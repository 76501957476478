import type * as Types from '@/types/graphql';

export type ThemePageEdgeSelectFragment = Pick<Types.ThemePageEdge, 'cursor'> & {
  node?: Types.Maybe<
    Pick<
      Types.ThemePage,
      | 'id'
      | 'name'
      | 'description'
      | 'type'
      | 'default'
      | 'isGlobal'
      | 'isMobile'
      | 'status'
      | 'sectionPosition'
      | 'splitPercentage'
      | 'isPlatformDefault'
      | 'handle'
      | 'createdAt'
      | 'updatedAt'
      | 'publishedAt'
      | 'theme'
    > & {
      saleFunnels?: Types.Maybe<Array<Types.Maybe<Pick<Types.SaleFunnel, 'name'>>>>;
      themeStyle?: Types.Maybe<Pick<Types.ThemeStyle, 'id' | 'data'>>;
      themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
      libraryTemplate?: Types.Maybe<Pick<Types.LibraryTemplate, 'id'>>;
      metafields?: Types.Maybe<
        Array<Types.Maybe<Pick<Types.ThemePageMeta, 'key' | 'value' | 'id' | 'createdAt' | 'updatedAt'>>>
      >;
      platformOriginalTemplate?: Types.Maybe<Pick<Types.PlatformOriginalTemplate, 'content' | 'localesDefaultSchema'>>;
      parent?: Types.Maybe<
        Pick<Types.ThemePage, 'id' | 'name' | 'type' | 'status' | 'default'> & {
          themePageOnlineStoreData?: Types.Maybe<
            Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>
          >;
        }
      >;
      interaction?: Types.Maybe<Pick<Types.PageInteraction, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'value'>>;
    }
  >;
};

export type ThemePageSelectFragment = Pick<
  Types.ThemePage,
  | 'id'
  | 'name'
  | 'description'
  | 'type'
  | 'default'
  | 'isGlobal'
  | 'isMobile'
  | 'status'
  | 'sectionPosition'
  | 'splitPercentage'
  | 'isPlatformDefault'
  | 'handle'
  | 'createdAt'
  | 'updatedAt'
  | 'publishedAt'
  | 'theme'
> & {
  saleFunnels?: Types.Maybe<Array<Types.Maybe<Pick<Types.SaleFunnel, 'name'>>>>;
  themeStyle?: Types.Maybe<Pick<Types.ThemeStyle, 'id' | 'data'>>;
  themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
  libraryTemplate?: Types.Maybe<Pick<Types.LibraryTemplate, 'id'>>;
  metafields?: Types.Maybe<
    Array<Types.Maybe<Pick<Types.ThemePageMeta, 'key' | 'value' | 'id' | 'createdAt' | 'updatedAt'>>>
  >;
  platformOriginalTemplate?: Types.Maybe<Pick<Types.PlatformOriginalTemplate, 'content' | 'localesDefaultSchema'>>;
  parent?: Types.Maybe<
    Pick<Types.ThemePage, 'id' | 'name' | 'type' | 'status' | 'default'> & {
      themePageOnlineStoreData?: Types.Maybe<Pick<Types.ThemePageOnlineStoreData, 'id' | 'onlineStoreObjectBaseIDs'>>;
    }
  >;
  interaction?: Types.Maybe<Pick<Types.PageInteraction, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'value'>>;
};

export const ThemePageSelectFragmentDoc = `
    fragment ThemePageSelect on ThemePage {
  id
  name
  description
  type
  default
  isGlobal
  isMobile
  status
  saleFunnels {
    name
  }
  sectionPosition
  splitPercentage
  themeStyle {
    id
    data
  }
  isPlatformDefault
  themePageOnlineStoreData {
    id
    onlineStoreObjectBaseIDs
  }
  libraryTemplate {
    id
  }
  metafields {
    key
    value
    id
    createdAt
    updatedAt
  }
  interaction {
    id
    createdAt
    updatedAt
    deletedAt
    value
  }
  handle
  createdAt
  updatedAt
  publishedAt
  platformOriginalTemplate {
    content
    localesDefaultSchema
  }
  parent {
    id
    name
    type
    status
    default
    themePageOnlineStoreData {
      id
      onlineStoreObjectBaseIDs
    }
  }
  theme {
    id
  }
}
    `;
export const ThemePageEdgeSelectFragmentDoc = `
    fragment ThemePageEdgeSelect on ThemePageEdge {
  cursor
  node {
    ...ThemePageSelect
  }
}
    `;
