import {
  advancedSettingUI as defaultAdvancedSettingUI,
  postPurchaseAdvancedSettingUI,
  advancedSetting as defaultAdvancedSetting,
  postPurchaseSetting,
} from '../../common/utils/const';
import { useSaleFunnel } from '../../sale-funnels/hooks/useSaleFunnel';

export const useGetAdvancedSetting = () => {
  const { isPostPurchasePage } = useSaleFunnel();

  const advancedSettingUI = computed(() => {
    if (isPostPurchasePage.value) {
      return postPurchaseAdvancedSettingUI;
    }
    return defaultAdvancedSettingUI;
  });

  const advancedSetting = computed(() => {
    if (isPostPurchasePage.value) {
      return postPurchaseSetting;
    }
    return defaultAdvancedSetting;
  });

  return { advancedSettingUI, advancedSetting };
};
