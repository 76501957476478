import useShopStore from '@/stores/shop';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import { getConfig } from '@/config';
import { sentryCaptureException } from '@/modules/editor/modules/common/use-cases/sentry';
import { isInIframe } from './checkInIframe';

export const reloadEditor = (isGetNewSession?: boolean) => {
  const shopStore = useShopStore();
  const editorStore = useEditorStore();

  if (isInIframe()) {
    // in shopify embed
    const appId = getConfig('featureConfig')?.embedAppId || 'gempages-cro';
    const shopId = shopStore.getShopId;
    const shopDomain = shopStore.getShopDomain;
    const [shopName] = shopDomain?.split('.') ?? [''];
    const pageId = editorStore.getEditingPageId;
    const themeId = editorStore.getEditingThemeId;
    const pageType = editorStore.getEditingPageType;
    if (shopId && shopDomain && shopName && pageId && themeId && pageType) {
      if (window.top) {
        window.top.location.href = `https://admin.shopify.com/store/${shopName}/apps/${appId}/app/builder/v7?pageId=${pageId}&themeId=${themeId}&type=gempagesv7&pageType=${pageType}&shopId=${shopId}&shopifyDomain=${shopDomain}`;
      } else {
        window.location.reload();
      }
    } else {
      // Log error
      sentryCaptureException('reloadEditor', 'variable is blank', {
        shopId,
        shopDomain,
        shopName,
        pageId,
        themeId,
        pageType,
      });

      // Fallback
      window.location.href = 'https://admin.shopify.com/?redirect=/apps/' + appId;
    }
  } else {
    if (isGetNewSession) {
      // https://admin.shopify.com/store/antony-dev-19/apps/gempages-development/app/builder/v7?pageId=530840608785302035&themeId=452020537569313333&type=gempagesv7&pageType=GP_INDEX&shopId=452020533844771446&shopifyDomain=antony-dev-19.myshopify.com
      // Chuyển hướng để khởi tạo token
      const appId = getConfig('featureConfig')?.embedAppId || 'gempages-cro';
      const shopId = shopStore.getShopId;
      const shopDomain = shopStore.getShopDomain;
      const [shopName] = shopDomain?.split('.') ?? [''];
      const pageId = editorStore.getEditingPageId;
      const themeId = editorStore.getEditingThemeId;
      const pageType = editorStore.getEditingPageType;
      if (shopId && shopDomain && shopName && pageId && themeId && pageType) {
        window.location.href = `https://admin.shopify.com/store/${shopName}/apps/${appId}/app/builder/v7?pageId=${pageId}&themeId=${themeId}&type=gempagesv7&pageType=${pageType}&shopId=${shopId}&shopifyDomain=${shopDomain}`;
      } else {
        // Log error
        sentryCaptureException('reloadEditor', 'variable is blank', {
          shopId,
          shopDomain,
          shopName,
          pageId,
          themeId,
          pageType,
        });

        // Fallback
        window.location.href = 'https://admin.shopify.com/?redirect=/apps/' + appId;
      }
    } else {
      window.location.reload();
    }
  }
  return;
};
