import type { ScreenType } from '@/types/custom';
import type {
  GlobalStyleConfig,
  GlobalStyleResponsiveConfig,
  TypographyProps,
  ObjectDeviceGlobalType,
} from '@/types/globalStyle';
import { cloneDeepObject } from '@/utils/common';
import { defineStore } from 'pinia';
import { defaultSupportDevices } from '../../common/utils/const';

export type State = {
  currentGlobalStyleId: string | undefined;
  currentGlobalStyle: GlobalStyleResponsiveConfig | undefined;
  cacheCurrentGlobalStyle: GlobalStyleResponsiveConfig | undefined;
  themeGlobalStyleLoading: boolean;
  isChangeGlobalStyle: boolean;
  isDiscardChangeGS: boolean;
  currentGlobalStyleLivePreview: GlobalStyleResponsiveConfig | undefined;
  hasChangeTheme?: boolean;
  isPreviewThemeStyle?: boolean;
  generateThemeFinish?: boolean;
};

export const useGlobalStyleStore = defineStore('globalStyle', {
  state: (): State => ({
    generateThemeFinish: false,
    currentGlobalStyleId: undefined,
    currentGlobalStyle: undefined,
    cacheCurrentGlobalStyle: undefined,
    themeGlobalStyleLoading: false,
    isChangeGlobalStyle: false,
    isDiscardChangeGS: false,
    currentGlobalStyleLivePreview: undefined,
    hasChangeTheme: false,
    isPreviewThemeStyle: false,
  }),
  getters: {
    getGenerateThemeFinish(state) {
      return state.generateThemeFinish;
    },
    getGlobalStyle(state) {
      return state.currentGlobalStyle;
    },
    getThemeGlobalStyleLoading(state) {
      return state.themeGlobalStyleLoading;
    },
    getCurrentGlobalStyleId(state) {
      return state.currentGlobalStyleId;
    },
    getByDevice(state) {
      return (device: ScreenType): GlobalStyleConfig => {
        return {
          ...state.currentGlobalStyle,
          typography: Object.fromEntries(
            (
              Object.entries(state.currentGlobalStyle?.typography ?? {}) as Array<
                [any, ObjectDeviceGlobalType<TypographyProps>]
              >
            ).map(([key, value]) => {
              return [key, value?.[device]];
            }),
          ),
          spacing: Object.fromEntries(
            (
              Object.entries(state.currentGlobalStyle?.spacing ?? {}) as Array<[any, ObjectDeviceGlobalType<string>]>
            ).map(([key, value]) => {
              return [key, value?.[device]];
            }),
          ),
        };
      };
    },
    getByDeviceExtend(state) {
      return (device: ScreenType): GlobalStyleConfig => {
        const indexScreen = defaultSupportDevices.findIndex((e) => e === device);
        const composeDeviceValue = defaultSupportDevices.slice(0, indexScreen + 1);
        return {
          ...state.currentGlobalStyle,
          typography: Object.fromEntries(
            (
              Object.entries(state.currentGlobalStyle?.typography ?? {}) as Array<
                [any, ObjectDeviceGlobalType<TypographyProps>]
              >
            ).map(([key, value]) => {
              const extendValue = composeDeviceValue.reduce((curr, deviceId) => {
                const typoDevice = value?.[deviceId];
                return {
                  ...curr,
                  ...typoDevice,
                };
              }, {} as any);
              return [key, extendValue];
            }),
          ),
          spacing: Object.fromEntries(
            (
              Object.entries(state.currentGlobalStyle?.spacing ?? {}) as Array<[any, ObjectDeviceGlobalType<string>]>
            ).map(([key, value]) => {
              return [key, value?.[device]];
            }),
          ),
        };
      };
    },
    getIsChangeGlobalStyle(state) {
      return state.isChangeGlobalStyle;
    },
    getIsDiscardChangeGS(state) {
      return state.isDiscardChangeGS;
    },
    getHasChangeTheme(state) {
      return state.hasChangeTheme;
    },
    getIsPreviewThemeStyle(state) {
      return state.isPreviewThemeStyle;
    },
  },
  actions: {
    setGenerateThemeFinish(value: boolean) {
      this.generateThemeFinish = value;
    },
    setCurrentGlobalStyleLivePreview(value: GlobalStyleResponsiveConfig) {
      this.currentGlobalStyleLivePreview = cloneDeepObject(value);
    },
    setCurrentGlobalStyle(value: GlobalStyleResponsiveConfig) {
      this.currentGlobalStyle = value;
      this.updateCacheCurrentGlobalStyle();
    },
    setCurrentGlobalStyleId(id: string) {
      this.currentGlobalStyleId = id;
    },
    setThemeGlobalStyleLoading(value: boolean) {
      this.themeGlobalStyleLoading = value;
    },
    setIsChangeGlobalStyle(isChange: boolean) {
      this.isChangeGlobalStyle = isChange;
    },
    setIsDiscardChangeGS(isShow: boolean) {
      this.isDiscardChangeGS = isShow;
    },
    updateCacheCurrentGlobalStyle() {
      if (this.currentGlobalStyle) {
        this.cacheCurrentGlobalStyle = cloneDeepObject(this.currentGlobalStyle);
      }
    },
    revertCurrentGlobalStyle() {
      if (this.cacheCurrentGlobalStyle) {
        this.currentGlobalStyle = cloneDeepObject(this.cacheCurrentGlobalStyle);
      }
    },
    setHasChangeTheme(value: boolean) {
      this.hasChangeTheme = value;
    },
    setIsPreviewThemeStyle(value: boolean) {
      this.isPreviewThemeStyle = value;
    },
  },
});

export default useGlobalStyleStore;
