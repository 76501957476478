import type { ScreenType } from '@/types/custom';
import type { PageSection, ThemeSectionStatus } from '@/types/graphql';
import type { ComponentTag } from '@gem/control';
import type {
  BuilderDiffType,
  Component,
  ComponentCommand,
  ComponentConfig,
  Control,
  GroupTypeSetting,
  InitComponentType,
  UpdateControlInput,
  InitNewPresetOptions,
} from '../types';

import { cacheGetSectionCidByComponentUid } from '@/modules/editor/modules/common/use-cases/cache';
import { useBuilderConfigStore } from '@/modules/editor/modules/component-preset/stores/builderConfig';
import { ID } from '@/utils/id';
import { cloneDeepObject, deepMergeObject, isDefined, isObject } from '@/utils/common';
import { createInitVal } from '@gem/control/src/control/distance-picker/commons';
import { useSaleFunnel } from '../../../sale-funnels/hooks/useSaleFunnel';
import { useSalesFunnelStore } from '../../../sale-funnels/stores';
import { useGetAdvancedSetting } from '../../hooks/useGetAdvancedSetting';
import useEditorStore from '../../stores/editor';
import { sentryCaptureException } from '../../use-cases/sentry';
import { COUPON_TAG, ROW_TAG, SECTION_TAG, TRIGGER_UPDATE_ELEMENTS } from '../const';
import { createMapIdControls } from './control';
import { arraysIntersect } from '@/utils/arraysIntersect';
import { arrayUnique } from '@/utils/arrayUnique';
import useSectionStore from '../../stores/section';
import { onOpenSetting } from '../../hooks/useDraggable';
import useCurrentDevice from '../../hooks/useCurrentDevice';
import useHandleResponsiveSetting from '../../../settings/hooks/useHandleResponsiveSetting';

const { handleResponsiveSetting } = useHandleResponsiveSetting();

export function convertComponentToJSON(component: string): Component | undefined {
  try {
    return JSON.parse(component);
  } catch (e) {
    sentryCaptureException(
      'convertComponentToJSON',
      'Parse component error',
      {
        component,
      },
      {
        level: 'error',
      },
    );
  }
}

export function convertComponentToString(component: Component): string {
  return JSON.stringify(component);
}

export function getComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent.uid == uid) {
    return jsonComponent;
  }
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      const c = getComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}

export function getSectionByComponentUid(componentUid: string, sectionStore: any): any {
  if (componentUid) {
    const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
    if (sectionCid) {
      return sectionStore.getItemByCid(sectionCid);
    }
  }
  return null;
}

export function getComponentsByTag(jsonComponent: Component, tag: string | string[], components: Component[]) {
  if (jsonComponent.tag == tag || (Array.isArray(tag) && tag.includes(jsonComponent.tag))) {
    components.push(jsonComponent);
  }
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      getComponentsByTag(children, tag, components);
    }
  }
  return components;
}

export function getComponentsBySettingAndValue(
  jsonComponent: Component,
  tag: string,
  setting: string,
  value: any,
  components: Component[],
) {
  if (jsonComponent.tag == tag && jsonComponent.settings?.[`${setting}`] == value) {
    components.push(jsonComponent);
  }
  if (jsonComponent.childrens?.length) {
    for (const children of jsonComponent.childrens) {
      getComponentsBySettingAndValue(children, tag, setting, value, components);
    }
  }
  return components;
}

export function getParentComponentByUid(jsonComponent: Component, uid: string): Component | null {
  if (jsonComponent?.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      if (children.uid == uid) {
        return jsonComponent;
      }
      const c = getParentComponentByUid(children, uid);
      if (c) {
        return c;
      }
    }
  }
  return null;
}

export function getIndexFromParent(parent: Component, uid: string) {
  const childrens = parent?.childrens ?? [];
  let toIndex = 0;
  childrens.forEach((child, index) => {
    if (child.uid === uid) {
      toIndex = index;
    }
  });
  return toIndex;
}

export function findParentByUidAndByParentTag(
  jsonComponent: Component,
  uid: string,
  parentTag: string | string[],
): Component | null {
  const lastParent = getParentComponentByUid(jsonComponent, uid);
  if (lastParent && lastParent?.uid) {
    if (lastParent?.tag === parentTag || (Array.isArray(parentTag) && parentTag.includes(lastParent.tag))) {
      return lastParent;
    }
    return findParentByUidAndByParentTag(jsonComponent, lastParent?.uid, parentTag);
  }
  return null;
}

export function findParentsByUid(jsonComponent: Component, uid: string, parents: Component[]): boolean {
  if (jsonComponent?.uid == uid) {
    parents.push(jsonComponent);
    return true;
  }
  if (jsonComponent?.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const node = jsonComponent.childrens[i];
      if (findParentsByUid(node, uid, parents)) {
        parents.push(jsonComponent);
        return true;
      }
    }
  }
  // no matches found - return false
  return false;
}

export function removeComponentByUid(component: string, uid: string): string {
  const jsonComponent = convertComponentToJSON(component);

  if (jsonComponent?.uid == uid) {
    // Remove all components
    return '';
  } else if (jsonComponent) {
    const parent = getParentComponentByUid(jsonComponent, uid);
    if (parent) {
      const childs = parent.childrens;
      if (childs) {
        const index = childs.findIndex((child: { uid: string }) => child.uid == uid);
        if (index > -1) {
          childs.splice(index, 1);
        }
      }
    }

    component = convertComponentToString(jsonComponent);
  }

  return component;
}

export function removeLayoutColByUid(component: string, uid: string): string {
  const jsonComponent = convertComponentToJSON(component);

  if (jsonComponent?.uid == uid) {
    // Remove all components
    return '';
  } else if (jsonComponent) {
    const parent = getParentComponentByUid(jsonComponent, uid);
    if (parent) {
      const childs = parent.childrens;
      if (childs) {
        const newCol = (childs?.length ?? 1) - 1;
        parent.settings = {
          ...parent.settings,
          layout: {
            desktop: {
              ...parent.settings?.layout?.desktop,
              cols: Array.from({ length: newCol }, () => 12 / newCol),
            },
          },
        };
        const index = childs.findIndex((child: { uid: string }) => child.uid == uid);
        if (index > -1) {
          childs.splice(index, 1);
        }
      }
    }
    component = convertComponentToString(jsonComponent);
  }

  return component;
}

export function addComponent(
  components: Component[],
  allComponent: string,
  toComponentUid: string,
  direction: 'before' | 'after' | 'children' | undefined,
): string {
  const jsonComponent = JSON.parse(allComponent);

  if (direction == 'children') {
    // Drag to children component
    const parent = getComponentByUid(jsonComponent, toComponentUid);
    if (parent) {
      const childs = parent.childrens || [];
      if (Array.isArray(components)) {
        for (let i = 0; i < components.length; i++) {
          const item = components[i];
          childs.push(item);
        }
      } else {
        childs.push(components);
      }
      parent.childrens = childs;
    }
  } else {
    const parent = getParentComponentByUid(jsonComponent, toComponentUid);
    if (parent) {
      const childs = parent.childrens;
      if (childs?.length) {
        let index = childs.findIndex((child: { uid: string }) => child.uid == toComponentUid);
        components.forEach((component) => {
          // Drag to before or after component
          if (direction == 'before' || direction == 'after') {
            if (direction == 'before') {
              if (index == 0) {
                childs.unshift(component);
                index++; // After element append
              } else {
                childs.splice(index, 0, component);
                index++; // After element append
              }
            } else {
              childs.splice(index + 1, 0, component);
              index++; // After element append
            }
          }
        });
      }
    }
  }

  allComponent = JSON.stringify(jsonComponent);
  return allComponent;
}

export function mapControlToComponentProp(
  component: InitComponentType,
  controls: Control[],
  groupType: GroupTypeSetting,
): Record<string, any> {
  const cloneData = cloneDeepObject(component);
  const data: Record<GroupTypeSetting, any> = {
    advanced: cloneData.advanced,
    setting: cloneData.settings,
    style: cloneData.styles,
  };

  const setting: Record<string, any> = data[groupType] || {}; // same props react
  const mapControls = createMapIdControls(controls);

  Object.entries(mapControls).forEach(([controlId, control]) => {
    if (control.devices && isObject(control.devices)) {
      Object.entries(control.devices).forEach(([deviceId, controlDevice]) => {
        if (controlDevice) {
          const controlValue = (controlDevice as any)?.default;
          if (controlValue !== undefined && controlValue !== null) {
            setting[controlId] = isObject(setting[controlId]) ? setting[controlId] : {};
            setting[controlId][deviceId] = controlValue;
          }
        }
      });
    } else {
      const controlValue = control.default;
      if (controlValue !== undefined && controlValue !== null) {
        if (control.id) {
          setting[control.id] = controlValue;
        }
      }
    }
  });

  return setting;
}

export function createArrayComponentsInComponent(component: Component): Component[] {
  const arr: Component[] = [];
  loopComponent(component, (component) => {
    arr.push(component);
  });
  return arr;
}

function handleTypeChildItem(
  currentComponent: Component,
  options: {
    controlType: string;
    newValue: any;
  },
) {
  const { controlType, newValue } = options;
  if (!['product-bundle-child-item', 'child-item'].includes(controlType)) return;
  if (!newValue.childrens) return;
  currentComponent.childrens = cloneDeepObject(newValue.childrens);
}

function handleTypeChildren(currentComponent: Component, options: { controlType: string; newValue: any }) {
  if (options?.controlType !== 'childrens') return;
  currentComponent.childrens = cloneDeepObject(options?.newValue);
}

function handleLayoutControl(
  currentComponent: Component,
  options: {
    screenId: ScreenType;
    newValue: any;
    groupType: GroupTypeSetting;
    controlType: string;
    controlId: string;
    hasDevices?: boolean;
    tag?: string;
  },
) {
  const { controlType, newValue, screenId } = options;
  const isLayout = ['layout', 'grid', 'layout-banner'].includes(controlType);
  const isDesktop = screenId === 'desktop';
  // Keep the column for products element
  if (isLayout && isDesktop) {
    // Regex convert css grid to array
    const gridToArrayRegex = /[^\s()]+(?:\([^\s()]+(?:\([^()]+\))?(?:, *[^\s()]+(?:\([^()]+\))?)*\))?/g; // Regex convert css grid to array

    const nColNumber =
      controlType === 'layout' || controlType === 'layout-banner'
        ? newValue?.cols?.length ?? 0
        : newValue.match(gridToArrayRegex).length ?? 0;
    const childComponent = cloneDeepObject(currentComponent.childrens) || [];

    const currentColNumber = childComponent.length;

    if (nColNumber > currentColNumber) {
      // Add new column
      const appendCols = Array.from<number, Component>({ length: nColNumber - currentColNumber }, () => ({
        uid: ID(),
        tag: 'Col',
        label: 'Block',
        settings: {},
        childrens: [],
      }));
      currentComponent.childrens = [...childComponent, ...appendCols];
    } else if (currentColNumber > nColNumber) {
      if (options?.tag === 'Product' || options?.tag === 'PostPurchaseProductOffer') return;
      // Remove col
      const nChildComponent = childComponent.slice(0, nColNumber);

      // Merge col left
      const childrenNeedMerge = childComponent
        .slice(nColNumber)
        .map((v) => v.childrens)
        .flat()
        .filter(isDefined);

      const lastChild = nChildComponent[nChildComponent.length - 1];
      if (lastChild) {
        nChildComponent[nChildComponent.length - 1].childrens = [...(lastChild.childrens ?? []), ...childrenNeedMerge];
      }

      currentComponent.childrens = nChildComponent;
    }
  }
}

function handleControlTypeOther(
  currentComponent: Component,
  options: {
    controlType: string;
    newValue: any;
    screenId: ScreenType;
    hasDevices?: boolean;
    groupType: GroupTypeSetting;
    controlId: string;
  },
) {
  if (options?.controlType === 'childrens') return;
  const { hasDevices, groupType, controlId, screenId, newValue, controlType } = options;
  handleLayoutControl(currentComponent, {
    ...options,
    tag: currentComponent?.tag,
  });

  // Control normal
  const styles = currentComponent?.styles || {};
  const settings = currentComponent?.settings || {};
  const advanced = currentComponent?.advanced || {};
  const data: Record<GroupTypeSetting, any> = {
    advanced,
    setting: settings,
    style: styles,
  };

  if (hasDevices) {
    if (newValue == undefined) {
      if (data?.[groupType]?.[controlId]?.[screenId]) {
        delete data[groupType][controlId][screenId];
      }
    } else {
      data[groupType][controlId] = { ...data[groupType][controlId], [screenId]: newValue };
    }
  } else {
    if (controlType === 'child-item' || controlType === 'product-bundle-child-item') {
      data[groupType][controlId] = newValue.setting;
    } else {
      if (newValue == undefined) {
        if (data[groupType][controlId]) {
          delete data[groupType][controlId];
        }
      } else {
        data[groupType][controlId] = newValue;
      }
    }
  }

  switch (groupType) {
    case 'style': {
      currentComponent.styles = data[groupType];
      break;
    }
    case 'setting': {
      currentComponent.settings = data[groupType];
      break;
    }
    case 'advanced': {
      currentComponent.advanced = data[groupType];
      break;
    }
  }
}

// update value from control to component
export function updateControlValueInComponent({ component, settings }: UpdateControlInput) {
  // Replace setting component
  const jsonComponent = convertComponentToJSON(component);
  if (!jsonComponent) return component;

  for (let i = 0; i < settings.length; i++) {
    const setting = settings[i];
    const { componentUid, controlId, newValue, controlType, hasDevices, screenId, groupType } = setting;

    const currentComponent = getComponentByUid(jsonComponent, componentUid); // ref to memory
    if (!currentComponent) continue;

    handleTypeChildItem(currentComponent, {
      controlType,
      newValue,
    });

    handleTypeChildren(currentComponent, {
      controlType,
      newValue,
    });

    handleControlTypeOther(currentComponent, {
      controlType,
      newValue,
      screenId,
      hasDevices,
      groupType,
      controlId,
    });

    handleResponsiveSetting(currentComponent, setting);
  }

  component = convertComponentToString(jsonComponent);
  return component;
}

export function loopComponent(component: Component | undefined, callback: (component: Component) => void) {
  if (component) {
    callback(component);
  }
  if (component?.childrens?.length) {
    for (let i = 0; i < component.childrens.length; i++) {
      const children = component.childrens[i];
      loopComponent(children, callback);
    }
  }
}

export function createBuilderComponentBySection(
  section: PageSection & {
    isThemeSection?: boolean;
    status?: ThemeSectionStatus;
    isShopifySection?: boolean;
    isShopifyVisibility?: boolean;
  },
  builderSettings: Record<string, any>,
) {
  if (section.component) {
    try {
      const component = convertComponentToJSON(section.component);
      if (component) {
        if (section?.name) {
          component.name = section.name;
        }
        if (section?.isThemeSection) {
          component.isThemeSection = true;
          component.status = section.status;
        }
        component.isShopifySection = !!section?.isShopifySection;
        component.isShopifyVisibility = !!section?.isShopifyVisibility;

        modifyComponentBeforeBuilderState(component, builderSettings);
      }
      return component;
    } catch (e) {
      sentryCaptureException(
        'createBuilderComponentBySection',
        'Parse component error',
        {
          section,
        },
        {
          level: 'error',
        },
      );
    }
  }
  return undefined;
}

export function modifyComponentBeforeBuilderState(component: Component, builderSettings: Record<string, any>) {
  const { advancedSetting } = useGetAdvancedSetting();
  loopComponent(component, (comp) => {
    const builderSetting = builderSettings[comp.tag];
    const newComponent: Component = {
      uid: ID(),
      tag: component.tag,
      settings: {},
      styles: {},
      advanced: {},
    };
    const advancedDefaultValue = mapControlToComponentProp(newComponent, advancedSetting.value.controls, 'advanced');
    comp.advanced = comp.advanced ? Object.assign(advancedDefaultValue, comp.advanced) : advancedDefaultValue;

    if (builderSetting?.editorConfigs) {
      comp.editorConfigs = builderSetting.editorConfigs || {};
    }
  });
}

export function generateNewUid(component: Component) {
  loopComponent(component, (componentItem) => {
    componentItem.uid = ID();
  });
}

export const initNewComponent = (
  tag: ComponentTag,
  override?: {
    uid?: string;
    label?: string;
    settings?: Record<string, any>;
    styles?: Record<string, any>;
    advanced?: Record<string, any>;
    noRenderInit?: boolean;
  },
  overrideFullWidth?: boolean,
): Component[] => {
  const builderConfigStore = useBuilderConfigStore();
  const builderConfigComponent = builderConfigStore.getBuilderSettingByTag(tag);
  if (!builderConfigComponent) {
    sentryCaptureException(
      'initNewComponent',
      "Can't find tag in builder settings",
      {
        tag,
      },
      {
        level: 'fatal',
      },
    );
    return [];
  }
  const initComponent = cloneDeepObject(builderConfigComponent);
  // Init group component
  if (initComponent.init?.length && !override?.noRenderInit) {
    if (tag === 'Section' && overrideFullWidth) {
      initComponent.init[0].styles = {
        fullWidth: {
          desktop: true,
          tablet: true,
          mobile: true,
        },
      };
    }
    const components = initNewPreset(initComponent.init);
    if (components?.length) {
      return components;
    }
  }

  // Init component
  let newComponent: Component = {
    uid: override?.uid ?? ID(),
    tag,
    label: override?.label ?? initComponent.label,
    customLabel: initComponent.customLabel || undefined,
    settings: override?.settings ?? {},
    styles: override?.styles ?? {},
    advanced: override?.advanced ?? {},
  };

  // Merge settings
  addDefaultValueAdvancedSetting(initComponent);
  initComponent?.settings?.forEach((setting) => {
    const data = mapControlToComponentProp(newComponent, setting.controls, setting.id);
    switch (setting.id) {
      case 'setting':
        newComponent.settings = deepMergeObject(data, newComponent.settings);
        break;
      case 'style':
        newComponent.styles = deepMergeObject(data, newComponent.styles);
        break;
      case 'advanced':
        newComponent.advanced = deepMergeObject(data, newComponent.advanced);
        break;
      default:
        break;
    }
  });
  newComponent = handleProductSettingForSaleFunnel(newComponent);
  return [newComponent];
};

const handleProductSettingForSaleFunnel = (component: Component) => {
  const { isSalePage } = useSaleFunnel();
  const newComponent = component;
  const tag = newComponent.tag;
  if (isSalePage.value && tag === 'Product') {
    const productSetting = getInitProductSettingForSalesPage();
    newComponent.settings = {
      ...newComponent.settings,
      productSetting,
    };
  }

  return newComponent;
};

const getInitProductSettingForSalesPage = () => {
  const { salesPageProductIdAssignment, productAssignment } = useSalesFunnelStore();
  return {
    productId: salesPageProductIdAssignment || 'Latest',
    initialVariantId: 'Latest',
    productHandle: productAssignment?.handle || '',
    productStatus: 'static',
  };
};

export const initNewPreset = (components: InitComponentType[], options?: InitNewPresetOptions) => {
  const builderConfig = useBuilderConfigStore();
  return components.map((initItem) => {
    const setting = builderConfig.getBuilderSettingByTag(initItem.tag);
    const { rootOverride } = setting ?? {};
    const overrideData = options?.allowSectionOverride ? rootOverride ?? initItem : initItem;

    const newComponent = initNewComponent(initItem.tag, {
      label: overrideData.label,
      settings: { ...initItem.settings, ...(options?.allowSectionOverride && rootOverride?.settings) },
      styles: { ...initItem.styles, ...(options?.allowSectionOverride && rootOverride?.styles) },
      advanced: { ...initItem.advanced, ...(options?.allowSectionOverride && rootOverride?.advanced) },
      noRenderInit: true,
    })[0];
    if (initItem.childrens?.length) {
      if (newComponent) newComponent.childrens = initNewPreset(initItem.childrens);
    }
    return { ...newComponent };
  }) as Component[];
};

function addDefaultValueAdvancedSetting(component: ComponentConfig): ComponentConfig {
  const { advancedSetting } = useGetAdvancedSetting();

  const advancedDefaultValue = advancedSetting.value;
  if (component?.settings) {
    const advancedSetting = component.settings.find((item: { id: string }) => item.id === 'advanced');
    if (advancedSetting) {
      advancedSetting.controls = Object.assign(advancedDefaultValue.controls, advancedSetting.controls);
    } else {
      component.settings.push(advancedDefaultValue);
    }
  }
  return component;
}

export const getFlowTags = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  const flowTags: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const componentConfig = builderSettings[component.tag];
    const flowTag: string[] | undefined = componentConfig?.editorConfigs?.placeholder?.flowTag;
    if (flowTag?.length) {
      flowTag.forEach((item) => {
        flowTags.push(item);
      });
    }
  }
  return flowTags;
};

export const getFlowTagsMessage = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  let flowTagsMessage: string[] = [];
  components.forEach((component) => {
    const componentConfig = builderSettings[component.tag];
    const newMessage: string[] | undefined = componentConfig?.editorConfigs?.placeholder?.flowTagMessage;
    if (newMessage?.length) {
      flowTagsMessage = [...flowTagsMessage, ...newMessage];
    }
  });
  return flowTagsMessage;
};

const getAllChildrenTags = (component: Component | InitComponentType, result: string[]) => {
  component.childrens?.forEach((child) => {
    if (result.indexOf(child.tag) === -1) result.push(child.tag);
    if (child.childrens?.length) getAllChildrenTags(child, result);
  });
};
export const getNotAppendTags = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  let notAppendTags: string[] = [];
  const checkingChildrenTags: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (component.childrens?.length) {
      const allChildrenTags: string[] = [];
      getAllChildrenTags(component, allChildrenTags);
      allChildrenTags.forEach((componentTag) => {
        const componentConfig = builderSettings[componentTag];
        if (componentConfig?.editorConfigs?.placeholder?.notAppendTags?.length) {
          checkingChildrenTags.push(componentTag);
          notAppendTags = [...notAppendTags, ...componentConfig.editorConfigs.placeholder.notAppendTags];
        }
      });
    }
    const componentConfig = builderSettings[component.tag];
    notAppendTags = [...notAppendTags, ...(componentConfig?.editorConfigs?.placeholder?.notAppendTags || [])];
  }
  return { checkingChildrenTags, notAppendTags };
};

export const getAllowAppendTags = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  let allowAppendTags: string[] = [];
  const checkingChildrenTags: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    if (!component.childrens?.length) continue;

    const allChildrenTags: string[] = [];
    getAllChildrenTags(component, allChildrenTags);
    allChildrenTags.forEach((componentTag) => {
      const componentConfig = builderSettings[componentTag];
      if (!componentConfig?.editorConfigs?.placeholder?.allowAppendTags?.length) return;

      checkingChildrenTags.push(componentTag);
      allowAppendTags = [...allowAppendTags, ...componentConfig.editorConfigs.placeholder.allowAppendTags];
    });
    const componentConfig = builderSettings[component.tag];
    allowAppendTags = [...allowAppendTags, ...(componentConfig?.editorConfigs?.placeholder?.allowAppendTags || [])];
  }

  return { checkingChildrenTags, allowAppendTags };
};

export const getFlowPages = (components: Component[] | InitComponentType[]) => {
  const builderConfig = useBuilderConfigStore();
  const builderSettings = builderConfig.getBuilderSettings;
  const flowPages: string[] = [];
  for (let i = 0; i < components.length; i++) {
    const component = components[i];
    const componentConfig = builderSettings[component.tag];
    const flowPage: string | undefined = componentConfig?.editorConfigs?.placeholder?.flowPage;
    if (flowPage) {
      flowPages.push(flowPage);
    }
  }
  return flowPages;
};

export const checkExistComponentByTag = (jsonComponent: Component, tags: string[]) => {
  if (tags.includes(jsonComponent.tag)) {
    return true;
  }
  if (jsonComponent.childrens?.length) {
    for (let index = 0; index < jsonComponent.childrens?.length; index++) {
      const element = jsonComponent.childrens?.[index];
      const isExist = checkExistComponentByTag(element, tags);
      if (isExist) {
        return true;
      }
    }
  }
  return false;
};

export const convertDynamicProductAndProductListToStatic = (
  jsonComponent: Component,
  isProductPage?: boolean,
  isCollectionPage?: boolean,
  isDifferentShopId?: boolean,
) => {
  if (
    jsonComponent?.tag == 'Product' &&
    jsonComponent?.settings?.productSetting &&
    jsonComponent?.settings?.productSetting?.productStatus == 'dynamic' &&
    !isProductPage
  ) {
    jsonComponent.settings.productSetting.productStatus = 'static';
    if (isDifferentShopId) {
      jsonComponent.settings.productSetting.productId = 'latest';
      jsonComponent.settings.productSetting.productHandle = undefined;
    }
  }

  if (jsonComponent?.tag == 'ProductList' && jsonComponent?.settings?.productSetting) {
    if (isCollectionPage) {
      jsonComponent.settings.productSetting = {
        productSrc: 'DynamicCollection',
        productIds: [],
        collectionId: 'latest',
      };
    } else if (jsonComponent?.settings?.productSetting?.productSrc == 'DynamicCollection') {
      const collectionId = jsonComponent.settings.productSetting.collectionId;
      jsonComponent.settings.productSetting = {
        productSrc: collectionId ? 'Collection' : 'PickProduct',
        productIds: [],
        collectionId: collectionId ?? 'latest',
      };
    }
  }

  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      convertDynamicProductAndProductListToStatic(children, isProductPage);
    }
  }
  return jsonComponent;
};

export const updateWidgetTypeSettingComponent = (
  jsonComponent: Component,
  isTriggerUpdate?: boolean,
  callback?: (data: { uid: string; propName: string; propValue: any; group: GroupTypeSetting }) => void,
) => {
  const builderConfigStore = useBuilderConfigStore();
  const updateComponents = getComponentsByTag(jsonComponent, TRIGGER_UPDATE_ELEMENTS, []);
  for (const updateComponent of updateComponents) {
    const builderSetting = builderConfigStore.getBuilderSettingByTag(updateComponent.tag);
    const options = builderSetting?.settings?.[0]?.controls?.find((item) => (item.id = 'widgetType'))?.options;
    if (options?.length) {
      const { newOptions } = useControlSelectByCondition(options, jsonComponent, updateComponent.uid);
      const existValue = newOptions.find((item: any) => item.value == updateComponent?.settings?.widgetType);
      if (!existValue && updateComponent.settings) {
        updateComponent.settings.widgetType = newOptions?.[0]?.value;
        if (isTriggerUpdate) {
          callback &&
            callback({
              uid: updateComponent.uid,
              propName: 'widgetType',
              propValue: updateComponent?.settings?.widgetType,
              group: 'setting',
            });
        }
      }
    }
  }

  return {
    jsonComponent,
    updateComponents,
  };
};

export const useControlSelectByCondition = (
  options: Record<string, any>[],
  sectionComponent?: Component | null,
  currentComponentUid?: string | null,
) => {
  const editorStore = useEditorStore();
  const editingPageType = computed(() => {
    return editorStore.getEditingPageType ?? '';
  });

  let isChangeOption = false;

  const newOptions = options.filter((option) => {
    const hideOnPage = option.hideOnPage;
    const flowTag = option.flowTag;
    const condition = option.condition;
    const hasFlowTagAndComponent = flowTag?.length && sectionComponent && currentComponentUid;
    if (hideOnPage?.length || hasFlowTagAndComponent) isChangeOption = true;
    if (condition) {
      if (hideOnPage?.length && hasFlowTagAndComponent) {
        const showOnThisPage = !hideOnPage.includes(editingPageType.value);
        const flowTagParent = findParentByUidAndByParentTag(sectionComponent, currentComponentUid, flowTag);
        if (condition == 'and' && showOnThisPage && flowTagParent) {
          return option;
        } else if (condition == 'or' && (showOnThisPage || flowTagParent)) {
          return option;
        }
      } else {
        return option;
      }
    } else if (hideOnPage?.length) {
      if (!hideOnPage.includes(editingPageType.value)) {
        return option;
      }
    } else if (hasFlowTagAndComponent) {
      if (findParentByUidAndByParentTag(sectionComponent, currentComponentUid, flowTag)) {
        return option;
      }
    } else {
      return option;
    }
  });

  return {
    newOptions,
    isChangeOption,
  };
};

export const getElementsTagInComponent = (jsonComponent: Component, tags: string[]) => {
  tags.push(jsonComponent.tag);
  if (jsonComponent.childrens?.length) {
    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      getElementsTagInComponent(children, tags);
    }
  }

  return tags;
};

export const getComponentMarginBottom = (component: Component) => {
  return component?.advanced?.['spacing-setting']?.desktop?.margin?.bottom;
};

const isDefaultMarginBottom = (component: Component) => {
  const marginBottom = getComponentMarginBottom(component);
  return marginBottom === 'var(--g-s-l)' || marginBottom === '16px';
};

const isZeroMarginBottom = (component: Component) => {
  const marginBottom = getComponentMarginBottom(component);
  return !marginBottom || marginBottom === 0 || marginBottom === '0px';
};

export const setComponentMarginBottom = (component: Component, value: string | number) => {
  if (!component) return;
  component.advanced = component.advanced || {};
  component.advanced['spacing-setting'] = component.advanced['spacing-setting'] || {};
  component.advanced['spacing-setting'].desktop = component.advanced['spacing-setting'].desktop || {};
  component.advanced['spacing-setting'].desktop.margin = component.advanced['spacing-setting'].desktop.margin || {};
  component.advanced['spacing-setting'].desktop.margin.bottom = value;
};

const verifyNegativeMarginBottom = (component: Component) => {
  if (!component) return 0;
  const marginBottom = getComponentMarginBottom(component);
  const mbIntVal = createInitVal(marginBottom);
  if (mbIntVal && parseFloat(mbIntVal) < 0) return 0;
  return marginBottom;
};

/**
 * Update spacing after add new component
 * @param component
 * @param newComponents
 * @returns
 */

export const updateSpacingComponent = (component: Component, newComponents: Component[]) => {
  const childs = component.childrens;
  const newComponent = newComponents[0];
  if (childs) {
    const index = childs.findIndex((child: { uid: string }) => child.uid == newComponent.uid);
    if (index < 0) return;

    if (index == 0 && childs.length > 1) {
      // insert to first
      const nextComponent = childs[index + 1];
      const marginBottom = verifyNegativeMarginBottom(nextComponent);
      for (const newComponent of newComponents) {
        if (childs.length > 2) {
          if (isDefaultMarginBottom(newComponent) || isZeroMarginBottom(newComponent)) {
            setComponentMarginBottom(newComponent, marginBottom);
          }
        } else {
          if (isZeroMarginBottom(newComponent)) {
            setComponentMarginBottom(newComponent, 'var(--g-s-l)');
          }
        }
      }
    } else if (index > 0 && index < childs.length - 1) {
      // insert to middle
      const previousComponent = childs[index - 1];
      const marginBottom = verifyNegativeMarginBottom(previousComponent);
      for (const newComponent of newComponents) {
        if (isDefaultMarginBottom(newComponent) || isZeroMarginBottom(newComponent)) {
          setComponentMarginBottom(newComponent, marginBottom);
        }
      }
    } else {
      // insert to last
      for (const newComponent of newComponents) {
        if (isDefaultMarginBottom(newComponent) || isZeroMarginBottom(newComponent)) {
          updateSpacingLastComponent(newComponent);
        }
      }
    }
  }

  return childs;
};

const updateSpacingColumnOfNewRow = (component: Component) => {
  const currentChilds = component?.childrens || [];
  updateSpacingLastComponent(currentChilds[currentChilds.length - 1]);

  return currentChilds;
};

const updateSpacingLastComponent = (component: Component) => {
  if (isDefaultMarginBottom(component) || isZeroMarginBottom(component)) {
    setComponentMarginBottom(component, 0);
  }

  return component;
};

const componentRemoveEntity = (component: Component, removeUid: string) => {
  const childs = component.childrens;
  if (childs) {
    const index = childs.findIndex((child: { uid: string }) => child.uid == removeUid);
    const isRemoveLastElement = index === childs.length - 1;
    if (index > -1) {
      childs.splice(index, 1);
    }
    if (isRemoveLastElement && childs.length >= 1) {
      updateSpacingLastComponent(childs[childs.length - 1]);
    }
  }
  return component;
};

const isEmptyRow = (component: Component) => {
  if (component.tag === ROW_TAG && component?.childrens?.length) {
    for (let i = 0; i < component.childrens.length; i++) {
      const children = component.childrens[i];
      if (Array.isArray(children.childrens) && children.childrens.length > 0) {
        return false;
      }
    }
    return true;
  }
  return false;
};

const removeEmptyRow = (component: Component, jsonComponent: Component) => {
  const rowParent = getParentComponentByUid(jsonComponent, component.uid);
  const isAutoGen = rowParent?.isAutoGenerated;
  if (!rowParent || !isEmptyRow(rowParent) || !isAutoGen) return;

  const parentOfRow = getParentComponentByUid(jsonComponent, rowParent.uid);
  if (!parentOfRow) return;

  const childs = parentOfRow.childrens;
  if (!childs) return;

  componentRemoveEntity(parentOfRow, rowParent.uid);
  removeEmptyRow(parentOfRow, jsonComponent);
};

const isSectionRowProduct = (tag: string) => {
  return tag === SECTION_TAG || tag === ROW_TAG || tag === 'Product';
};

const isMoveComponentInsideColumn2Childs = (childs: Component[], toComponentUid: string) => {
  if (childs.length > 2) return false;
  for (const child of childs) {
    if (child.uid === toComponentUid) return true;
  }
  return false;
};

const MAX_COLUMNS = 6;

const verifyMaxColumns = (sectionComponent: string | undefined, toComponentUid: string) => {
  if (!sectionComponent) return;
  const jsonComponent = convertComponentToJSON(sectionComponent);
  if (!jsonComponent) return;
  const parent = getParentComponentByUid(jsonComponent, toComponentUid);
  if (!parent) return;

  const rowParent = getParentComponentByUid(jsonComponent, parent?.uid);

  if (!isSectionRowProduct(parent.tag) && (!rowParent || !isSectionRowProduct(rowParent.tag))) return;

  const childs = parent.childrens || [];
  const isMove = isMoveComponentInsideColumn2Childs(childs, toComponentUid);
  if (isMove && childs.length >= MAX_COLUMNS) {
    return true;
  }

  const rowParentChilds = (rowParent && rowParent.childrens) || [];

  const isMaxColumns =
    (isSectionRowProduct(parent.tag) && childs.length >= MAX_COLUMNS) ||
    (rowParent && isSectionRowProduct(rowParent.tag) && rowParentChilds.length >= MAX_COLUMNS);
  if (isMaxColumns) {
    return true;
  }
};

const isIncreaseRowColumns = (component: Component) => {
  const editorStore = useEditorStore();
  const activeScreenId = computed(() => editorStore.getScreenActiveId);
  const childs = component.childrens || [];
  const colLength = (childs?.length ?? 1) + 1;
  const isOddCols = colLength % 2 === 1;
  const isMobile = activeScreenId.value === 'mobile';
  const isTabletOrOdd = activeScreenId.value === 'tablet' || isOddCols;
  const isMobileOrOdd = isMobile || isOddCols;

  const newRowLayout = {
    cols: Array.from({ length: colLength }, () => 12 / colLength),
  };

  component.settings = {
    ...component.settings,
    layout: {
      desktop: {
        ...component.settings?.layout?.desktop,
        ...newRowLayout,
      },
      tablet: {
        ...component.settings?.layout?.tablet,
        ...((isTabletOrOdd || isMobile) && newRowLayout),
      },
      mobile: {
        ...component.settings?.layout?.mobile,
        ...(isMobileOrOdd && newRowLayout),
      },
    },
  };

  return component;
};

export const updateComponentUsingCommand = ({
  component,
  commands,
}: {
  component: string;
  commands: ComponentCommand[];
}): { component: string } | undefined => {
  const jsonComponent = convertComponentToJSON(component);
  const originalJsonComponent = cloneDeepObject(jsonComponent);
  if (jsonComponent) {
    for (const command of commands) {
      // Add component
      if (command.type == 'add_component') {
        if (command.direction == 'children') {
          // Drag to children component
          const parent = getComponentByUid(jsonComponent, command.toComponentUid);
          if (parent) {
            const childs = parent.childrens || [];
            if (Array.isArray(command.newComponents)) {
              for (let i = 0; i < command.newComponents.length; i++) {
                const item = command.newComponents[i];
                childs.push(item);
              }
            } else {
              childs.push(command.newComponents);
            }
            updateSpacingLastComponent(childs[childs.length - 1]);
            parent.childrens = childs;
          } else {
            //find in original children
            if (!originalJsonComponent) continue;
            const parent = getParentComponentByUid(originalJsonComponent, command.toComponentUid);
            if (parent) {
              const childs = parent.childrens || [];
              for (const child of childs) {
                if (child.uid == command.toComponentUid) {
                  child.childrens = command.newComponents;
                } else {
                  child.childrens = [];
                }
              }
              parent.childrens = childs;

              const paths = getComponentDiff(jsonComponent, originalJsonComponent);

              for (const path of paths) {
                switch (path.type) {
                  case 'add':
                    if (path?.addParams) {
                      const componentShouldAdd = path.addParams.entity;
                      const grandParent = getParentComponentByUid(originalJsonComponent, componentShouldAdd.uid);
                      if (!grandParent) break;
                      const grandParentChilds = grandParent?.childrens || [];
                      const rowIndex =
                        grandParentChilds &&
                        grandParentChilds.findIndex((child: { uid: string }) => child.uid == componentShouldAdd.uid);
                      if (rowIndex < 0) break;

                      let toComponentUid;
                      let direction: 'before' | 'after' | 'children';

                      if (grandParentChilds?.length <= 1) {
                        // add to children
                        toComponentUid = grandParent.uid;
                        direction = 'children';
                      } else {
                        // add to before or after element
                        let targetElement;
                        if (rowIndex === grandParentChilds.length - 1) {
                          targetElement = grandParentChilds[rowIndex - 1];
                          direction = 'after'; // Add after the targetElement
                        } else {
                          targetElement = grandParentChilds[rowIndex + 1];
                          direction = 'before'; // Add before the targetElement
                        }

                        toComponentUid = targetElement.uid;
                      }
                      return updateComponentUsingCommand({
                        component: convertComponentToString(jsonComponent),
                        commands: [
                          {
                            type: 'add_component',
                            newComponents: [componentShouldAdd],
                            toComponentUid,
                            direction,
                          },
                        ],
                      });
                    }
                    break;

                  default:
                    break;
                }
              }
            }
          }
        } else if (command.direction == 'after' || command.direction == 'before') {
          const parent = getParentComponentByUid(jsonComponent, command.toComponentUid);
          if (parent) {
            const childs = parent.childrens;
            if (childs?.length) {
              let index = childs.findIndex((child: { uid: string }) => child.uid == command.toComponentUid);
              command.newComponents.forEach((component) => {
                // Drag to before or after component
                if (command.direction == 'before' || command.direction == 'after') {
                  if (command.direction == 'before') {
                    if (index == 0) {
                      childs.unshift(component);
                      index++; // After element append
                    } else {
                      childs.splice(index, 0, component);
                      index++; // After element append
                    }
                  } else {
                    childs.splice(index + 1, 0, component);
                    index++; // After element append
                  }
                }
              });
              updateSpacingComponent(parent, command.newComponents);
            }
          } else {
            //find in original children
            if (!originalJsonComponent) continue;
            const parent = getParentComponentByUid(originalJsonComponent, command.toComponentUid);
            if (parent) {
              const parentChilds = parent.childrens;
              if (parentChilds?.length) {
                let toComponentUid;
                let direction: 'before' | 'after' | 'children';
                if (parentChilds.length <= 1) {
                  toComponentUid = parent.uid;
                  direction = 'children';
                } else {
                  const index = parentChilds.findIndex((child: { uid: string }) => child.uid == command.toComponentUid);
                  const prevElement = parentChilds[index - 1];
                  const nextElement = parentChilds[index + 1];
                  let targetElement;

                  if (command.direction === 'before') {
                    if (index === 0) {
                      targetElement = nextElement;
                    } else {
                      targetElement = prevElement;
                    }
                  } else {
                    if (index === parentChilds.length - 1) {
                      targetElement = prevElement;
                    } else {
                      targetElement = nextElement;
                    }
                  }
                  if (!targetElement) continue;
                  toComponentUid = targetElement.uid;
                  direction = command.direction;
                }
                return updateComponentUsingCommand({
                  component: convertComponentToString(jsonComponent),
                  commands: [
                    {
                      type: 'add_component',
                      newComponents: command.newComponents,
                      toComponentUid,
                      direction,
                    },
                  ],
                });
              }
            }
          }
        } else if (command.direction == 'left' || command.direction == 'right') {
          const initRow2Cols = (toComponentUid: string, isCol?: boolean) => {
            const builderConfigStore = useBuilderConfigStore();
            const row2Cols = builderConfigStore.getComponentPresetById('layout-2-col');
            const newRowComponents = initNewPreset(row2Cols.components);
            const newRow = { ...newRowComponents[0], isAutoGenerated: true };
            const cols = newRow.childrens || [];
            cols.forEach((col, index) => {
              const childs = col.childrens || [];
              // push to left or right the row
              if ((command.direction == 'right' && index === 1) || (command.direction == 'left' && index === 0)) {
                if (Array.isArray(command.newComponents)) {
                  for (let i = 0; i < command.newComponents.length; i++) {
                    const item = command.newComponents[i];
                    childs.push(item);
                  }
                } else {
                  childs.push(command.newComponents);
                }
              } else {
                if (!isCol) {
                  const toComponent = getComponentByUid(jsonComponent, toComponentUid);
                  toComponent && childs.push(toComponent);
                }
              }
              col.childrens = childs;
              col.childrens = updateSpacingColumnOfNewRow(col);
            });
            return newRow;
          };

          const parent = getParentComponentByUid(jsonComponent, command.toComponentUid);
          const toComponent = getComponentByUid(jsonComponent, command.toComponentUid);
          // Case drag to Col
          if (toComponent && toComponent.tag === 'Col') {
            let toComponentParent = getParentComponentByUid(jsonComponent, toComponent.uid);
            const isIncreaseRowCol = toComponentParent && isSectionRowProduct(toComponentParent.tag);
            if (isIncreaseRowCol && toComponentParent) {
              if (verifyMaxColumns(component, command.toComponentUid)) return;
              // increase Row col
              const childs = toComponentParent.childrens || [];
              if (childs.length < 6) {
                toComponentParent = isIncreaseRowColumns(toComponentParent);
                const childs = toComponentParent.childrens || [];
                const cloneCol = { ...toComponent, uid: ID(), childrens: command.newComponents };
                if (!cloneCol) return;
                updateSpacingComponent(cloneCol, command.newComponents);

                // add clone columns to the Row
                const index = childs.findIndex((child: { uid: string }) => child.uid == toComponent.uid);
                const positionCol = command.direction == 'left' ? index : index + 1;
                childs.splice(positionCol, 0, cloneCol);
              }
            } else {
              // init new row if row parent doesn't exist
              if (toComponentParent) {
                const newRow = initRow2Cols(command.toComponentUid, true);
                if (toComponentParent?.childrens) {
                  const index =
                    toComponentParent.childrens.findIndex((child: { uid: string }) => child.uid == toComponent.uid) ||
                    0;
                  toComponentParent.childrens[index] = newRow;
                }
                updateSpacingComponent(toComponentParent, [newRow]);
              }
            }
          }
          // Case drag to element
          else {
            if (!parent) return;
            const childsLength = (Array.isArray(parent?.childrens) && parent?.childrens.length) || 0;
            let rowParent = getParentComponentByUid(jsonComponent, parent.uid);
            const rowParentCols = (Array.isArray(rowParent?.childrens) && rowParent?.childrens.length) || 0;
            const isIncreaseRowCol =
              rowParent &&
              isSectionRowProduct(rowParent.tag) &&
              rowParentCols >= 1 &&
              childsLength === 1 &&
              !verifyMaxColumns(component, command.toComponentUid);
            if (isIncreaseRowCol && rowParent) {
              // increase Row col
              rowParent = isIncreaseRowColumns(rowParent);
              const childs = rowParent.childrens || [];
              const cloneCol = { ...parent, uid: ID(), childrens: command.newComponents };
              updateSpacingComponent(cloneCol, command.newComponents);

              // add clone columns to the Row
              const index = childs.findIndex((child: { uid: string }) => child.uid == parent.uid);
              const positionCol = command.direction == 'left' ? index : index + 1;
              childs.splice(positionCol, 0, cloneCol);
            } else {
              // init new row if row parent doesn't exist or row parent exist but childs length > 1
              const newRow = initRow2Cols(command.toComponentUid, false);
              if (parent?.childrens) {
                const index =
                  parent.childrens.findIndex((child: { uid: string }) => child.uid == command.toComponentUid) || 0;
                parent.childrens[index] = newRow;
              }
              updateSpacingComponent(parent, [newRow]);
            }
          }
        }
      }

      // Delete component
      if (command.type == 'remove_component') {
        if (jsonComponent?.uid == command.componentUid) {
          // Do not delete section uid. Please fix to delete section
        } else {
          const toComponentParent = getComponentByUid(jsonComponent, command.componentUid);
          const parent = getParentComponentByUid(jsonComponent, command.componentUid);
          if (!toComponentParent || !parent) return;
          if (toComponentParent.tag === 'Col') {
            // delete layout column
            const isSection1Column = parent.tag == 'Section' && parent.childrens?.length == 1;
            if (isSection1Column) {
              // Delete all children of Section 1 column
              if (toComponentParent?.childrens) {
                toComponentParent.childrens.length = 0;
              }
            } else {
              const childs = parent.childrens;
              if (childs) {
                const newCol = (childs?.length ?? 1) - 1;
                parent.settings = {
                  ...parent.settings,
                  layout: {
                    desktop: {
                      ...parent.settings?.layout?.desktop,
                      cols: Array.from({ length: newCol }, () => 12 / newCol),
                    },
                  },
                };
                const index = childs.findIndex((child: { uid: string }) => child.uid == command.componentUid);
                if (index > -1) {
                  childs.splice(index, 1);
                }
              }
            }
          } else {
            componentRemoveEntity(parent, command.componentUid);
            removeEmptyRow(parent, jsonComponent);
          }
        }
      }
    }

    return {
      component: convertComponentToString(jsonComponent),
    };
  }
};

export function flattenObject(obj: any) {
  const result: any = {};

  function flatten(node: any, parentUid = null, index: number) {
    const uid = node.uid;
    const newNode = { ...node };

    if (parentUid !== null) {
      newNode.parentUid = parentUid;
      newNode.position = index;
    }

    delete newNode.uid;
    delete newNode.childrens;

    result[uid] = newNode;

    if (node.childrens && node.childrens.length > 0) {
      node.childrens.forEach((child: any, index: number) => {
        flatten(child, uid, index);
      });
    }
  }

  flatten(obj, null, 0);

  return result;
}

export function getComponentDiff(jsonOld: Component | undefined, jsonNew: Component | undefined) {
  const jsonNewOriginal = cloneDeepObject(jsonNew);
  const flatJsonOld = flattenObject(jsonOld);
  const flatJsonNew = flattenObject(jsonNew);

  const diff: BuilderDiffType[] = [];
  const addedUids: string[] = [];

  const removeComponent = (uid: string) => {
    diff.push({ type: 'remove', uid });
    removeAllChildren(uid);
  };

  const removeAllChildren = (parentUid: string) => {
    // remove all children
    for (const uid in flatJsonOld) {
      if (flatJsonOld[uid].parentUid == parentUid) {
        delete flatJsonOld[uid];
        removeAllChildren(uid);
      }
    }
  };

  const verifyAddComponent = (parentUid: string) => {
    // check component should be add
    for (const uid in flatJsonNew) {
      if (flatJsonNew[uid].parentUid == parentUid) {
        if (flatJsonOld[uid]) {
          removeComponent(uid);
        }
        addedUids.push(uid);
        verifyAddComponent(uid);
      }
    }
  };

  // Remove elements
  for (const uid in flatJsonOld) {
    // Check for keys in flatJsonOld that are not in flatJsonNew
    if (flatJsonOld[uid] && !flatJsonNew[uid]) {
      removeComponent(uid);
    }
  }

  // Add elements
  for (const uid in flatJsonNew) {
    if (!flatJsonOld[uid] && flatJsonNew[uid]) {
      // dont add if parent is already added
      if (!addedUids.includes(uid)) {
        const component = jsonNewOriginal && uid && getComponentByUid(jsonNewOriginal, uid);
        if (component) {
          const parent = getParentComponentByUid(jsonNewOriginal, component.uid);
          if (parent) {
            const toParent = parent.uid;
            const childs = parent.childrens;
            const index = (childs && childs.findIndex((child: { uid: string }) => child.uid == component.uid)) || 0;
            verifyAddComponent(uid);
            diff.push({
              type: 'add',
              addParams: { entity: component, id: toParent, position: index },
            });
          }
        }
      }
    }
  }

  // Move elements
  for (const uid in flatJsonOld) {
    // check moved position element
    if (
      !addedUids.includes(uid) &&
      flatJsonOld[uid] &&
      flatJsonNew[uid] &&
      (flatJsonOld[uid].parentUid !== flatJsonNew[uid].parentUid ||
        flatJsonOld[uid].position !== flatJsonNew[uid].position)
    ) {
      const component = jsonNewOriginal && uid && getComponentByUid(jsonNewOriginal, uid);
      if (component) {
        const parent = getParentComponentByUid(jsonNewOriginal, component.uid);
        if (parent) {
          const toParent = parent.uid;
          const childs = parent.childrens;
          const index = (childs && childs.findIndex((child: { uid: string }) => child.uid == component.uid)) || 0;
          diff.push({
            type: 'move',
            moveParams: {
              uid,
              to: toParent,
              position: index,
            },
          });
        }
      }
    }
  }

  // Update settings, advanced elements
  for (const uid in flatJsonOld) {
    // check setting and advanced was changed
    if (flatJsonOld[uid] && flatJsonNew[uid] && JSON.stringify(flatJsonOld[uid]) !== JSON.stringify(flatJsonNew[uid])) {
      const groupTypeDiff = getGroupTypeDiff(flatJsonOld[uid], flatJsonNew[uid]);
      groupTypeDiff.forEach((item: any) => {
        diff.push({
          type: 'update',
          updateParams: {
            uid,
            propName: item.propName,
            propValue: item.propValue,
            group: item.groupType,
          },
        });
      });
    }
  }

  return diff;
}

function getGroupTypeDiff(oldVal: any, newVal: any) {
  const diff: {}[] = [];
  const groupTypeList: string[] = ['style', 'setting', 'advanced'];
  for (const groupType in oldVal) {
    if (
      oldVal[groupType] &&
      newVal[groupType] &&
      JSON.stringify(oldVal[groupType]) !== JSON.stringify(newVal[groupType])
    ) {
      const groupValue = newVal[groupType];
      for (const key in groupValue) {
        if (
          oldVal[groupType][key] &&
          newVal[groupType][key] &&
          JSON.stringify(oldVal[groupType][key]) !== JSON.stringify(newVal[groupType][key])
        ) {
          const groupTypeVal = groupType === 'settings' ? 'setting' : groupType;
          if (groupTypeList.includes(groupTypeVal)) {
            diff.push({ groupType: groupTypeVal, propName: key, propValue: groupValue[key] });
          }
        }
      }
    }
  }
  return diff;
}

const splitAtCapital = (s: string) => {
  return s.split(/(?=[A-Z])/).join(' ');
};

export const generateNameFlowTags = (tags: string[]) => {
  let name = '';
  if (tags.length > 1) {
    name = tags.reduce((prev, curr) => {
      if (prev && prev !== '') {
        return prev + ' or ' + splitAtCapital(curr);
      }
      return splitAtCapital(curr);
    }, '');
  }
  return name;
};

export const getAllChildrensFlowTags = (components: Component[], loopChild?: boolean) => {
  return getChildrensFlowTags(components, [], '', loopChild);
};

const getChildrensFlowTags = (
  components: Component[],
  flowTags: string[] = [],
  parentLabel?: string,
  isHasChild?: boolean,
  flowTagsMessage: string[] = [],
): { flowTags: string[]; parentLabel?: string; isHasChild?: boolean; flowTagsMessage: string[] } => {
  const sectionStore = useSectionStore();
  for (const component of components) {
    const currentFlowTags = getFlowTags([component]);
    const currentFlowTagsMessage = getFlowTagsMessage([component]);
    flowTags = arrayUnique([...flowTags, ...currentFlowTags]);
    flowTagsMessage = arrayUnique([...flowTagsMessage, ...currentFlowTagsMessage]);

    const componentUid = component.uid;
    const sectionId = cacheGetSectionCidByComponentUid(componentUid);
    const section = sectionStore.getItemByCid(sectionId);
    const sectionItemComponent = JSON.parse(section?.component ?? '{}');
    const PARENT_LIST = ['Newsletter', 'ContactForm', 'ProductList', 'Product', 'PostPurchaseProductOffer'];
    const parentComponent = findParentByUidAndByParentTag(sectionItemComponent, componentUid, PARENT_LIST);

    if (!parentLabel) {
      parentLabel = parentComponent?.label;
    }
    if ((!!parentComponent || isHasChild) && component.childrens?.length) {
      isHasChild = true;

      const child = getChildrensFlowTags(component.childrens, flowTags, parentLabel, isHasChild, flowTagsMessage);

      if (arraysIntersect(child.flowTags, PARENT_LIST)) {
        flowTags = arrayUnique([...flowTags, ...child.flowTags]);
      }
      if (arraysIntersect(child.flowTagsMessage, PARENT_LIST)) {
        flowTagsMessage = arrayUnique([...flowTagsMessage, ...child.flowTagsMessage]);
      }

      if (!parentLabel) {
        parentLabel = child.parentLabel;
      }
    }
  }

  return { flowTags, parentLabel, isHasChild, flowTagsMessage };
};

export const changeActiveCoupon = (
  components: Component | InitComponentType,
  newComponents: Component[],
  toSectionCid: any,
) => {
  const couponComponent = getComponentsByTag(components as Component, COUPON_TAG, []);
  if (components.tag == ROW_TAG && couponComponent[0]?.tag === COUPON_TAG) {
    const couponData = getComponentsByTag(newComponents[0], COUPON_TAG, []);
    const couponUid = couponData[0]?.uid ?? '';
    onOpenSetting(couponUid, toSectionCid, '');
  }
};
export const changePresetRowMobile = (components: Component[]) => {
  const currentDevice = useCurrentDevice();
  if (components[0]?.tag == ROW_TAG && currentDevice.value === 'mobile') {
    if (components[0]?.settings?.layout?.mobile?.cols) {
      components[0].settings.layout.mobile.cols = components[0].settings?.layout?.desktop?.cols ?? [12];
    }
  }
  return components;
};
