import type { DataSearch } from './useComponentPresetList';
import searchPriorityPresetJson from './searchPriority.json';

interface KeywordResult {
  keyword: string[];
  results: string[];
}

export const useSearchPresets = () => {
  const searchTopPresetsByKeyword = (keyword: string, presets: DataSearch[]): string[] => {
    const searchResult: KeywordResult | undefined = searchPriorityPresetJson.find((item) =>
      item.keyword.some((kw) => kw === keyword.toLowerCase()),
    );

    const topResults: string[] =
      searchResult?.results.filter((result) => presets.some((preset) => result.includes(preset.presetId))) ?? [];

    return topResults;
  };

  return {
    searchTopPresetsByKeyword,
  };
};
