type Config = {
  env: 'local' | 'production';
  configEnv: 'development' | 'staging' | 'production';
  url: {
    gateway: string;
    ws: string;
    app: string;
    instant: string;
    ai: string;
    appApiDomain: string;
    gemAIReadMoreLink: string;
    wsv5: string;
    baseThemeUrl: string;
  };
  featureConfig: {
    sentryCDN: string;
    domainCDN: string;
    ipAddress: string;
    instantBackToURL: string | undefined;
    embedAppId: string | undefined;
  };
  thirdParty: {
    gtm: string;
    posthog: string;
    gaMeasurementID: string;
  };
  shopify: {
    proxyPath: string;
    themeExtensionId: string;
  };
  overrideFeatureFlags: Record<string, boolean>;
  bootstrapFeatureFlags: Record<string, boolean>;
  skipPricingLimitation: Record<string, string>;
};

const defaultConfig = (): Config => {
  if (!import.meta.env.VITE_APP_URL) {
    throw 'Miss env: VITE_APP_URL';
  }
  if (!import.meta.env.VITE_GATEWAY_URL) {
    throw 'Miss env: VITE_GATEWAY_URL';
  }
  if (!import.meta.env.VITE_APP_GTAG) {
    throw 'Miss env: VITE_APP_GTAG';
  }
  if (!import.meta.env.VITE_APP_WEBSOCKET_URL) {
    throw 'Miss env: VITE_APP_WEBSOCKET_URL';
  }
  if (!import.meta.env.VITE_APP_POSTHOG) {
    throw 'Miss env: VITE_APP_POSTHOG';
  }
  if (!import.meta.env.VITE_DOMAIN_CDN) {
    throw 'Miss env: VITE_DOMAIN_CDN';
  }
  if (!import.meta.env.VITE_SHOPIFY_PROXY_PATH) {
    throw 'Miss env: VITE_SHOPIFY_PROXY_PATH';
  }
  // if (!import.meta.env.VITE_GEM_AI_URL) {
  //   throw 'Miss env: VITE_GEM_AI_URL';
  // }

  return {
    configEnv: 'development',
    env: 'local',
    url: {
      app: import.meta.env.VITE_APP_URL.toString(),
      ws: import.meta.env.VITE_APP_WEBSOCKET_URL.toString(),
      wsv5: import.meta.env.VITE_APP_WEBSOCKET_URL_V5?.toString() || '',
      gateway: import.meta.env.VITE_GATEWAY_URL.toString(),
      instant: import.meta.env.VITE_APP_URL.toString(),
      ai: import.meta.env.VITE_GEM_AI_URL?.toString() || '',
      appApiDomain: import.meta.env.VITE_APP_API_DOMAIN?.toString() || '',
      gemAIReadMoreLink: import.meta.env.VITE_APP_READ_MORE_GEM_AI_LINK?.toString() || '',
      baseThemeUrl: import.meta.env.VITE_BASE_THEME_URL?.toString() || '',
    },
    featureConfig: {
      sentryCDN: 'https://4b8407e5e9d64479b12e0196a53073f2@sentry.gemcommerce.xyz/10',
      domainCDN: import.meta.env.VITE_DOMAIN_CDN.toString(),
      ipAddress: import.meta.env.VITE_IP_ADDRESS?.toString(),
      instantBackToURL: import.meta.env.VITE_EDITOR_BACK_TO_URI?.toString(),
      embedAppId: import.meta.env.VITE_GEMPAGES_APP_ID?.toString(),
    },
    thirdParty: {
      gtm: import.meta.env.VITE_APP_GTAG.toString(),
      posthog: import.meta.env.VITE_APP_POSTHOG.toString(),
      gaMeasurementID: import.meta.env.VITE_GA_MEASUREMENT_ID.toString(),
    },
    shopify: {
      proxyPath: import.meta.env.VITE_SHOPIFY_PROXY_PATH.toString(),
      themeExtensionId: import.meta.env.VITE_SHOPIFY_THEME_EXTENSION_ID.toString(),
    },
    overrideFeatureFlags: {},
    bootstrapFeatureFlags: {},
    skipPricingLimitation: {
      interaction: import.meta.env.VITE_SKIP_INTERACTION_LIMITATION || 'false',
    },
  };
};

const config: Record<'local' | 'development' | 'staging' | 'production', Config> = {
  local: {
    ...defaultConfig(),
    configEnv: 'development',
    overrideFeatureFlags: {
      'element-icon-list': true,
      'element-yotpo-loyalty': true,
      'element-klaviyo': true,
      'convert-gempages': true,
      'editor-getting-started': true,
      'tracking-code': true,
      'editor-upcoming': true,
      'login-as-shop': true,
      'enabled-layer-tree': true,
      'version-history': true,
      'enable-shop-upgrade-changelog': true,
      'i2l-box2layout': true,
    },
    bootstrapFeatureFlags: {
      'gempages-v5': true,
    },
  },
  development: {
    ...defaultConfig(),
    configEnv: 'development',
    overrideFeatureFlags: {
      'element-icon-list': true,
      'element-yotpo-loyalty': true,
      'element-klaviyo': true,
      'convert-gempages': true,
      'editor-getting-started': true,
      'tracking-code': true,
      'editor-upcoming': true,
      'login-as-shop': true,
      'enabled-layer-tree': true,
      'version-history': true,
      'enable-shop-upgrade-changelog': true,
      'i2l-box2layout': true,
    },
    bootstrapFeatureFlags: {
      'gempages-v5': true,
    },
  },
  staging: {
    ...defaultConfig(),
    configEnv: 'staging',
    env: 'production',
    overrideFeatureFlags: {
      'convert-gempages': true,
      'enable-shop-upgrade-changelog': true,
    },
    bootstrapFeatureFlags: {
      'gempages-v5': true,
    },
  },
  production: {
    ...defaultConfig(),
    configEnv: 'production',
    env: 'production',
    overrideFeatureFlags: {},
    bootstrapFeatureFlags: {
      'gempages-v5': false,
    },
  },
};

export const getConfig = <T extends keyof Config>(key: T): Config[T] => {
  if (!import.meta.env.VITE_CONFIG_ENV) {
    throw 'Miss env: VITE_CONFIG_ENV';
  }
  if (['local'].includes(import.meta.env.VITE_CONFIG_ENV)) {
    return config.local[key];
  } else if (['dev'].includes(import.meta.env.VITE_CONFIG_ENV)) {
    return config.development[key];
  } else if (['staging'].includes(import.meta.env.VITE_CONFIG_ENV)) {
    return config.staging[key];
  }
  return config.production[key];
};
