import Fuse from 'fuse.js';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import { usePageInformation } from '@/modules/editor/modules/common/hooks/usePageInformation';
import type { PresetGroup, ComponentPreset, SortableComponents } from '@/modules/editor/modules/common/utils/types';
// import { isFeatureEnabled } from '@gem/feature-flag';
import useBuilderConfigStore from '../../component-preset/stores/builderConfig';
import { useGtm } from '@gtm-support/vue-gtm';
import { EDITOR_SEARCH_WORKFLOW_EVENT } from '../../common/constants/trackEventIds';
import useShopStore from '@/stores/shop';
import { useSearchPresets } from './useSearchPresets';

export type DataSearch = {
  presetId: string;
  groupLabel: string;
  presetLabel: string;
  presetKeyword?: string[];
  presetKeywordLow?: string[];
};

type Options = {
  raw?: boolean;
};

export const useComponentPresetList = (sortableComponentsJson: any, options?: Options) => {
  const editorStore = useEditorStore();
  const builderConfigStore = useBuilderConfigStore();
  const { pageType } = usePageInformation();
  const { searchTopPresetsByKeyword } = useSearchPresets();

  const presetPresets = computed(() => builderConfigStore.getComponentPresets);
  const searchKeyword = computed(() => editorStore.searchKeyword);

  const componentPresetGroups = computed(() => {
    return getComponentPresetGroups();
  });

  const getComponentPresetGroups = () => {
    const groupPresets: PresetGroup[] = [];
    const arrDataSearch: DataSearch[] = [];
    const sortableComponents: SortableComponents = sortableComponentsJson;
    // Sort presets
    sortableComponents.forEach((sortableComponent) => {
      const isHideOnCurrentPage = sortableComponent.hideOnPages?.find((item) => item === pageType.value)?.length;
      if (isHideOnCurrentPage) {
        return;
      }
      const groupPresetItem: ComponentPreset[] = [];

      sortableComponent.presets?.forEach((preset) => {
        const { id, keyword, hideOnPages, keywordLow } = preset;
        const presetPreset = presetPresets.value[id];
        if (presetPreset) {
          // Check Element hide on page
          const isHideOnCurrentPage = pageType?.value && hideOnPages?.find((item) => item == pageType.value);
          if (isHideOnCurrentPage) {
            return;
          }
          arrDataSearch.push({
            presetId: id,
            groupLabel: sortableComponent.name,
            presetLabel: presetPreset.name.en,
            presetKeyword: keyword?.split(',').map((i) => i.trim()),
            presetKeywordLow: keywordLow?.split(',').map((i) => i.trim()),
          });
          groupPresetItem.push(presetPreset);
        }
      });
      groupPresetItem.length &&
        groupPresets.push({
          label: sortableComponent.name,
          hideLabel: sortableComponent.hideLabel,
          presets: groupPresetItem,
          orderBy: sortableComponent?.orderBy,
        });
    });

    // Search
    let newGroupPresets: PresetGroup[] = [];
    const topComponentPresets: ComponentPreset[] = [];
    const NUMBER_RESULT = 20;
    if (searchKeyword.value && !options?.raw) {
      const topSearchPresets = searchTopPresetsByKeyword(searchKeyword.value, arrDataSearch);
      const fuse = new Fuse(arrDataSearch, {
        includeScore: true,
        minMatchCharLength: searchKeyword.value.length > 5 ? 5 : searchKeyword.value.length,
        keys: [
          {
            name: 'groupLabel',
            weight: 0.1,
          },
          {
            name: 'presetLabel',
            weight: 0.9,
          },
          {
            name: 'presetKeyword',
            weight: 0.5,
          },
          {
            name: 'presetKeywordLow',
            weight: 0.2,
          },
          {
            name: 'presetId',
            weight: 0.05,
          },
        ],
      });
      const result = fuse.search(searchKeyword.value);
      result.sort((a, b) => {
        if ((a.score || 0) >= (b.score || 0)) return 1;
        return -1;
      });
      result.forEach((resultItem, index) => {
        if (index < NUMBER_RESULT - 1) {
          const group = groupPresets.find((item) => item.label == resultItem.item.groupLabel);
          if (group) {
            const isGroup = newGroupPresets.find((item) => item.label == group?.label);
            if (!isGroup) {
              const presets: ComponentPreset[] = [];
              const presetsShow = result.filter(
                (presetShow, index) => presetShow.item.groupLabel == group.label && index < NUMBER_RESULT - 1,
              );
              presetsShow.forEach((presetShow) => {
                const preset = group.presets.find((preset) => preset.id == presetShow.item.presetId);
                if (preset) {
                  const isTopPreset = topSearchPresets.find((item) => item == preset.id);
                  if (isTopPreset) {
                    topComponentPresets.push(preset);
                  } else {
                    presets.push(preset);
                  }
                }
              });
              const newPresets = orderBy(presets, group?.orderBy);
              newGroupPresets.push({ ...group, presets: newPresets });
            }
          }
        }
      });
      if (topSearchPresets.length) {
        const topGroupPresets: PresetGroup = {
          label: '',
          hideLabel: false,
          presets: topComponentPresets,
        };
        newGroupPresets.unshift(topGroupPresets);
      }
      const isExistsResult = result.length > 0;
      handleTrackingSearchResult(isExistsResult);
    } else {
      newGroupPresets = groupPresets;
    }

    return [...newGroupPresets];
  };

  return {
    componentPresetGroups,
  };
};

const orderBy = (presets: ComponentPreset[], key?: string) => {
  if (!key) return presets;

  const compareFunction = (a: ComponentPreset, b: ComponentPreset) => {
    if (key === 'asc') {
      return a.id.localeCompare(b.id);
    } else if (key === 'desc') {
      return b.id.localeCompare(a.id);
    }
    return 0;
  };

  return [...presets].sort(compareFunction);
};

export const handleTrackingSearchResult = (
  result: boolean,
  drag = false,
  drag_element = '',
  drag_element_uid = '',
  onDelete = false,
) => {
  const editorStore = useEditorStore();
  const gtm = useGtm();
  const shopStore = useShopStore();

  const searchKeyword = computed(() => editorStore.searchKeyword);
  const shopifyDomain = computed(() => shopStore.getShopDomain);

  const isExistsSearchKeyword = searchKeyword.value && searchKeyword.value?.length > 0;
  if (!isExistsSearchKeyword) {
    return;
  }
  gtm?.trackEvent({
    event: 'editor_search_workflow',
    data: {
      event_id: EDITOR_SEARCH_WORKFLOW_EVENT.EDITOR_SEARCH,
      time: new Date().toISOString(),
      keyword: searchKeyword.value,
      result: result,
      drag: drag,
      element_tag: drag_element,
      element_uid: drag_element_uid,
      drag_time: drag ? new Date().toISOString() : '',
      delete: onDelete,
      store_domain: shopifyDomain.value,
    },
  });
};
