import type { ComponentTag } from '@gem/control';

import useBuilderConfigStore from '../../component-preset/stores/builderConfig';

const usePreventAppendTag = () => {
  const builderConfigStore = useBuilderConfigStore();

  const isPreventAppend = (draggingComponentTag: ComponentTag, nearComponent?: HTMLElement) => {
    if (!nearComponent) return false;

    const tagName = nearComponent.getAttribute('data-component-tag') as ComponentTag;
    if (!tagName) return false;

    const elementSetting = builderConfigStore.getBuilderSettingByTag(tagName);
    if (!elementSetting) return false;

    const allowAppendTags = elementSetting.editorConfigs?.placeholder?.allowAppendTags;
    if (!allowAppendTags || !allowAppendTags.length) return false;

    return !elementSetting.editorConfigs?.placeholder?.allowAppendTags?.includes(draggingComponentTag);
  };

  return {
    isPreventAppend,
  };
};

export default usePreventAppendTag;
