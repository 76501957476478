import type { GlobalSwatchesData } from '@gem/common';
import type { PageSection } from '@/types/graphql';
import { cloneDeepObject, isDefined } from '@/utils/common';
import useEditorStore from '../../common/stores/editor';
import {
  convertComponentToJSON,
  createBuilderComponentBySection,
  getComponentDiff,
  modifyComponentBeforeBuilderState,
} from '../../common/utils/section/component';
import type { Component, BuilderDiffType, GroupTypeSetting } from '../../common/utils/types';
import useBuilderConfigStore from '../../component-preset/stores/builderConfig';
import {
  emitAddEntity,
  emitInitBuilder,
  emitMoveEntity,
  emitRemoveEntity,
  emitSetGlobalStyle,
  emitUpdateEntityProp,
  emitForceUpdateEntityProps,
  emitUpdateGlobalSwatchesData,
  emitUpdateToolbarMarginBottom,
  emitUpdateToolbarPaddingLeft,
} from './emitToIframe';

export function builderInit(sections: PageSection[], callback?: () => void, iframeClass?: string) {
  if (sections?.length) {
    const builderConfigStore = useBuilderConfigStore();
    const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
    const components = sections
      .map((section) => createBuilderComponentBySection(section, builderSettings))
      .filter(isDefined);
    // Emit new state
    emitInitBuilder(components, callback, iframeClass);
  } else {
    emitInitBuilder([], callback, iframeClass);
  }
}

export function builderAddSection(section: PageSection, position: number, callback?: () => void) {
  const builderConfigStore = useBuilderConfigStore();
  const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
  const component = createBuilderComponentBySection(section, builderSettings);
  if (component) {
    emitAddEntity(
      {
        entity: component,
        position: position,
      },
      callback,
    );
  }
}

export function builderAddComponent(component: Component, id: string, position: number, callback?: () => void) {
  if (component) {
    const builderConfigStore = useBuilderConfigStore();
    const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
    const cloneComponent = cloneDeepObject(component);
    modifyComponentBeforeBuilderState(cloneComponent, builderSettings);
    emitAddEntity(
      {
        entity: cloneComponent,
        id,
        position,
      },
      callback,
    );
  }
}

export function builderDeleteSection(section: PageSection, callback?: () => void) {
  const component = convertComponentToJSON(section?.component || '');
  if (component?.uid) {
    emitRemoveEntity(component.uid, callback);
  }
}

export function builderMoveComponent(data: { uid: string; to: string; position: number }, callback?: () => void) {
  emitMoveEntity(data, callback);
}

export function builderDeleteComponent(uid: string, callback?: () => void) {
  emitRemoveEntity(uid, callback);
}

export function builderUpdateComponent(
  data: { uid: string; propName: string; propValue: any; group: GroupTypeSetting },
  callback?: () => void,
) {
  emitUpdateEntityProp(data, callback);
}

export function builderForceUpdateComponents(
  data: { uid: string; data: Record<string, any>; group: GroupTypeSetting },
  callback?: () => void,
) {
  emitForceUpdateEntityProps(data, callback);
}

export function builderSetGlobalStyle(
  { data, mobileOnly, iframeClass }: { data: any; mobileOnly?: boolean; iframeClass?: string },
  callback?: () => void,
) {
  if (data) {
    emitSetGlobalStyle(
      {
        globalStyle: data,
        mobileOnly,
      },
      callback,
      iframeClass,
    );
  }
}

export function builderUpdateSwatchesData(swatchesData: GlobalSwatchesData[], callback?: () => void) {
  if (swatchesData) {
    emitUpdateGlobalSwatchesData(
      {
        swatchesData: swatchesData,
      },
      callback,
    );
  }
}

export function builderUpdateToolbar() {
  emitUpdateToolbarMarginBottom();
  emitUpdateToolbarPaddingLeft();
}

export function builderApplyChangeset(paths: BuilderDiffType[]) {
  for (const path of paths) {
    switch (path.type) {
      case 'remove':
        path.uid && emitRemoveEntity(path.uid);
        break;
      case 'add':
        if (path?.addParams) {
          const builderConfigStore = useBuilderConfigStore();
          const builderSettings = cloneDeepObject(builderConfigStore.getBuilderSettings || {});
          const cloneComponent = cloneDeepObject(path.addParams.entity);
          modifyComponentBeforeBuilderState(cloneComponent, builderSettings);
          emitAddEntity({ ...path.addParams, entity: cloneComponent });
        }
        break;
      case 'update':
        if (path?.updateParams) {
          emitUpdateEntityProp(path.updateParams);
          const editorStore = useEditorStore();
          const editingComponentUid = editorStore.getEditingComponentUid;
          const isUpdateToolbar =
            path.updateParams.propName === 'spacing-setting' && path.updateParams.uid === editingComponentUid;
          if (isUpdateToolbar) {
            builderUpdateToolbar();
          }
        }
        break;
      case 'move':
        path?.moveParams && emitMoveEntity(path.moveParams);
        break;

      default:
        break;
    }
  }
}

export function builderGetChangeset(oldComponent: string, newComponent: string) {
  const jsonOld = convertComponentToJSON(oldComponent);
  const jsonNew = convertComponentToJSON(newComponent);
  const paths = getComponentDiff(jsonOld, jsonNew);
  return paths;
}
