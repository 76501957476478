import useShopStore from '@/stores/shop';
import type { ConnectedType } from '@/types/connect';
import { useRoute } from 'vue-router';
import useNotification from './useNotification';
import { useShopQuery } from './useShopQuery';
import useShopLoginQuery from '@/api/auth/queries/useShopLoginQuery';
import type { ShopLoginResponse } from '@/api/auth/queries/shopLogin';

export const useInstantAuthentication = (
  connectedType: ComputedRef<ConnectedType>,
  editorType: ComputedRef<string>,
) => {
  const route = useRoute();
  const shopStore = useShopStore();
  const { handleError } = useNotification();

  const isEnableInstantAuth = computed(
    () => (connectedType.value === 'editor' && editorType.value === 'instant') || connectedType.value === 'instant',
  );

  const shopID = computed(() => shopStore.getShopId);

  useShopLoginQuery(
    {
      connectedType: 'gempagesv7',
      key: 'check-gp-v5-use-instant',
    },
    {
      enabled: isEnableInstantAuth,
      onSuccess: (v: ShopLoginResponse) => {
        shopStore.setShopId(v?.shopID);
        shopStore.setToken(v?.token);
        shopStore.setShopDomain(v?.shopifyDomain);
        shopStore.setUserHash(v?.userHash);
        shopStore.setFirstName(v?.firstName);
        shopStore.setIsGemPagesV7(v.isGemPagesV7);
        const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
        shopStore.setStatus(!!isConnected);
        shopStore.setShopType(v.isGemPagesV7);
        shopStore.setShopPlan(v.plan);
        shopStore.setShopifyPlan(v.shopifyPlan);
      },
    },
  );

  const { data: dataShopLogin, refetch } = useShopLoginQuery(
    {
      connectedType: 'instant',
    },
    {
      enabled: isEnableInstantAuth,
      onError: handleError,
      onSuccess: (v) => {
        handleSuccess(v);
      },
    },
  );

  const handleSuccess = (v: ShopLoginResponse) => {
    setShopInfoInStore(v);
  };

  const setShopInfoInStore = (v: ShopLoginResponse) => {
    if (v?.shopID && v?.shopID !== '0') {
      shopStore.setShopId(v?.shopID);
    }
    shopStore.setToken(v?.token);
    shopStore.setShopDomain(v?.shopifyDomain);
    shopStore.setUserHash(v?.userHash);
    shopStore.setFirstName(v?.firstName);
    shopStore.setIsGemPagesV7(v.isGemPagesV7);
    const isConnected = v?.shopID && v?.shopID !== '0' && v?.status == 'CONNECTED';
    shopStore.setStatus(!!isConnected);
    shopStore.setShopType(v.isGemPagesV7);
    shopStore.setShopPlan(v.plan);
    shopStore.setShopifyPlan(v.shopifyPlan);
  };

  useShopQuery({
    shopID,
  });

  watch(
    () => route.name,
    () => {
      if (route.name === 'InstantPages' && dataShopLogin.value) {
        refetch.value();
      }
    },
  );
};
