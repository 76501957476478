export enum AnimationZoomDirectionType {
  In = 'in',
  Out = 'out',
}

export enum AnimationDirectionType {
  Left = 'left',
  Right = 'right',
  Up = 'up',
  Down = 'down',
}

export enum AnimationType {
  Fade = 'fade',
  Slide = 'slide',
  Zoom = 'zoom',
  Shake = 'shake',
  None = 'none',
}

export enum AnimationTriggerType {
  Appear = 'appear',
  Hover = 'hover',
  Hidden = 'hidden',
  AppearByTrigger = 'appearByTrigger',
  Idle = 'idle',
}

export enum AnimationSetting {
  Loop = 'loop',
  Scale = 'scale',
  Delay = 'delay',
  Easing = 'easing',
  Speed = 'speed',
  Distance = 'distance',
  Intensity = 'intensity',
  Direction = 'direction',
  ZoomDirection = 'zoomDirection',
  IsFade = 'isFade',
  IsCustomDistance = 'isCustomDistance',
}

export enum AnimationEasingType {
  Ease = 'ease',
  EaseIn = 'ease-in',
  EaseOut = 'ease-out',
  Linear = 'linear',
}

export type AnimationSupportSetting = Exclude<AnimationType, AnimationType.None>;

export type AnimationSettingWithRangeSlide = Exclude<
  AnimationSetting,
  AnimationSetting.Loop | AnimationSetting.Direction
>;

export type AnimationSettingType = {
  loop: boolean;
  scale: ScaleByDirection;
  delay: string;
  speed: number | string;
  distance?: string;
  intensity: number | string;
  direction: AnimationDirectionType;
  zoomDirection: AnimationZoomDirectionType;
  easing: AnimationEasingType;
  isFade: boolean;
  isCustomDistance: boolean;
};

export type ScaleByDirection = {
  [key in AnimationZoomDirectionType]: [number | string, number | string];
};

export type AnimationShakeSettingType = Pick<
  AnimationSettingType,
  | AnimationSetting.Loop
  | AnimationSetting.Intensity
  | AnimationSetting.Speed
  | AnimationSetting.Delay
  | AnimationSetting.Easing
>;

export type AnimationZoomSettingType = Pick<
  AnimationSettingType,
  | AnimationSetting.Scale
  | AnimationSetting.ZoomDirection
  | AnimationSetting.Speed
  | AnimationSetting.Delay
  | AnimationSetting.Easing
  | AnimationSetting.IsFade
>;

export type AnimationFadeSettingType = Pick<
  AnimationSettingType,
  AnimationSetting.Speed | AnimationSetting.Delay | AnimationSetting.Easing
>;

export type AnimationSlideSettingType = Pick<
  AnimationSettingType,
  | AnimationSetting.Direction
  | AnimationSetting.Distance
  | AnimationSetting.Speed
  | AnimationSetting.Delay
  | AnimationSetting.Easing
  | AnimationSetting.IsCustomDistance
>;

export type SettingByAnimationType = {
  [key in AnimationSupportSetting]: key extends AnimationType.Slide
    ? AnimationSlideSettingType
    : key extends AnimationType.Shake
    ? AnimationShakeSettingType
    : key extends AnimationType.Zoom
    ? AnimationZoomSettingType
    : key extends AnimationType.Fade
    ? AnimationFadeSettingType
    : never;
};

export type SettingByAnimationValues = SettingByAnimationType[keyof SettingByAnimationType];

export type AnimationConfig = {
  enabled: boolean;
  trigger: AnimationTriggerType;
  triggerConfig: Record<AnimationTriggerType, AnimationTriggerConfig>;
};

type AnimationTriggerConfig = {
  animation: AnimationType;
  setting: SettingByAnimationType;
};

export type ChangeSettingParams = {
  animation: AnimationSupportSetting;
  setting: SettingByAnimationValues;
  isPreview?: boolean;
};
