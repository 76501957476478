import { setFeatureFlagOverride } from '@gem/feature-flag';

export const useAdmin = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const isAdmin = urlParams.get('admin_debug') === 'true';
  const adminShopID = urlParams.get('shop_id');
  const adminShopifyDomain = urlParams.get('shop');
  const adminToken = urlParams.get('admin_token');

  const overwriteAdminSetting = () => {
    if (isAdmin) {
      setFeatureFlagOverride({
        'disable-auto-upgrade': true,
        'version-history': true,
        'enabled-layer-tree': true,
        'login-as-shop': true,
        'convert-gempages': true,
      });
    }
  };

  return {
    isAdmin,
    overwriteAdminSetting,
    adminToken,
    adminShopID,
    adminShopifyDomain,
  };
};
