import type { ScreenType } from '@gem/common';
import type { Component } from '../../common/utils/types';
import type { GroupTypeSetting } from '@gem/control';

const SCALE_FACTORS: Record<string, any> = {
  headingSize: {
    tablet: {
      scale: 1,
    },
    mobile: {
      startResponsive: '24px',
      minValue: '24px',
      maxValue: '40px',
      scale: 0.8,
    },
  },
  paragraphSize: {
    tablet: {
      scale: 1,
    },
    mobile: {
      startResponsive: '15px',
      minValue: '15px',
      maxValue: '20px',
      scale: 0.9,
    },
  },
  spacing: {
    left: {
      tablet: {
        startResponsive: '24px',
        minValue: '16px',
        maxValue: '24px',
        scale: 0.3,
      },
      mobile: {
        scale: 1,
      },
    },
    top: {
      tablet: {
        startResponsive: '40px',
        minValue: '16px',
        maxValue: '40px',
        scale: 0.3,
      },
      mobile: {
        scale: 1,
      },
    },
    right: {
      tablet: {
        startResponsive: '24px',
        minValue: '16px',
        maxValue: '24px',
        scale: 0.3,
      },
      mobile: {
        scale: 1,
      },
    },
    bottom: {
      tablet: {
        startResponsive: '40px',
        minValue: '16px',
        maxValue: '40px',
        scale: 0.3,
      },
      mobile: {
        scale: 1,
      },
    },
  },
};

const getValue = (
  val: string,
  option: {
    scale: number | undefined;
    startResponsive?: string;
    minValue?: string;
    maxValue?: string;
  },
) => {
  const { scale, startResponsive, minValue, maxValue } = option;
  const fVal = parseFloat(val);
  const fStartResponsive = parseFloat(startResponsive ?? '0');
  const fMinValue = parseFloat(minValue ?? '0');
  const fMaxValue = parseFloat(maxValue ?? '0');

  if ((startResponsive && fVal <= fStartResponsive) || !scale) {
    return val;
  }
  const newValue = fVal * scale;
  if (minValue && newValue <= fMinValue) {
    return minValue;
  }

  if (maxValue && newValue > fMaxValue) {
    return maxValue;
  }
  return `${newValue.toFixed(2)}px`;
};

const optimizeSpacingData = (data: {
  margin: Record<string, string | number>;
  padding: Record<string, string | number>;
}) => {
  return {
    ...data,
    margin: removeZeroValues(data.margin ?? {}),
    padding: removeZeroValues(data.padding ?? {}),
  };
};

const removeZeroValues = (obj: Record<string, string | number>): Record<string, string | number> => {
  const filteredObj: Record<string, string | number> = {};
  for (const [key, value] of Object.entries(obj)) {
    if (value !== 0 && value !== '0px') {
      filteredObj[key] = value;
    }
  }

  return filteredObj;
};

const getSpacingConfigValue = (config: any, position: string, screenId: ScreenType) => {
  return config?.[position]?.[screenId];
};

export default () => {
  const handleResponsiveSetting = (
    currentComponent: Component,
    setting: {
      controlType: string;
      newValue: any;
      screenId: ScreenType;
      hasDevices?: boolean;
      groupType: GroupTypeSetting;
      controlId: string;
    },
  ) => {
    const { controlId, screenId, newValue, groupType } = setting;
    if (!currentComponent) return;

    let settingType: 'styles' | 'settings' | 'advanced' = 'advanced';
    switch (groupType) {
      case 'style': {
        settingType = 'styles';
        break;
      }
      case 'setting': {
        settingType = 'settings';
        break;
      }
    }

    const value = currentComponent?.[settingType]?.[controlId];
    if (!value) return;

    switch (controlId) {
      case 'spacing-setting': {
        const config = SCALE_FACTORS['spacing'];
        let desktopValue = value.desktop;
        let tabletValue = value.tablet;
        let mobileValue = value.mobile;
        const edited = value.edited ?? [];
        if (!edited?.length) {
          if (tabletValue) {
            edited.push('tablet');
          }
          if (mobileValue) {
            edited.push('mobile');
          }
        }
        if (screenId === 'desktop') {
          desktopValue = newValue;
          const marginValue = newValue.margin;
          const paddingValue = newValue.padding;
          if (!edited.includes('desktop')) {
            edited.push('desktop');
          }
          if (!edited.includes('tablet')) {
            tabletValue = {
              ...tabletValue,
              margin: {
                bottom: getValue(marginValue?.bottom, { ...getSpacingConfigValue(config, 'bottom', 'tablet') }),
                left: getValue(marginValue?.left, { ...getSpacingConfigValue(config, 'left', 'tablet') }),
                right: getValue(marginValue?.right, { ...getSpacingConfigValue(config, 'right', 'tablet') }),
                top: getValue(marginValue?.top, { ...getSpacingConfigValue(config, 'top', 'tablet') }),
              },
              padding: {
                bottom: getValue(paddingValue?.bottom, { ...getSpacingConfigValue(config, 'bottom', 'tablet') }),
                left: getValue(paddingValue?.left, { ...getSpacingConfigValue(config, 'left', 'tablet') }),
                right: getValue(paddingValue?.right, { ...getSpacingConfigValue(config, 'right', 'tablet') }),
                top: getValue(paddingValue?.top, { ...getSpacingConfigValue(config, 'top', 'tablet') }),
              },
            };
            if (!edited.includes('mobile')) {
              mobileValue = {
                ...mobileValue,
                margin: {
                  bottom: getValue(tabletValue.margin?.bottom, {
                    ...getSpacingConfigValue(config, 'bottom', 'mobile'),
                  }),
                  left: getValue(tabletValue.margin?.left, {
                    ...getSpacingConfigValue(config, 'left', 'mobile'),
                  }),
                  right: getValue(tabletValue.margin?.right, {
                    ...getSpacingConfigValue(config, 'right', 'mobile'),
                  }),
                  top: getValue(tabletValue.margin?.top, {
                    ...getSpacingConfigValue(config, 'top', 'mobile'),
                  }),
                },
                padding: {
                  bottom: getValue(tabletValue.padding?.bottom, {
                    ...getSpacingConfigValue(config, 'bottom', 'mobile'),
                  }),
                  left: getValue(tabletValue.padding?.left, {
                    ...getSpacingConfigValue(config, 'left', 'mobile'),
                  }),
                  right: getValue(tabletValue.padding?.right, {
                    ...getSpacingConfigValue(config, 'right', 'mobile'),
                  }),
                  top: getValue(tabletValue.padding?.top, {
                    ...getSpacingConfigValue(config, 'top', 'mobile'),
                  }),
                },
              };
            }
          }
        } else if (screenId === 'tablet') {
          tabletValue = newValue;
          if (!edited.includes('tablet')) {
            edited.push('tablet');
          }
          if (!edited.includes('desktop')) {
            desktopValue = {
              ...desktopValue,
              ...newValue,
            };
          }
          if (!edited.includes('mobile')) {
            mobileValue = {
              ...mobileValue,
              margin: {
                bottom: getValue(tabletValue.margin?.bottom, {
                  ...getSpacingConfigValue(config, 'bottom', 'mobile'),
                }),
                left: getValue(tabletValue.margin?.left, {
                  ...getSpacingConfigValue(config, 'left', 'mobile'),
                }),
                right: getValue(tabletValue.margin?.right, {
                  ...getSpacingConfigValue(config, 'right', 'mobile'),
                }),
                top: getValue(tabletValue.margin?.top, {
                  ...getSpacingConfigValue(config, 'top', 'mobile'),
                }),
              },
              padding: {
                bottom: getValue(tabletValue.padding?.bottom, {
                  ...getSpacingConfigValue(config, 'bottom', 'mobile'),
                }),
                left: getValue(tabletValue.padding?.left, {
                  ...getSpacingConfigValue(config, 'left', 'mobile'),
                }),
                right: getValue(tabletValue.padding?.right, {
                  ...getSpacingConfigValue(config, 'right', 'mobile'),
                }),
                top: getValue(tabletValue.padding?.top, {
                  ...getSpacingConfigValue(config, 'top', 'mobile'),
                }),
              },
            };
          }
        } else {
          mobileValue = newValue;
          if (!edited.includes('mobile')) {
            edited.push('mobile');
          }
          if (!edited.includes('desktop')) {
            desktopValue = {
              ...desktopValue,
              ...newValue,
            };
            if (!edited.includes('tablet')) {
              tabletValue = {
                ...tabletValue,
                ...newValue,
              };
            }
          }
        }
        currentComponent![settingType]![controlId] = {
          edited,
          desktop: optimizeSpacingData(desktopValue),
          tablet: optimizeSpacingData(tabletValue),
          mobile: optimizeSpacingData(mobileValue),
        };
        break;
      }
      case 'typo': {
        if (!value?.custom?.fontSize) return;
        const typeSize = value.type.includes('heading') ? 'headingSize' : 'paragraphSize';
        const config = SCALE_FACTORS[typeSize];
        const tabletConfig = config['tablet'];
        const mobileConfig = config['mobile'];
        const edited = newValue?.custom?.fontSize?.edited ?? [];
        let desktopValue = value.custom?.fontSize?.desktop;
        let tabletValue = value.custom?.fontSize?.tablet;
        let mobileValue = value.custom?.fontSize?.mobile;
        if (screenId === 'desktop') {
          desktopValue = newValue.custom.fontSize.desktop;
          if (!edited.includes('desktop')) {
            edited.push('desktop');
          }
          if (!edited.includes('tablet')) {
            tabletValue = getValue(desktopValue, { ...tabletConfig });
            if (!edited.includes('mobile')) {
              mobileValue = getValue(tabletValue, { ...mobileConfig });
            }
          }
        } else if (screenId === 'tablet') {
          tabletValue = newValue.custom.fontSize.tablet;
          if (!edited.includes('tablet')) {
            edited.push('tablet');
          }
          if (!edited.includes('desktop')) {
            desktopValue = tabletValue;
          }
          if (!edited.includes('mobile')) {
            mobileValue = getValue(tabletValue, { ...mobileConfig });
          }
        } else {
          mobileValue = newValue.custom.fontSize.mobile;
          if (!edited.includes('mobile')) {
            edited.push('mobile');
          }
          if (!edited.includes('desktop')) {
            desktopValue = mobileValue;
            if (!edited.includes('tablet')) {
              tabletValue = mobileValue;
            }
          }
        }
        const newFontSize = {
          ...newValue.custom.fontSize,
          edited,
          desktop: desktopValue,
          tablet: tabletValue,
          mobile: mobileValue,
        };
        currentComponent![settingType]![controlId] = {
          ...value,
          custom: {
            ...value.custom,
            fontSize: newFontSize,
          },
        };
      }
    }
  };

  return {
    handleResponsiveSetting,
  };
};
