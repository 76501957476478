import { postPurchaseRequiredElements, postPurchaseWrapElements } from '../constants/postPurchaseElements';
import useNotification from '@/composables/useNotification';
import { useSaleFunnel } from '../../sale-funnels/hooks/useSaleFunnel';
import { cacheGetComponentTagByComponentUid } from '../use-cases/cache';

export default function useVerifyPostPurchase() {
  const { handleAlert } = useNotification();
  const { isPostPurchasePage } = useSaleFunnel();

  const isUnableDelete = (uid: string): boolean => {
    if (!isPostPurchasePage) return false;

    const tag = cacheGetComponentTagByComponentUid(uid);
    if (!tag) return false;

    if (postPurchaseRequiredElements.includes(tag) && verifyRequireElement(tag)) {
      const message = `This element is required and can’t be deleted.`;
      handleAlert('error', message, 2000);
      return true;
    }

    if (
      postPurchaseWrapElements.includes(tag) &&
      countRequiredElement(uid) > 0 &&
      verifyWrapContainsRequireElement(uid)
    ) {
      const elmName = tag === 'Section' ? 'section' : 'element';
      const message = `This ${elmName} contains required elements and cannot be deleted`;
      handleAlert('error', message, 2000);
      return true;
    }

    return false;
  };

  return { isUnableDelete };
}

const iframeSelector = '.iframe';

const getIframeDocument = (): Document | null => {
  const $iframe = document.body.querySelector<HTMLIFrameElement>(iframeSelector);
  return $iframe ? $iframe.contentDocument || ($iframe.contentWindow as any)?.document : null;
};

const countRequiredElement = (uid: string): number => {
  const $iframeDoc = getIframeDocument();
  if (!$iframeDoc) return 0;

  return postPurchaseRequiredElements.reduce((acc, requiredElementTag) => {
    const $component = $iframeDoc.querySelector(`[data-uid="${uid}"]`);
    if (!$component) return acc;
    const count = $component.querySelectorAll(`[data-component-tag="${requiredElementTag}"]`).length;
    return acc + count;
  }, 0);
};

const verifyRequireElement = (tag: string): boolean => {
  const $iframeDoc = getIframeDocument();
  if (!$iframeDoc) return false;

  const count = $iframeDoc.querySelectorAll(`[data-component-tag="${tag}"]`).length || 0;
  return count <= 1;
};

const verifyWrapContainsRequireElement = (uid: string): boolean => {
  const $iframeDoc = getIframeDocument();
  if (!$iframeDoc) return false;

  const $component = $iframeDoc.querySelector(`[data-uid="${uid}"]`);
  if (!$component) return false;

  for (const requiredElementTag of postPurchaseRequiredElements) {
    const countElementInsidePage = $iframeDoc.querySelectorAll(`[data-component-tag="${requiredElementTag}"]`).length;
    const countElementInsideWrap = $component.querySelectorAll(`[data-component-tag="${requiredElementTag}"]`).length;
    if (countElementInsideWrap > 0 && countElementInsidePage - countElementInsideWrap < 1) {
      return true;
    }
  }
  return false;
};
