import type { Maybe } from '@gem/common';
import { convertComponentToJSON, getComponentByUid } from '../utils/section/component';
import type { Component } from '../utils/types';

export const useUpdateProductBadge = () => {
  const updateSettingProductBadge = (
    component: Component,
    toComponentUid: string,
    direction: string,
    newSectionComponent: Maybe<string>,
  ): 'inside' | 'outside' | null => {
    if (component.tag === 'ProductBadge' && component.settings) {
      component.settings.positionWithImage = getPositionBadgeWithImage(
        toComponentUid,
        direction || '',
        newSectionComponent,
      );
      return component.settings.positionWithImage;
    }

    return null;
  };

  const getPositionBadgeWithImage = (
    toComponentUid: string,
    direction: string,
    newComponentTo?: string,
  ): 'inside' | 'outside' => {
    const jsonComponent = convertComponentToJSON(newComponentTo || '');

    let component;
    if (newComponentTo && jsonComponent) {
      component = getComponentByUid(jsonComponent, toComponentUid);
    }
    return component?.tag === 'ProductImagesV2' && direction === 'children' ? 'inside' : 'outside';
  };

  return {
    updateSettingProductBadge,
  };
};
