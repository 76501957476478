import { defineStore } from 'pinia';

export type State = {
  reason: null | 'LOST_NETWORK' | 'SESSION_EXPIRED' | 'SAVE_FAIL' | 'PAGE_CONTENT_OUTDATE';
};

export const usePageBackupStore = defineStore('pageBackup', {
  state: (): State => ({
    reason: null,
  }),
  getters: {
    getReason(state) {
      return state.reason;
    },
  },
  actions: {
    setReason(data: State['reason']) {
      this.reason = data;
    },
  },
});

export default usePageBackupStore;
