import type { ThemePageType } from '@/types/graphql';
import { defineStore } from 'pinia';

export type State = {
  pageType: ThemePageType;
  searchKeyword: string;
  isSwitchingPage: boolean;
  isSwitchingDifferentPageType: boolean;
};

export const useSwitchPage = defineStore('switchPage', {
  state: (): State => ({
    pageType: 'GP_INDEX',
    searchKeyword: '',
    isSwitchingPage: false,
    isSwitchingDifferentPageType: false,
  }),
  getters: {
    getPageType(state) {
      return state.pageType;
    },
    getSearchKeyword(state) {
      return state.searchKeyword;
    },
    getIsSwitchingPage(state) {
      return state.isSwitchingPage;
    },
    getIsSwitchingDifferentPageType(state) {
      return state.isSwitchingDifferentPageType;
    },
  },
  actions: {
    setPageType(val: ThemePageType) {
      this.pageType = val;
    },
    setSearchKeyword(val: string) {
      this.searchKeyword = val;
    },
    setIsSwitchingPage(val: boolean) {
      this.isSwitchingPage = val;
    },
    setIsSwitchingDifferentPageType(val: boolean) {
      this.isSwitchingDifferentPageType = val;
    },
  },
});

export default useSwitchPage;
