import type { Component, ControlLink, PseudoStateSetting } from '@/modules/editor/modules/common/utils/types';
import { defaultSupportDevices } from '@/modules/editor/modules/common/utils/const';
import type { Control } from '@/modules/editor/modules/common/utils/types';
import type { GroupTypeSetting } from '../types';
import { cloneDeepObject, isObject } from '@/utils/common';
import type { ScreenType } from '@/types/custom';

// const virtualControls = ['behavior-state', 'group'];
const virtualControls = [''];

export function mapVirtualControls(controls: Control[], map: Record<string, Control> = {}): Record<string, Control> {
  controls.forEach((control) => {
    if (control.id && virtualControls.includes(control.type)) {
      map[control.id] = control;
    }
    if (control.controls?.length) {
      map = mapVirtualControls(control.controls, map);
    }
  });
  return map;
}

export function createMapIdControls(controls: Control[], map: Record<string, Control> = {}): Record<string, Control> {
  controls.forEach((control) => {
    if (control.id && !virtualControls.includes(control.type)) {
      map[control.id] = control;
    }
    if (control.controls?.length) {
      map = createMapIdControls(control.controls, map);
    }
  });
  return map;
}

export function mapComponentSettingsToControls(
  component: Component,
  sourceControls: Control[],
  group: GroupTypeSetting,
) {
  const controls = cloneDeepObject(sourceControls);
  const componentStyle = component.styles || {}; // same props react
  const componentSettings = component.settings || {}; // same props react
  const componentAdvanced = component.advanced || {}; // same props react
  const setting = cloneDeepObject(componentSettings);
  const advanced = cloneDeepObject(componentAdvanced);
  const style = cloneDeepObject(componentStyle);

  const mapControls = createMapIdControls(controls);
  const data: Record<GroupTypeSetting, Record<string, any>> = {
    advanced,
    setting,
    style,
  };

  // const hasValueOnDevices = (control: Control, newValue: any) => {
  //   return (
  //     isObject(control.devices) &&
  //     isObject(newValue) &&
  //     (Object.hasOwnProperty.call(newValue, defaultSupportDevices[0]) ||
  //       Object.hasOwnProperty.call(newValue, defaultSupportDevices[1]) ||
  //       Object.hasOwnProperty.call(newValue, defaultSupportDevices[2]))
  //   );
  // };

  const newSettings = data[group];
  Object.entries(mapControls).forEach(([prop, control]) => {
    const propValue = newSettings[prop];
    if (propValue !== undefined && propValue !== null) {
      if (isObject(control.devices) && control.devices) {
        // Control support devices and setting not support devices
        // const devices = control.devices; // { desktop: any, mobile: any }
        defaultSupportDevices.forEach((deviceId) => {
          control.devices = {
            ...control.devices,
            [deviceId]: {
              default: propValue?.[deviceId],
            },
          };
        });
      } else if (isObject(propValue) && propValue.hasOwnProperty.call(propValue, defaultSupportDevices[0])) {
        // Controls not support device and setting support device
        control.default = propValue[defaultSupportDevices[0]];
      } else {
        // Controls not support device and setting not support device
        control.default = propValue;
      }
    }
  });

  return controls;
}

export function mapSettingsToControls(component: Component, mapControls: Record<string, Control>) {
  // Get old data
  const componentStyle = component.styles || {}; // same props react
  const componentSettings = component.settings || {}; // same props react
  const componentAdvanced = component.advanced || {}; // same props react
  const setting = cloneDeepObject(componentSettings);
  const advanced = cloneDeepObject(componentAdvanced);

  migrateSpacingControl(advanced);
  const style = cloneDeepObject(componentStyle);
  const newSettings: Record<string, any> = {
    ...style,
    ...setting,
    ...advanced,
  };

  Object.entries(mapControls).forEach(([prop, control]) => {
    const propValue = newSettings[prop];
    if (propValue !== undefined && propValue !== null) {
      if (isObject(control.devices) && control.devices) {
        // Control support devices and setting not support devices
        // const devices = control.devices; // { desktop: any, mobile: any }
        defaultSupportDevices.forEach((deviceId) => {
          control.devices = {
            ...control.devices,
            [deviceId]: {
              default: propValue?.[deviceId],
            },
          };
        });
      } else if (isObject(propValue) && propValue.hasOwnProperty.call(propValue, defaultSupportDevices[0])) {
        // Controls not support device and setting support device
        control.default = propValue[defaultSupportDevices[0]];
      } else {
        // Controls not support device and setting not support device
        control.default = propValue;
      }
    } else {
      control.default = getDefaultValueControl(control.id) ?? control.default;
    }
  });

  return mapControls;
}

function migrateSpacingControl(advanced: Record<string, any>) {
  if (!advanced['spacing-setting']) {
    advanced['spacing-setting'] = {
      desktop: {
        margin: {
          ...advanced?.margin?.desktop,
        },
        padding: {
          ...advanced?.padding?.desktop,
        },
      },
    };

    if (advanced?.margin?.tablet) {
      Object.assign(advanced?.['spacing-setting']?.tablet, {
        margin: {
          ...advanced?.margin?.tablet,
        },
      });
    }

    if (advanced?.padding?.tablet) {
      Object.assign(advanced?.['spacing-setting']?.tablet, {
        padding: {
          ...advanced?.padding?.tablet,
        },
      });
    }

    if (advanced?.padding?.mobile) {
      Object.assign(advanced['spacing-setting'].mobile, {
        padding: {
          ...advanced?.padding?.mobile,
        },
      });
    }

    if (advanced?.margin?.mobile) {
      Object.assign(advanced?.['spacing-setting']?.mobile, {
        margin: {
          ...advanced?.margin?.mobile,
        },
      });
    }
  } else {
    const spacing = advanced['spacing-setting'];
    for (const device in spacing) {
      if (spacing[device].margin === '' && Object.values(spacing[device].padding).every((val) => val === '')) {
        delete spacing[device];
      }
    }
  }
}

export function updateControlLinks(
  sourceControls: Control[],
  screenId: ScreenType,
  sourceDependentControls?: Control[],
) {
  // Clone control
  const controls = cloneDeepObject(sourceControls);

  // Merge control and dependent
  let controlsAndDependent = controls;
  if (sourceDependentControls) {
    const dependentControls = cloneDeepObject(sourceDependentControls);
    controlsAndDependent = controls.concat(dependentControls);
  }

  // Create map controls and dependent
  const mapControls = createMapIdControls(controlsAndDependent);

  // Find control links
  const mapLinksWithControlId: Record<
    string,
    Array<{ result?: boolean; parentId?: string; stateResult?: Record<string, boolean>; link: ControlLink }>
  > = {};
  Object.entries(mapControls).forEach(([, control]) => {
    if (control.links?.length) {
      // Loop link parent
      control.links?.forEach((link) => {
        const value = link.value;
        link?.control?.ids?.forEach((id) => {
          mapLinksWithControlId[id] = mapLinksWithControlId[id] || [];
          if (control.state && isObject(control.state)) {
            // control.state
            const state = control.state; // normal, hover, focus, active...
            const result: Record<string, boolean> = {};
            for (const key in state) {
              if (Object.prototype.hasOwnProperty.call(state, key)) {
                result[key] = control.default[key] == value;
              }
            }
            mapLinksWithControlId[id].push({
              parentId: control.id,
              stateResult: result,
              link: link,
            });
          } else if (control.devices && isObject(control.devices)) {
            // control.devices
            const devices = control.devices;

            // Merge default control by screen
            const indexScreen = defaultSupportDevices.findIndex((v) => v === screenId);
            const composeDeviceValue = defaultSupportDevices.slice(0, indexScreen + 1).reduce((curr, deviceId) => {
              const controlDevice = devices?.[deviceId];
              return {
                ...curr,
                ...controlDevice,
              };
            }, {} as any);
            if (composeDeviceValue.default !== undefined && composeDeviceValue.default !== null) {
              mapLinksWithControlId[id].push({
                parentId: control.id,
                result: value == composeDeviceValue.default,
                link: link,
              });
            }
          } else {
            mapLinksWithControlId[id].push({
              parentId: control.id,
              result: value == control.default,
              link: link,
            });
          }
        });
      });
    }
  });

  // Check link
  Object.entries(mapLinksWithControlId).forEach(([controlId, arrayData]) => {
    const control = mapControls[controlId];
    if (control?.id && arrayData?.length) {
      const controlOptions: {
        hide?: null | boolean;
        hideOnState?: null | Record<string, boolean>;
      } = {
        hide: null,
        hideOnState: null,
      };
      arrayData.forEach(({ result, stateResult, link, parentId }) => {
        let parentHidden: Record<string, boolean> = {};
        if (parentId) {
          const parentControl = mapControls[parentId];
          if (parentControl.hide !== undefined && parentControl.hide) {
            parentHidden.default = true;
          } else if (parentControl.show !== undefined && !parentControl.show) {
            parentHidden.default = true;
          }

          if (parentControl.hideOnState !== undefined && parentControl.hideOnState) {
            parentHidden = { ...parentHidden, ...parentControl.hideOnState };
          }
        }

        // If any link makes the control 'show', it cannot be 'hide'
        if (link.control.show) {
          if (result !== undefined) {
            if (parentHidden.default) {
              controlOptions['hide'] = true;
            } else if (controlOptions['hide'] == null || controlOptions['hide'] == true) {
              if (result) {
                controlOptions['hide'] = false;
              } else {
                controlOptions['hide'] = true;
              }
            }
          }
          if (stateResult !== undefined) {
            for (const key in stateResult) {
              if (parentHidden[key]) {
                controlOptions['hideOnState'] = controlOptions['hideOnState'] || {};
                controlOptions['hideOnState'][key] = true;
              } else if (Object.prototype.hasOwnProperty.call(stateResult, key)) {
                const result = stateResult[key];
                controlOptions['hideOnState'] = controlOptions['hideOnState'] || {};
                if (result) {
                  controlOptions['hideOnState'][key] = false;
                } else {
                  controlOptions['hideOnState'][key] = true;
                }
              }
            }
          }
        }

        // Support hide control
        if (link.control.hide) {
          // If any link makes the control 'show', it cannot be 'hide'
          if (result !== undefined) {
            if (parentHidden.default) {
              controlOptions['hide'] = true;
            } else if (controlOptions['hide'] == null || controlOptions['hide'] == true) {
              if (result) {
                controlOptions['hide'] = true;
              } else {
                controlOptions['hide'] = false;
              }
            }
          }
          if (stateResult !== undefined) {
            for (const key in stateResult) {
              if (parentHidden[key]) {
                controlOptions['hideOnState'] = controlOptions['hideOnState'] || {};
                controlOptions['hideOnState'][key] = true;
              } else if (Object.prototype.hasOwnProperty.call(stateResult, key)) {
                const result = stateResult[key];
                controlOptions['hideOnState'] = controlOptions['hideOnState'] || {};
                if (result) {
                  controlOptions['hideOnState'][key] = true;
                } else {
                  controlOptions['hideOnState'][key] = false;
                }
              }
            }
          }
        }
      });
      if (controlOptions.hide !== null) {
        control.hide = controlOptions.hide;
      }
      if (controlOptions.hideOnState !== null) {
        control.hideOnState = controlOptions.hideOnState;
      }
    }
  });

  // Return
  return controls;
}

export function getCurrentValueFromControl({
  control,
  screenId,
}: {
  control: Control;
  state?: PseudoStateSetting;
  screenId: ScreenType;
}) {
  let valueDefault = null;
  // Update devices undefine to default
  if (control.devices && isObject(control.devices)) {
    // Merge default control by screen
    const indexScreen = defaultSupportDevices.findIndex((e) => e === screenId);
    const composeDeviceValue = defaultSupportDevices.slice(0, indexScreen + 1).reduce((curr, deviceId) => {
      const controlDevice = control.devices?.[deviceId];
      return {
        ...curr,
        ...(controlDevice !== undefined && controlDevice.default !== undefined && { ...controlDevice }),
      };
    }, {} as any);
    valueDefault = composeDeviceValue?.default;
  } else {
    valueDefault = control.default;
  }

  return valueDefault;
}

function getDefaultValueControl(id?: string) {
  switch (id) {
    case 'isSyncProduct':
      return false;
    case 'feraVersion':
      return 'V2';
    case 'wiserVersion':
      return 'V1';
    default:
      return;
  }
}
