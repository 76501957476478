import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { useMutation, type UseMutationOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ShopMetaCreateOrUpdateMutationVariables = Types.Exact<{
  input: Types.UpsertShopMetaInput;
}>;

export type ShopMetaCreateOrUpdateMutationResponse = {
  shopMetaCreateOrUpdate?: Types.Maybe<Pick<Types.ShopMeta, 'createdAt' | 'id' | 'key' | 'updatedAt' | 'value'>>;
};

export const ShopMetaCreateOrUpdateDocument = `
    mutation shopMetaCreateOrUpdate($input: UpsertShopMetaInput!) {
  shopMetaCreateOrUpdate(input: $input) {
    createdAt
    id
    key
    updatedAt
    value
  }
}
    `;
export const useShopMetaCreateOrUpdateMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<
    ShopMetaCreateOrUpdateMutationResponse,
    TError,
    ShopMetaCreateOrUpdateMutationVariables,
    TContext
  >,
) =>
  useMutation<ShopMetaCreateOrUpdateMutationResponse, TError, ShopMetaCreateOrUpdateMutationVariables, TContext>(
    ['shopMetaCreateOrUpdate'],
    (
      variables:
        | ShopMetaCreateOrUpdateMutationVariables
        | ComputedRef<ShopMetaCreateOrUpdateMutationVariables>
        | Ref<ShopMetaCreateOrUpdateMutationVariables>,
    ) =>
      appFetcher<ShopMetaCreateOrUpdateMutationResponse, ShopMetaCreateOrUpdateMutationVariables>(
        ShopMetaCreateOrUpdateDocument,
        variables,
      )(),
    options,
  );
useShopMetaCreateOrUpdateMutation.getKey = () => ['shopMetaCreateOrUpdate'];
