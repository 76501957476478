import type * as Types from '@/types/graphql';

export type ShopMetaSelectFragment = Pick<Types.ShopMeta, 'id' | 'value' | 'key' | 'createdAt' | 'updatedAt'>;

export const ShopMetaSelectFragmentDoc = `
    fragment ShopMetaSelect on ShopMeta {
  id
  value
  key
  createdAt
  updatedAt
}
    `;
