import { defineStore } from 'pinia';

type Mode =
  | 'global-style'
  | 'builder'
  | 'theme-change'
  | 'theme-section'
  | 'seo-checker'
  | 'search'
  | 'shopify-apps'
  | 'ab-testing'
  | 'interaction'
  | undefined;

type State = {
  mode: Mode;
  elementGroupHistory: 'builder' | 'shopify-apps' | 'sections' | undefined;
  data: Record<string, boolean | undefined>;
  title: string | undefined;
};

const ELEMENTS_GROUP: (string | undefined)[] = ['builder', 'shopify-apps', 'sections'];
export const useSidebarStore = defineStore('sidebar', {
  state: (): State => ({
    mode: 'builder',
    title: 'Start with',
    elementGroupHistory: undefined,
    data: {},
  }),
  getters: {
    getMode(state) {
      return state.mode;
    },
    getElementGroupHistory(state) {
      return state.elementGroupHistory;
    },
    groupState: (state) => {
      return (key: string) => {
        if (state.data[key] === undefined) {
          return true;
        }
        return state.data[key];
      };
    },
    getTitle(state) {
      return state.title;
    },
  },
  actions: {
    setMode(mode?: State['mode']) {
      this.mode = mode;
      if (!ELEMENTS_GROUP?.includes(mode)) return;
      this.setElementGroupHistory(mode as State['elementGroupHistory']);
    },
    setElementGroupHistory(mode?: State['elementGroupHistory']) {
      this.elementGroupHistory = mode;
    },
    setTitle(title: string | undefined) {
      this.title = title;
    },
    setGroupState(key: string, state?: boolean) {
      this.data[key] = state;
    },
  },
});
