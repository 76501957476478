import { useFetchThemePage } from '../services/useFetchThemePage';
import useEditorStore from '../stores/editor';

export const usePageInformation = () => {
  const { data: themePage } = useFetchThemePage();
  const editorStore = useEditorStore();

  const isInstant = computed(() => {
    return !!(
      themePage.value?.themePage?.type &&
      !themePage.value.themePage.type.startsWith('GP_') &&
      !themePage.value.themePage.type.startsWith('POST_PURCHASE')
    );
  });

  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);

  const pageType = computed(() => (isEditThemeSection.value ? 'THEME_SECTION' : themePage.value?.themePage?.type));

  const isPublished = computed(() => {
    return themePage.value?.themePage?.status === 'PUBLISHED';
  });

  const didPublish = computed(() => {
    return !!themePage.value?.themePage?.publishedAt;
  });

  const pageName = computed(() => {
    return themePage.value?.themePage?.name ?? '';
  });

  const isDefaultTemplate = computed(() => {
    const { isPlatformDefault, default: isDefault, publishedAt } = themePage.value?.themePage ?? {};

    return isPlatformDefault ? isDefault : isDefault && !!publishedAt;
  });

  const isOriginTemplate = computed(() => {
    return !!themePage.value?.themePage?.isPlatformDefault;
  });

  return {
    isInstant,
    pageType,
    isPublished,
    didPublish,
    pageName,
    isDefaultTemplate,
    isOriginTemplate,
  };
};
