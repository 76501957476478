import type { InjectionKey } from 'vue';
import type { DeviceValue, ScreenType } from '@/types/custom';

import { inject } from 'vue';

import { defaultSupportDevices } from '@/modules/editor/modules/common/utils/const';

export function isDefined<T>(argument: T | undefined): argument is T {
  return argument !== undefined;
}
export function isObjectEqual(obj1: any, obj2: any): boolean {
  // check if the objects are the same object
  if (obj1 === obj2) return true;

  // check if the objects are null or not objects
  if (obj1 == null || typeof obj1 != 'object' || obj2 == null || typeof obj2 != 'object') return false;

  // compare the number of properties in the objects
  const keysA = Object.keys(obj1),
    keysB = Object.keys(obj2);
  if (keysA.length != keysB.length) return false;

  // compare the properties and values of the objects
  for (const key of keysA) {
    if (!keysB.includes(key) || !isObjectEqual(obj1[key], obj2[key])) return false;
  }

  // if all of the properties and values are the same, the objects are equal
  return true;
}
export const isObject = (obj: any) => {
  if (typeof obj === 'object' && !Array.isArray(obj) && obj !== null) {
    return true;
  }
  return false;
};

export const cloneDeepObject = <T = unknown, R = T>(input: T) => {
  return JSON.parse(JSON.stringify(input)) as R;
};

export const parseJson = <R = unknown>(input: string) => {
  return JSON.parse(input) as R;
};

export function deepMergeObject(...objects: (Record<string, any> | undefined)[]) {
  const isObject = (obj: any) => obj && typeof obj === 'object';

  return objects.reduce((prev = {}, obj) => {
    if (!obj) return prev;
    Object.keys(obj).forEach((key) => {
      const pVal = prev[key];
      const oVal = obj[key];
      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = oVal;
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = deepMergeObject(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}

export function getValueByDevice<T>(
  value: DeviceValue<T> | undefined,
  device: ScreenType,
  defaultValue?: T,
): T | undefined {
  if (!value) return defaultValue;
  const indexScreen = defaultSupportDevices.findIndex((e) => e === device);
  const devices = defaultSupportDevices.slice(0, indexScreen + 1);
  if (typeof value['desktop'] === 'object') {
    const composeDeviceValue = devices.reduce((curr, deviceId) => {
      const controlDevice = value?.[deviceId];
      return {
        ...curr,
        ...controlDevice,
      };
    }, {} as T);
    return composeDeviceValue;
  }
  const selected = devices.reverse().find((dv) => value[dv] !== undefined);
  if (selected) return value[selected];
  return defaultValue;
}

export const strictInject = <T>(key: InjectionKey<T>, defaultValue?: T) => {
  const resolved = inject(key, defaultValue);
  if (!resolved) {
    throw new Error(`Could not resolve ${key}`);
  }

  return resolved;
};
