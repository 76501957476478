import { cloneDeepObject } from '@/utils/common';
import { sentryCaptureException } from '../../common/use-cases/sentry';

const useEmitToFrame = () => {
  const refIframe = ref(document?.querySelector<HTMLIFrameElement>('.iframe'));
  const awaitSelectorKeys = ref<Record<string, { count: number }>>({});

  const waitForElement = (selector?: string, cb?: (element: any) => void, cbf?: () => void) => {
    if (!selector) return;

    // Check count loop
    awaitSelectorKeys.value[selector] = awaitSelectorKeys.value[selector] ?? {
      count: 0,
    };
    awaitSelectorKeys.value[selector].count = awaitSelectorKeys.value[selector].count + 1;
    if (awaitSelectorKeys.value[selector].count == 120) {
      // 1m
      console.error('waitForElement', 'Infinite loop error', selector);
      sentryCaptureException('waitForElement', `Infinite loop error (${selector})`, { selector }, { level: 'error' });
      return;
    }
    // End check count loop

    if (!refIframe.value) {
      cbf?.();
      setTimeout(() => waitForElement(selector, cb, cbf), 500);
      return;
    }

    const $iframeDoc = refIframe.value.contentDocument || (refIframe.value.contentWindow as any).document;
    const element = $iframeDoc?.querySelector(selector);
    if (!element) {
      cbf?.();
      setTimeout(() => waitForElement(selector, cb, cbf), 500);
      return;
    }

    delete awaitSelectorKeys.value[selector];
    cb?.(element);
  };

  const waitForElements = (selector?: string, cb?: (element: any) => void, cbf?: () => void) => {
    if (!selector) return;

    // Check count loop
    awaitSelectorKeys.value[selector] = awaitSelectorKeys.value[selector] ?? {
      count: 0,
    };
    awaitSelectorKeys.value[selector].count = awaitSelectorKeys.value[selector].count + 1;
    if (awaitSelectorKeys.value[selector].count == 120) {
      // 1m
      console.error('waitForElements', 'Infinite loop error', selector);
      sentryCaptureException('waitForElements', `Infinite loop error (${selector})`, { selector }, { level: 'error' });
      return;
    }
    // End check count loop

    if (!refIframe.value) {
      cbf?.();
      setTimeout(() => waitForElements(selector, cb, cbf), 500);
      return;
    }

    const $iframeDoc = refIframe.value.contentDocument || (refIframe.value.contentWindow as any).document;
    const elements = $iframeDoc?.querySelectorAll(selector);
    if (elements?.length === 0) {
      cbf?.();
      setTimeout(() => waitForElements(selector, cb, cbf), 500);
      return;
    }

    delete awaitSelectorKeys.value[selector];
    cb?.(elements);
  };

  const emitEventToToolbox = (eventName: string, data: any, callback?: () => void) => {
    const event = new CustomEvent(eventName, {
      bubbles: true,
      detail: typeof data === 'object' ? cloneDeepObject(data) : data,
    });

    waitForElement(
      '.builder',
      () => {
        const winContent = refIframe.value?.contentWindow;
        winContent?.dispatchEvent(event);
        if (callback) {
          callback();
        }
      },
      () => {
        refIframe.value = document?.querySelector<HTMLIFrameElement>('.iframe');
      },
    );
  };

  const documentSelector = (selector: string, callback?: (target: Window | null | undefined | HTMLElement) => void) => {
    waitForElement(
      selector,
      (target) => {
        if (callback) {
          callback(target);
        }
      },
      () => {
        refIframe.value = document?.querySelector<HTMLIFrameElement>('.iframe');
      },
    );
  };

  const documentSelectorAll = (selector: string, callback?: (target: HTMLElement[]) => void) => {
    waitForElements(
      selector,
      (target) => {
        if (callback) {
          callback(target);
        }
      },
      () => {
        refIframe.value = document?.querySelector<HTMLIFrameElement>('.iframe');
      },
    );
  };

  return {
    emitEventToToolbox,
    documentSelector,
    documentSelectorAll,
  };
};

export { useEmitToFrame as default };
