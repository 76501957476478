import { MODAL_TAG, STICKY_TAG } from '../../common/utils/const';
import { convertComponentToJSON } from '../../common/utils/section/component';
import type { Section } from '../../common/utils/types';
import { useScrollToSection } from './useScrollToSection';

const IGNORED_TAGS = [STICKY_TAG, MODAL_TAG];

export const useScrollToFirstSectionInTemplate = () => {
  const { scrollNewSectionIntoView } = useScrollToSection();

  const scrollFirstSectionIntoView = (listSections: Section[], setComponentActive?: (uid: string) => void) => {
    const firstSection = listSections.find((section) => {
      const component = convertComponentToJSON(section?.component || '{}');
      return component && !IGNORED_TAGS.includes(component?.tag || '');
    });

    if (!firstSection) return;

    const component = convertComponentToJSON(firstSection?.component || '{}');
    if (!component) return;

    scrollNewSectionIntoView(component.uid);
    setComponentActive?.(component.uid);
  };

  return { scrollFirstSectionIntoView };
};
