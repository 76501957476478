import { waitForElement } from '../../preview/utils/emitToIframe';

export const useScrollToSection = () => {
  const scrollNewSectionIntoView = (componentUid: string, cb?: ($component: any) => void) => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>('.iframe');

    if (!$iframe) return;

    const $iframeDoc = $iframe.contentDocument || ($iframe.contentWindow as any).document;

    const selector = `[data-uid="${componentUid}"]`;

    waitForElement($iframeDoc, selector, () => {
      const $component = $iframeDoc?.querySelector(selector);
      if ($component) {
        $component.scrollIntoView({ behavior: 'smooth', block: 'start' });
        setTimeout(() => {
          cb?.($component);
        }, 500);
      }
    });
  };

  return { scrollNewSectionIntoView };
};
