import useEditorStore from '../../common/stores/editor';
import { convertComponentToJSON, convertComponentToString } from '../../common/utils/section/component';
import type { Component, Section } from '../../common/utils/types';
import useHistoryStore from '../../history/stores/history';
import { useSalesFunnelStore } from '../stores';
import { useSaleFunnel } from './useSaleFunnel';

export const useSalePageAssignProduct = () => {
  const saleFunnelStore = useSalesFunnelStore();
  const { isSalePage } = useSaleFunnel();
  const { setCid } = useHistoryStore();
  const editorStore = useEditorStore();
  const salesPageProductIdAssignment = computed(() => saleFunnelStore.salesPageProductIdAssignment);
  const productAssignment = computed(() => saleFunnelStore.productAssignment);

  const replaceProductIdForComponent = (jsonComponent?: Component, section?: Section) => {
    if (jsonComponent?.tag == 'Product' && jsonComponent?.settings?.productSetting && isSalePage.value) {
      if (
        jsonComponent.settings.productSetting.productId === salesPageProductIdAssignment.value &&
        jsonComponent.settings.productSetting.productHandle === productAssignment?.value?.handle
      )
        return jsonComponent;
      setCid([section?.cid || '']);
      editorStore.setAutoSaveEnable(true);
      jsonComponent.settings.productSetting.productId = salesPageProductIdAssignment.value || 'Latest';
      jsonComponent.settings.productSetting.productHandle = productAssignment?.value?.handle || '';
      jsonComponent.settings.productSetting.productStatus = 'static';
      jsonComponent.settings.productSetting.initialVariantId = 'Latest';
      return jsonComponent;
    }
    if (jsonComponent?.childrens?.length) {
      for (let i = 0; i < jsonComponent.childrens.length; i++) {
        const children = jsonComponent.childrens[i];
        replaceProductIdForComponent(children, section);
      }
    }
    return jsonComponent;
  };

  const replaceProductIdForSection = (section: Section) => {
    const component = convertComponentToJSON(section?.component || '');
    if (!component) return section;
    const newComponent = replaceProductIdForComponent(component, section) as Component;
    section.component = convertComponentToString(newComponent);
    return section;
  };

  const replaceProductIdAssignmentForSections = (sections: Section[]) => {
    return sections.map((section) => replaceProductIdForSection(section));
  };

  return {
    replaceProductIdForSection,
    replaceProductIdAssignmentForSections,
  };
};
