export enum PlanKey {
  NULL = '',
  TRIAL = 'trial',
  BUILD = 'build',
  TRIAL_2022 = 'trial2022',
  STARTER = 'starter',
  OPTIMIZE = 'optimize',
  ADVANCED = 'advanced',
  PROFESSIONAL = 'professional',
  ENTERPRISE = 'enterprise',
  DEVELOPMENT = 'development',
  DEVELOPMENT_2022 = 'development2022',
  DEVELOPMENT_STORE = 'development_store',
}

export const PLAN_NAMES = {
  FREE: 'Free',
  BUILD: 'Build',
  OPTIMIZE: 'Optimize',
  ENTERPRISE: 'Enterprise',
  DEVELOPMENT: 'Development',
  STARTER: 'Starter',
};

export const PLAN_NAME_MAP = {
  trial: PLAN_NAMES.FREE,
  build: PLAN_NAMES.BUILD,
  trial2022: PLAN_NAMES.FREE,
  starter: PLAN_NAMES.STARTER,
  optimize: PLAN_NAMES.OPTIMIZE,
  advanced: PLAN_NAMES.OPTIMIZE,
  professional: PLAN_NAMES.BUILD,
  enterprise: PLAN_NAMES.ENTERPRISE,
  development: PLAN_NAMES.DEVELOPMENT,
  development2022: PLAN_NAMES.DEVELOPMENT,
  development_store: PLAN_NAMES.DEVELOPMENT,
};
