export const LANDING_PAGE = 'GP_STATIC';
export const PRODUCT_PAGE = 'GP_PRODUCT';
export const COLLECTION_PAGE = 'GP_COLLECTION';
export const HOME_PAGE = 'GP_INDEX';
export const ARTICLE_PAGE = 'GP_ARTICLE';
export const STATIC_PAGE = 'STATIC';
export const POST_PURCHASE_PAGE = 'POST_PURCHASE';
export const FUNNEL_PAGE = 'GP_FUNNEL_PAGE';

export const LANDING_PAGE_NAME = 'Landing page';
export const HOME_PAGE_NAME = 'Home page';
export const PRODUCT_PAGE_NAME = 'Product page';
export const COLLECTION_PAGE_NAME = 'Collection page';
export const BLOG_POST_NAME = 'Blog post';
export const INSTANT_LANDING_PAGE_NAME = 'Instant landing page';

export const DEFAULT_LIST_GP_PAGE_TYPE = [
  { index: 0, display: 'All', type: '', name: '' },
  { index: 1, display: 'Landing', type: LANDING_PAGE, name: LANDING_PAGE_NAME },
  { index: 2, display: 'Home', type: HOME_PAGE, name: HOME_PAGE_NAME },
  { index: 3, display: 'Product', type: PRODUCT_PAGE, name: PRODUCT_PAGE_NAME },
  { index: 4, display: 'Collection', type: COLLECTION_PAGE, name: COLLECTION_PAGE_NAME },
  { index: 5, display: 'Blog post', type: ARTICLE_PAGE, name: BLOG_POST_NAME },
  { index: 6, display: 'Instant landing page', type: STATIC_PAGE, name: INSTANT_LANDING_PAGE_NAME },
];
