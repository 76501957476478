import type { ThemePageQueryResponse } from '@/api/app/queries/theme-page.generated';
import { useThemePageQuery } from '@/api/app/queries/theme-page.generated';
import useNotification from '@/composables/useNotification';
import type { UseQueryOptions } from 'vue-query';
import useEditorStore from '../stores/editor';
import useAssignStore from '@/modules/assign/stores/assignment';
import { STATE_KEYS } from '@/modules/assign/constant/stateKeys';

export const useFetchThemePage = (options?: UseQueryOptions<ThemePageQueryResponse, any, ThemePageQueryResponse>) => {
  const editorStore = useEditorStore();
  const assignStore = useAssignStore();
  const { handleError } = useNotification();

  const pageId = computed(() => editorStore.getEditingPageId);
  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);

  const themePageVariables = computed(() => {
    return {
      id: pageId.value,
    };
  });

  // Fetch themePage
  const themePage = useThemePageQuery(themePageVariables, {
    onError: handleError,
    enabled: computed(() => pageId.value !== '' && !isEditThemeSection.value),
    ...options,
  });

  const isHomePage = computed(() => {
    return themePage.data.value?.themePage?.type === 'STATIC' && themePage.data.value.themePage.default;
  });

  const isOriginTemplate = computed(() => {
    return themePage.data.value?.themePage?.isPlatformDefault;
  });

  const willMakeDefaultWhenPublish = computed(() => {
    const key = themePage.data.value?.themePage?.type === 'GP_PRODUCT' ? STATE_KEYS.PRODUCT : STATE_KEYS.COLLECTION;
    return assignStore.getWillMakeDefaultWhenPublish(key);
  });

  const setToLocalStore = () => {
    if (themePage.data.value?.themePage?.id) {
      editorStore.setInitLoadingTemplate(false);
      editorStore.setEditingPageType(themePage.data.value.themePage?.type ?? null);
      editorStore.setEditingPageStatus(themePage.data.value.themePage.status ?? '');
      editorStore.setEditingPublishedAt(themePage.data.value.themePage?.publishedAt ?? null);
      editorStore.setPageStatus(themePage.data.value.themePage?.status ?? 'DRAFT');

      willMakeDefaultWhenPublish.value
        ? editorStore.setEditingPageDefault(true)
        : editorStore.setEditingPageDefault(themePage.data.value.themePage?.default ?? false);

      stopW();
    }
  };

  const stopW = watch(themePage.data, () => {
    setToLocalStore();
  });
  setToLocalStore();

  return {
    ...themePage,
    isHomePage,
    isOriginTemplate,
  };
};
