import useGlobalStyleStore from '../stores/globalStyle';
import { useSidebarStore } from '../stores/sidebar';

export default function useGlobalStyle() {
  const globalStyleStore = useGlobalStyleStore();
  const sidebarStore = useSidebarStore();

  const checkDiscardChangeGlobalStyle = () => {
    if (isDiscardChangeGlobalStyle()) {
      return;
    }

    closeGlobalStyleSetting();
  };

  const isDiscardChangeGlobalStyle = () => {
    const isChangeGlobalStyle = globalStyleStore.getIsChangeGlobalStyle;
    if (isChangeGlobalStyle) {
      globalStyleStore.setIsDiscardChangeGS(true);
    }

    return isChangeGlobalStyle;
  };

  const closeGlobalStyleSetting = () => {
    if (sidebarStore.getMode === 'global-style') {
      sidebarStore.setMode();
    }
  };

  return {
    isDiscardChangeGlobalStyle,
    closeGlobalStyleSetting,
    checkDiscardChangeGlobalStyle,
  };
}
