function generateEventId(groupId: Number, eventIndex: Number) {
  return parseInt(groupId + eventIndex.toString().padStart(4, '0'));
}

const PAGE_CREATION_WORKFLOW = 701;
export const PAGE_CREATION_WORKFLOW_EVENT = {
  EDITOR_SAVE_PAGE: generateEventId(PAGE_CREATION_WORKFLOW, 2),
  EDITOR_PREVIEW_PAGE: generateEventId(PAGE_CREATION_WORKFLOW, 3),
  EDITOR_PUBLISH_PAGE: generateEventId(PAGE_CREATION_WORKFLOW, 4),
};
const EDITOR_SEARCH_WORKFLOW = 702;
export const EDITOR_SEARCH_WORKFLOW_EVENT = {
  EDITOR_SEARCH: generateEventId(EDITOR_SEARCH_WORKFLOW, 1),
};

const EDITOR_SECTION_LIMIT = 703;
export const EDITOR_SECTION_LIMIT_EVENT = {
  SECTION_LIMIT: generateEventId(EDITOR_SECTION_LIMIT, 1),
};

const EDITOR_ELEMENT = 704;
export const EDITOR_ELEMENT_EVENT = {
  ELEMENT_ACTIVE: generateEventId(EDITOR_ELEMENT, 1),
  ELEMENT_SETTING_BLOCK_ACTIVE: generateEventId(EDITOR_ELEMENT, 2),
  DEVICE_MODE_MOBILE: generateEventId(EDITOR_ELEMENT, 3),
  CHANGE_DEVICE: generateEventId(EDITOR_ELEMENT, 4),
  OPEN_HELP_POPOVER: generateEventId(EDITOR_ELEMENT, 5),
  OPEN_HELP_CENTER: generateEventId(EDITOR_ELEMENT, 6),
  CONTROL_CHANGE: generateEventId(EDITOR_ELEMENT, 7),
  EDITOR_ELEMENT_CONTROL_CHANGE: generateEventId(EDITOR_ELEMENT, 8),
};
const IMAGE_TO_LAYOUT = 705;

export const IMAGE_TO_LAYOUT_EVENT = {
  GENERATE_URL_TO_LAYOUT: generateEventId(IMAGE_TO_LAYOUT, 1),
  GENERATE_IMAGE_TO_LAYOUT: generateEventId(IMAGE_TO_LAYOUT, 2),
  ADD_SECTION_GENERATE_FROM_URL: generateEventId(IMAGE_TO_LAYOUT, 3),
  ADD_SECTION_GENERATE_FROM_IMAGE: generateEventId(IMAGE_TO_LAYOUT, 4),
  VIEW_POPUP_SELECT_SECTIONS_GENERATE_TO_LAYOUT: generateEventId(IMAGE_TO_LAYOUT, 5),
  GENERATE_SELECTED_SECTIONS: generateEventId(IMAGE_TO_LAYOUT, 6),
  GENERATE_EDITABLE_LAYOUT_STATUS: generateEventId(IMAGE_TO_LAYOUT, 7),
};

const DUPLICATE_PAGE_ID = 706;

export const DUPLICATE_PAGE_EVENT = {
  DUPLICATE: generateEventId(DUPLICATE_PAGE_ID, 1),
  DUPLICATE_TO: generateEventId(DUPLICATE_PAGE_ID, 2),
};

const LIBRARY = 707;

export const LIBRARY_EVENT = {
  VISIT_LIBRARY: generateEventId(LIBRARY, 1),
  VISIT_SEARCH_LIBRARY: generateEventId(LIBRARY, 2),
  SEARCH_LIBRARY: generateEventId(LIBRARY, 3),
  FILTER_USE_CASE_LIBRARY: generateEventId(LIBRARY, 4),
  PREVIEW_LIBRARY: generateEventId(LIBRARY, 5),
  ADD_LIBRARY_TO_PAGE: generateEventId(LIBRARY, 6),
};

const ICON_PICKER = 708;

export const ICON_PICKER_EVENT = {
  SEARCH_ICONS: generateEventId(ICON_PICKER, 1),
  UPLOAD_ICONS: generateEventId(ICON_PICKER, 2),
  PICK_ICON: generateEventId(ICON_PICKER, 3),
};

const ANIMATION_TOGGLE = 709;

export const ANIMATION_TOGGLE_EVENT = {
  TOGGLE: generateEventId(ANIMATION_TOGGLE, 1),
};

const SHORT_VIDEO = 710;

export const SHORT_VIDEO_EVENT = {
  EDITOR_COUNT_VIEW: generateEventId(SHORT_VIDEO, 1),
  EDITOR_COUNT_OPEN_SHORT_VIDEO: generateEventId(SHORT_VIDEO, 2),
};

const THIRD_PARTY_PLACEMENT_POPUP = 711;

export const THIRD_PARTY_PLACEMENT_POPUP_EVENT = {
  EDITOR_SHOW_THIRD_PARTY_PLACEMENT_POPUP: generateEventId(THIRD_PARTY_PLACEMENT_POPUP, 1),
  EDITOR_HIDE_THIRD_PARTY_PLACEMENT_POPUP: generateEventId(THIRD_PARTY_PLACEMENT_POPUP, 2),
  EDITOR_CLICK_TRY_NOW_THIRD_PARTY_PLACEMENT_POPUP: generateEventId(THIRD_PARTY_PLACEMENT_POPUP, 3),
};

const BUILD_WITH_SECTION_DROP_ELEMENT = 712;

export const BUILD_WITH_SECTION_DROP_ELEMENT_EVENT = {
  EDITOR_BUILD_WITH_SECTION_DROP_ELEMENT: generateEventId(BUILD_WITH_SECTION_DROP_ELEMENT, 1),
};

const EDITOR_PARTNER_SHIP_SECTION_PLACEMENT = 713;

export const EDITOR_PARTNER_SHIP_SECTION_PLACEMENT_EVENT = {
  EDITOR_PARTNER_SHIP_SECTION_PLACEMENT_CLICK_TRY_OUT: generateEventId(EDITOR_PARTNER_SHIP_SECTION_PLACEMENT, 1),
};

const EDITOR_NOTICE = 714;

export const EDITOR_NOTICE_EVENT = {
  EDITOR_NOTICE_SUCCESS: generateEventId(EDITOR_NOTICE, 1),
  EDITOR_NOTICE_ERROR: generateEventId(EDITOR_NOTICE, 2),
};

const THIRD_PARTY = 715;

export const THIRD_PARTY_EVENT = {
  EDITOR_THIRD_PARTY_CLICK_OPEN_APP: generateEventId(THIRD_PARTY, 1),
  EDITOR_THIRD_PARTY_CLICK_INSTALL: generateEventId(THIRD_PARTY, 2),
};

export const INTERACTION = 716;

export const INTERACTION_EVENT = {
  EDITOR_TRIGGER_SETUP: generateEventId(INTERACTION, 1),
  EDITOR_TRIGGER_SECOND_EVENT_SETUP: generateEventId(INTERACTION, 2),
  EDITOR_TARGET_SETUP: generateEventId(INTERACTION, 3),
};
