import { computed } from 'vue';
import useSectionStore from '@/modules/editor/modules/common/stores/section';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import useCurrentShop from '@/composables/useCurrentShop';
import type { Component, Section } from '@/modules/editor/modules/common/utils/types';
import { convertComponentToString, generateNewUid } from '@/modules/editor/modules/common/utils/section/component';
import { initNewSection } from '@/modules/editor/modules/common/utils/section/section';
import { sectionCreate, sectionDelete } from '@/modules/editor/modules/common/use-cases/section';
import useInsertTemplate from '@/modules/editor/modules/library/hooks/useInsertTemplate';
import useComponent from '@/modules/editor/modules/common/hooks/useComponent';
import { BUILD_WITH_SECTION_DROP_ELEMENT_EVENT } from '@/modules/editor/modules/common/constants/trackEventIds';
import useShopStore from '@/stores/shop';
import { useGtm } from '@gtm-support/vue-gtm';
import localForage from 'localforage';
import type { SectionCategory } from '@/types/graphql';
import type { LibrarySectionsQueryResponse } from '@/api/app/queries/library-sections.generated';
import { sentryCaptureException } from '@/modules/editor/modules/common/use-cases/sentry';
const store = localForage.createInstance({
  name: 'buildWithSection',
});
const STORE_KEY_PREFIX = 'build-with-section';
export const CATEGORIES_SUFFIX = 'categories';
export const CATEGORY_ITEMS_SUFFIX = 'category-items';
const CACHE_MAX_AGE = 24 * 60 * 60 * 1000; // 24h

const useBuildWithSection = (checkCacheData: boolean = false) => {
  const sectionStore = useSectionStore();
  const editorStore = useEditorStore();
  const shopStore = useShopStore();
  const gtm = useGtm();
  const shopifyDomain = computed(() => shopStore.getShopDomain);
  const currentShop = useCurrentShop();
  const { addThemeSectionLibraryFromBuildWithSection, convertSectionComponentWhenInsert } = useInsertTemplate();
  const { successDuplicateHandler } = useComponent();

  const isEditThemeSection = computed(() => editorStore.getIsEditThemeSection);
  const buildWithSectionLoadingIds = computed(() => sectionStore.getBuildWithSectionLoadingIds);
  const buildWithSectionMode = computed(() => editorStore.buildWithSectionMode);
  const isHoverBuildWithSectionList = computed(() => sectionStore.getIsHoverBuildWithSectionList);
  const isHoverBuildWithSectionCategoryList = computed(() => sectionStore.isHoverBuildWithSectionCategoryList);
  const buildWithSectionCurrentCategory = computed(() => sectionStore.getBuildWithSectionCurrentCategory);
  const buildWithSectionCurrentCategoryId = computed(() => buildWithSectionCurrentCategory.value?.id ?? null);
  const sectionDragging = computed(() => sectionStore.getSectionDragging);
  const buildWithSectionDraggingInfo = computed(() => sectionStore.getBuildWithSectionDraggingInfo);
  const pageId = computed(() => editorStore.getEditingPageId);

  const isShowPopup = computed(
    () =>
      buildWithSectionMode.value && (isHoverBuildWithSectionCategoryList.value || isHoverBuildWithSectionList.value),
  );

  const setDefaultActiveTab = () => {
    const buildWithSectionModeLocalStorage = localStorage.getItem('build-with-section-mode');
    if (buildWithSectionModeLocalStorage) {
      editorStore.setBuildWithSectionMode(!!+buildWithSectionModeLocalStorage);
      return;
    }

    if (!currentShop.value) {
      localStorage.setItem('build-with-section-mode', '0');
      return editorStore.setBuildWithSectionMode(false);
    }
    const shopCreatedAt = currentShop.value?.createdAt;
    if (!shopCreatedAt) {
      localStorage.setItem('build-with-section-mode', '0');
      return editorStore.setBuildWithSectionMode(false);
    }

    const isNewUser = new Date('2024-03-15').getTime() - new Date(shopCreatedAt.toString()).getTime() < 0;
    editorStore.setBuildWithSectionMode(isNewUser);
    localStorage.setItem('build-with-section-mode', (+isNewUser).toString());
  };

  const createBuildWithSection = (
    isLoading: boolean,
    buildWithSectionComponents: Component[] | null,
    direction: 'after' | 'before',
    newSectionCid: string,
  ) => {
    if (!buildWithSectionComponents) return;

    const newSectionComponents = buildWithSectionComponents[0];
    generateNewUid(newSectionComponents);

    if (!isLoading) {
      gtm?.trackEvent({
        event: 'EDITOR_BUILD_WITH_SECTION_DROP_ELEMENT',
        data: {
          event_id: BUILD_WITH_SECTION_DROP_ELEMENT_EVENT.EDITOR_BUILD_WITH_SECTION_DROP_ELEMENT,
          store_domain: shopifyDomain.value,
          library_section_id: buildWithSectionDraggingInfo.value?.id ?? '',
          library_section_name: buildWithSectionDraggingInfo.value?.name ?? '',
          page_id: pageId.value,
          page_type: buildWithSectionDraggingInfo.value?.pageType ?? '',
          time: new Date().toISOString(),
        },
      });
    }

    const initSection = initNewSection({
      components: [newSectionComponents],
      isMobile: editorStore.mobileOnly,
      options: { isBuildWithSectionLoading: isLoading },
    });

    convertSectionComponentWhenInsert(newSectionComponents, 'library_sections');

    const newSection: Section = {
      ...initSection,
      isCustom: false,
      isCustomNew: false,
      isImportFromLibrary: true,
      importFromLibraryType: 'library_sections',
      display: true,
      component: convertComponentToString(newSectionComponents),
    };

    if (isEditThemeSection.value) {
      addThemeSectionLibraryFromBuildWithSection(newSectionComponents, direction);
      return;
    }

    sectionCreate(
      newSection,
      {
        cid: newSectionCid,
        direction: direction == 'before' ? 'before' : 'after',
      },
      {
        noRecordHistory: isLoading,
      },
    );

    if (isLoading) {
      sectionStore.setBuildWithSectionLoadingIds([
        ...buildWithSectionLoadingIds.value,
        newSection?.cid ?? ('' as string),
      ]);
      return;
    }
    removeLoadingCpn();
    sectionStore.setSectionDragging('');
    sectionStore.setBuildWithSectionDraggingInfo(null);

    setTimeout(() => {
      onOpenSetting(newSectionComponents.uid, newSectionCid);
      successDuplicateHandler(newSectionComponents.uid);
    }, 0);
  };

  const onOpenSetting = (componentUid: string, sectionId: string) => {
    editorStore.setEditingComponentUid(componentUid);
    editorStore.setEditingSectionCid(sectionId);
  };

  const removeLoadingCpn = () => {
    buildWithSectionLoadingIds.value.forEach((cid) => {
      sectionDelete(cid, {
        noRecordHistory: true,
      });
    });
    sectionStore.setBuildWithSectionLoadingIds([]);
  };

  const generateCategoryItemsKey = (afterCursor: string | null) =>
    `${CATEGORY_ITEMS_SUFFIX}-${buildWithSectionCurrentCategoryId.value}${afterCursor ? `-${afterCursor}` : ''}`;

  const checkCacheIsFresh = (target: string) => {
    try {
      const now = new Date().getTime();
      const sectionMaxAge = localStorage.getItem(`${STORE_KEY_PREFIX}-max-age`);
      if (!sectionMaxAge) return false;

      const json = JSON.parse(sectionMaxAge);
      return +json?.[target] > now;
    } catch (e) {
      sentryCaptureException(
        'checkCacheIsFresh',
        'check cached is fresh from IndexedDB warning',
        { e, stringifyData: localStorage.getItem(`${STORE_KEY_PREFIX}-max-age`) },
        {
          level: 'error',
        },
      );
    }
  };

  const checkSectionItemsIsFresh = (afterCursor: string) => {
    return checkCacheIsFresh(generateCategoryItemsKey(afterCursor));
  };

  const setCacheMaxAge = (target: string) => {
    try {
      const now = new Date().getTime();
      const maxAge = (now + CACHE_MAX_AGE).toString();
      const sectionMaxAge = localStorage.getItem(`${STORE_KEY_PREFIX}-max-age`);
      if (!sectionMaxAge) {
        localStorage.setItem(
          `${STORE_KEY_PREFIX}-max-age`,
          JSON.stringify({
            [target]: maxAge,
          }),
        );
        return;
      }

      const json = JSON.parse(sectionMaxAge);
      json[target] = maxAge;
      return localStorage.setItem(`${STORE_KEY_PREFIX}-max-age`, JSON.stringify(json));
    } catch (e) {
      sentryCaptureException(
        'setCacheMaxAge',
        'set cached max age from IndexedDB warning',
        { e, stringifyData: localStorage.getItem(`${STORE_KEY_PREFIX}-max-age`) },
        {
          level: 'error',
        },
      );
    }
  };

  const getCachedCategories = () => {
    try {
      return store.getItem(`${STORE_KEY_PREFIX}-${CATEGORIES_SUFFIX}`);
    } catch (e) {
      sentryCaptureException(
        'getCachedCategories',
        'get cached categories from IndexedDB warning',
        { e },
        {
          level: 'error',
        },
      );
      return new Promise((resolve) => resolve(null));
    }
  };

  const setCachedCategories = (data: SectionCategory[]) => {
    try {
      setCacheMaxAge(CATEGORIES_SUFFIX);
      return store.setItem(`${STORE_KEY_PREFIX}-${CATEGORIES_SUFFIX}`, data);
    } catch (e) {
      sentryCaptureException(
        'setCachedCategories',
        'set cached categories from IndexedDB warning',
        { e },
        {
          level: 'error',
        },
      );
    }
  };

  const getCachedCategoryItems = (afterCursor: string = ''): Promise<LibrarySectionsQueryResponse | null> => {
    try {
      return store.getItem(`${STORE_KEY_PREFIX}-${generateCategoryItemsKey(afterCursor)}`);
    } catch (e) {
      sentryCaptureException(
        'getCachedCategoryItems',
        'get cached category items from IndexedDB warning',
        { e },
        {
          level: 'error',
        },
      );
      return new Promise((resolve) => resolve(null));
    }
  };

  const setCachedCategoryItems = (data: LibrarySectionsQueryResponse, afterCursor: string = '') => {
    try {
      const key = generateCategoryItemsKey(afterCursor);
      setCacheMaxAge(key);
      return store.setItem(`${STORE_KEY_PREFIX}-${key}`, data);
    } catch (e) {
      sentryCaptureException(
        'setCachedCategoryItems',
        'set cached category items from IndexedDB warning',
        { e },
        {
          level: 'error',
        },
      );
    }
  };

  const isCategoriesCacheFresh = ref<boolean>(true);
  const cachedCategories = ref();

  onBeforeMount(async () => {
    if (!checkCacheData) return;

    cachedCategories.value = await getCachedCategories();
    isCategoriesCacheFresh.value = checkCacheIsFresh(CATEGORIES_SUFFIX) && cachedCategories.value;
  });

  return {
    isShowPopup,
    buildWithSectionCurrentCategory,
    isHoverBuildWithSectionList,
    isHoverBuildWithSectionCategoryList,
    buildWithSectionMode,
    sectionDragging,
    cachedCategories,
    isCategoriesCacheFresh,
    buildWithSectionCurrentCategoryId,
    checkSectionItemsIsFresh,
    setDefaultActiveTab,
    createBuildWithSection,
    removeLoadingCpn,
    getCachedCategories,
    getCachedCategoryItems,
    setCachedCategories,
    setCachedCategoryItems,
    setCacheMaxAge,
  };
};

export default useBuildWithSection;
