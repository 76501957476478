import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ShopMetaSelectFragmentDoc } from '../fragments/shop-meta.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
export type ShopMetaQueryVariables = Types.Exact<{
  after?: Types.InputMaybe<Types.Scalars['Cursor']>;
  before?: Types.InputMaybe<Types.Scalars['Cursor']>;
  first?: Types.InputMaybe<Types.Scalars['Int']>;
  last?: Types.InputMaybe<Types.Scalars['Int']>;
  where?: Types.InputMaybe<Types.ShopMetaWhereInput>;
}>;

export type ShopMetaQueryResponse = {
  shopMeta?: Types.Maybe<
    Pick<Types.ShopMetaConnection, 'totalCount'> & {
      edges?: Types.Maybe<
        Array<
          Types.Maybe<
            Pick<Types.ShopMetaEdge, 'cursor'> & {
              node?: Types.Maybe<Pick<Types.ShopMeta, 'id' | 'value' | 'key' | 'createdAt' | 'updatedAt'>>;
            }
          >
        >
      >;
      pageInfo: Pick<Types.PageInfo, 'endCursor' | 'hasNextPage' | 'hasPreviousPage' | 'startCursor'>;
    }
  >;
};

export const ShopMetaDocument = `
    query shopMeta($after: Cursor, $before: Cursor, $first: Int, $last: Int, $where: ShopMetaWhereInput) {
  shopMeta(
    after: $after
    before: $before
    first: $first
    last: $last
    where: $where
  ) {
    edges {
      cursor
      node {
        ...ShopMetaSelect
      }
    }
    pageInfo {
      endCursor
      hasNextPage
      hasPreviousPage
      startCursor
    }
    totalCount
  }
}
    ${ShopMetaSelectFragmentDoc}`;
export const useShopMetaQuery = <TData = ShopMetaQueryResponse, TError = unknown>(
  variables?: ShopMetaQueryVariables | ComputedRef<ShopMetaQueryVariables> | Ref<ShopMetaQueryVariables>,
  options?: UseQueryOptions<ShopMetaQueryResponse, TError, TData>,
) =>
  useQuery<ShopMetaQueryResponse, TError, TData>(
    variables === undefined ? ['shopMeta'] : ['shopMeta', variables],
    appFetcher<ShopMetaQueryResponse, ShopMetaQueryVariables>(ShopMetaDocument, variables),
    options,
  );

useShopMetaQuery.getKey = (
  variables?: ShopMetaQueryVariables | ComputedRef<ShopMetaQueryVariables> | Ref<ShopMetaQueryVariables>,
) => (variables === undefined ? ['shopMeta'] : ['shopMeta', variables]);
