import type { PageSection } from '@/types/graphql';
import type { Component, GroupTypeSetting, Section } from '@/modules/editor/modules/common/utils/types';
import { loopComponent } from '../utils/section/component';
import type { Maybe } from '@/types/custom';
import { cloneDeepObject } from '@/utils/common';
import useBuilderConfigStore from '../../component-preset/stores/builderConfig';

type CacheType = {
  component: Record<
    string, // component uid
    Maybe<{
      component: {
        label: string;
        tag: string;
        customLabel: string;
        uid: string;
      };
      section: {
        cid: string;
      };
    }>
  >;
  setting: {
    openSettingComponent: Record<string, { tabId: GroupTypeSetting }>;
  };
};
const defaultCache: CacheType = {
  component: {},
  setting: {
    openSettingComponent: {},
  },
};

let cache: CacheType = cloneDeepObject(defaultCache);

//===================================== Section - Component ===========================
export const cacheAddComponent = (component: Component, section: Section) => {
  Object(cache.component)[component.uid] = {
    component: {
      uid: component.uid,
      label: component.label,
      tag: component.tag,
      customLabel: component.customLabel,
    },
    section: {
      cid: section.cid,
      isCustom: section.isCustom,
    },
  };
};

export const cacheDeleteComponent = (uid: string) => {
  delete Object(cache.component)[uid];
};

export const cacheAddComponentBySection = (section: PageSection) => {
  if (section?.component) {
    const jsonComponent = JSON.parse(section?.component ?? '{}') as Component;
    loopComponent(jsonComponent, (component) => {
      cacheAddComponent(component, section);
    });
  }
};

export const cacheDeleteComponentBySectionCid = (cid: string) => {
  for (const componentUid in cache.component) {
    if (Object.prototype.hasOwnProperty.call(cache.component, componentUid)) {
      const sectionCid = Object(cache.component)[componentUid].section.cid;
      if (sectionCid == cid) {
        cacheDeleteComponent(componentUid);
      }
    }
  }
};

export const cacheGetComponentBySectionCid = (cid: string) => {
  for (const componentUid in cache.component) {
    if (Object.prototype.hasOwnProperty.call(cache.component, componentUid)) {
      const currentComponent = Object(cache.component)[componentUid];
      const sectionCid = currentComponent.section.cid;
      if (sectionCid == cid) {
        return currentComponent;
      }
    }
  }
};

export const cacheGetSectionCidByComponentUid = (componentUid: string) => {
  return cache.component?.[componentUid]?.section?.cid ?? '';
};

export const cacheGetSectionIsCustomByComponentUid = (componentUid: string): boolean => {
  for (const uid in cache.component) {
    if (Object.prototype.hasOwnProperty.call(cache.component, uid)) {
      if (componentUid == uid) {
        const isCustom = Object(cache.component)[componentUid].section.isCustom;
        return isCustom;
      }
    }
  }
  return false;
};

export const cacheGetDataComponentByComponentUid = (componentUid: string) => {
  return cache.component?.[componentUid]?.component;
};

export const cacheGetComponentLabelByComponentUid = (componentUid: string) => {
  const builderConfigStore = useBuilderConfigStore();
  const builderSettings = builderConfigStore.getBuilderSettings;

  const component = cacheGetDataComponentByComponentUid(componentUid);
  if (!component) return undefined;

  if (component?.tag === 'CSSCode') {
    return component?.customLabel ? `<${component?.customLabel}>` : `<${component?.label}>`;
  }
  return component?.customLabel
    ? `<${component?.customLabel}>`
    : builderSettings?.[component?.tag]?.label || component?.label;
};

export const cacheGetComponentTagByComponentUid = (componentUid: string) => {
  const component = cacheGetDataComponentByComponentUid(componentUid);
  return component?.tag;
};

//============================================= Setting ===============================
export const cacheUpdateOpenSettingComponent = (componentUid: string, tabId: GroupTypeSetting) => {
  cache.setting.openSettingComponent[componentUid] = {
    tabId,
  };
};

export const cacheGetOpenSettingComponent = (componentUid: string) => {
  const data = cache.setting.openSettingComponent[componentUid];
  if (data) return data;
  return null;
};

//============================================= Clear ===============================
export const cacheClear = () => {
  cache = JSON.parse(JSON.stringify(defaultCache));
};

//============================================= Update label ===============================
export const cacheUpdateLabel = (componentUid: string, newLabel: string) => {
  const component = cacheGetDataComponentByComponentUid(componentUid);
  if (component) component.customLabel = newLabel;
};
