import { useSectionStore } from '@/modules/editor/modules/common/stores/section';
import { SECTION_TAG, MAXIMUM_SECTION } from '../utils/const';
import { usePageInformation } from '@/modules/editor/modules/common/hooks/usePageInformation';

const MAXIMUM_PRODUCT = 20;
export const usePublishLimitation = () => {
  const sectionStore = useSectionStore();
  const { isInstant } = usePageInformation();
  const items = computed(() => sectionStore.getItems);
  const components = computed(() => items.value.map((item) => JSON.parse(item.component || '{}')));
  const shopifySections = computed(() => items.value.map((item) => item.isShopifySection).length);

  let sectionCount = 0;
  let productCount = 0;
  const getSectionCountOfComponent = (component: any) => {
    const { tag, childrens } = component;
    if (!childrens) {
      return;
    }
    if (tag === SECTION_TAG) {
      sectionCount = sectionCount + 1;
    }

    childrens.forEach((element: any) => {
      getSectionCountOfComponent(element);
    });
  };

  const getProductCountOfComponent = (component: any) => {
    const { tag, childrens, settings } = component;
    if (!childrens) {
      return;
    }
    if (tag === 'Product' && settings?.productSetting?.productId && settings.productSetting.productId !== 'latest') {
      productCount = productCount + 1;
    }

    if (tag === 'ProductList' && settings?.productIds) {
      productCount = productCount + (settings?.productIds?.length || 0);
    }

    childrens.forEach((element: any) => {
      getProductCountOfComponent(element);
    });
  };

  const getProductCount = () => {
    productCount = 0;

    components.value.forEach((element) => {
      getProductCountOfComponent(element);
    });

    return productCount;
  };

  const getSectionCount = () => {
    sectionCount = 0;
    components.value.forEach((element) => {
      getSectionCountOfComponent(element);
    });

    return sectionCount;
  };

  const checkSectionLimitation = () => {
    if (isInstant.value) {
      return true;
    }
    const sectionCount = getSectionCount();

    if (sectionCount >= MAXIMUM_SECTION) {
      return false;
    }
    return true;
  };

  const checkSectionLimitationPublish = computed(() => {
    if (isInstant.value) {
      return true;
    }
    const sectionCount = items.value?.length || 0;

    if (sectionCount > MAXIMUM_SECTION) {
      return false;
    }
    return true;
  });

  const checkProductLimitation = () => {
    const productCount = getProductCount();
    if (productCount > MAXIMUM_PRODUCT) {
      return false;
    }
    return true;
  };

  const checkHasNoSection = computed(() => {
    sectionCount = 0;
    components.value.forEach((element) => {
      getSectionCountOfComponent(element);
    });
    return sectionCount + shopifySections.value === 0;
  });
  return {
    getSectionCount,
    checkSectionLimitation,
    checkProductLimitation,
    getProductCount,
    checkSectionLimitationPublish,
    checkHasNoSection,
  };
};
