import { getConfig } from '@/config';

export const getOriginGP = () => {
  try {
    const urlGemPage = new URL(getConfig('featureConfig').instantBackToURL || '');
    return urlGemPage.origin;
  } catch (e) {
    return '';
  }
};

export const getTargetGP = () => {
  try {
    if (getConfig('env') === 'local') {
      return '*';
    }
    const urlGemPage = new URL(getConfig('featureConfig').instantBackToURL || '');
    return urlGemPage.origin;
  } catch (e) {
    return '*';
  }
};
