export const ENABLE_SURVEY_FORM = false;
export const META_KEY_SURVEY_SHOWN: string = 'survey_shown';
export const MIN_TIME_SINCE_LAST_ATTEMPT_MS = 2 * 24 * 60 * 60 * 1000; // 48H;
export const ACTIVE_EDITOR_TIME_MS = 2 * 60 * 60 * 1000; // 2h
export const TIME_DELAY_SHOW_MS = 5000; // 5S;
export const SHOP_META_DROP_ELEMENT_COUNT: string = 'drop_element_count';
export const SHOP_META_DROP_ELEMENT_COUNT_MAX: number = 5;
export const SHOP_META_IS_PREVIEWED: string = 'is_previewed';

export const SPECIAL_ELEMENTS_UPDATE_SURVEY = [
  'Product',
  'ProductSwatches',
  'Row',
  'Carousel',
  'ProductImages',
  'Tab',
  'Accordion',
];

// ======================= Trigger 1 =======================
export const SURVEY_DRAG_DROP: string = 'survey-drag-drop';
// ======================= Trigger 2 =======================
export const SURVEY_SETTING_ELEMENT: string = 'survey-setting-element';
// ======================= Trigger 3 =======================
export const SURVEY_ACTIVE_EDITOR: string = 'survey-active-editor-2';
// ======================= Trigger 4 - 1 =======================
export const SURVEY_VIEW_ACROSS_DEVICES: string = 'survey-view-across-devices';
// ======================= Trigger 4 - 2 =======================
export const SURVEY_PUBLISHING: string = 'survey-publishing';
export const ALL_SURVEY = [
  SURVEY_DRAG_DROP,
  SURVEY_SETTING_ELEMENT,
  SURVEY_ACTIVE_EDITOR,
  SURVEY_PUBLISHING,
  SURVEY_VIEW_ACROSS_DEVICES,
];

export const SHOP_META_COMPRESS_IMAGE = 'compress_image';
