export const replaceAllObjKeys = (obj: any, keys: string[]) => {
  if (Array.isArray(obj)) {
    for (let i = 0; i < obj.length; i++) {
      replaceAllObjKeys(obj[i], keys);
    }
  } else if (typeof obj === 'object') {
    for (const key in obj) {
      if (keys.includes(key)) {
        delete obj[key];
      } else {
        replaceAllObjKeys(obj[key], keys);
      }
    }
  }
  return obj;
};
