import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import {
  cacheGetComponentTagByComponentUid,
  cacheGetSectionCidByComponentUid,
} from '@/modules/editor/modules/common/use-cases/cache';
import {
  componentRemove,
  componentDuplicate,
  componentForceUpdateConfigurations,
} from '@/modules/editor/modules/common/use-cases/component';
import { sectionDelete, sectionDuplicate } from '@/modules/editor/modules/common/use-cases/section';
import useGlobalStyle from '../../sidebar/hooks/useGlobalStyle';
import { MODAL_TAG, SECTION_TAG, STICKY_TAG } from '../utils/const';
import type { ActionOptions, Component, ComponentConfig, CopiedData } from '../utils/types';
import useSectionStore from '../stores/section';
import { convertComponentToJSON, convertComponentToString, getComponentByUid } from '../utils/section/component';
import { cloneDeepObject, parseJson } from '@/utils/common';
import type { PageSection } from '@/types/graphql';
import { emitActiveComponent, emitUpdateItemAttribute } from '../../preview/utils/emitToIframe';
import useVerifyPostPurchase from './useVerifyPostPurchase';
import useBuilderConfigStore from '../../component-preset/stores/builderConfig';

export default function useComponent() {
  const editorStore = useEditorStore();
  const sectionStore = useSectionStore();
  const { checkDiscardChangeGlobalStyle } = useGlobalStyle();
  const { isUnableDelete } = useVerifyPostPurchase();

  const remove = (uid: string, options?: ActionOptions) => {
    if (uid) {
      if (isUnableDelete(uid)) return false;

      const tag = cacheGetComponentTagByComponentUid(uid);
      if (tag && [SECTION_TAG, MODAL_TAG, STICKY_TAG].includes(tag)) {
        const cid = cacheGetSectionCidByComponentUid(uid);
        if (cid) {
          sectionDelete(cid, options);
        }
      } else {
        componentRemove([uid], options);
      }
    }
  };

  const duplicate = (uid: string, options?: ActionOptions) => {
    if (!uid) return;
    const tag = cacheGetComponentTagByComponentUid(uid);
    let newId: string | undefined = '';
    if (tag && [SECTION_TAG, MODAL_TAG, STICKY_TAG].includes(tag)) {
      const cid = cacheGetSectionCidByComponentUid(uid);
      if (!cid) return;
      const { isHandleThemeSection, isHandleShopifySection } = options ?? {};
      newId = sectionDuplicate(cid, { isHandleThemeSection, isHandleShopifySection });
      successDuplicateHandler(newId);
    } else {
      newId = componentDuplicate(uid, options);
      successDuplicateHandler(newId);
    }
    setTimeout(() => {
      if (newId) {
        emitActiveComponent({
          componentUid: newId,
          productId: editorStore.getEditingProductId,
          articleId: editorStore.getEditingArticleId,
        });
      }
    }, 0);
  };

  const visibilityShopifySection = (uid: string) => {
    if (!uid) return;
    const cid = cacheGetSectionCidByComponentUid(uid);
    const section = sectionStore.getItemByCid(cid);
    if (!section) return;
    const clone = JSON.parse(JSON.stringify(section));
    clone.isShopifyVisibility = !clone.isShopifyVisibility;
    sectionStore.update(clone);
    emitUpdateItemAttribute({ uid: uid, value: clone.isShopifyVisibility, attr: 'isShopifyVisibility' });
  };

  const successDuplicateHandler = (duplicatedId: string | undefined) => {
    if (!duplicatedId) return;
    editorStore.setEditingComponentUid(duplicatedId);

    nextTick(() => {
      if (!duplicatedId) return;

      const iframeDoc = getIframeDoc();
      if (!iframeDoc) return;

      const dom = iframeDoc.querySelector(`[data-uid='${duplicatedId}']`);
      if (!dom) return;

      if (!isTargetVisibleInIframeWithGivenExpectedPercent(dom, 70)) {
        nextTick(() => {
          scrollToActiveUid(duplicatedId);
        });
      }
    });
  };

  const scrollToActiveUid = (uid: string) => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>('.iframe');
    const $iframeDoc = $iframe?.contentDocument || ($iframe?.contentWindow && ($iframe?.contentWindow as any).document);

    if (!$iframeDoc) return;

    const component = $iframeDoc?.querySelector(`[data-uid="${uid}"]`);
    if (!component) return;

    const $iframeWin = $iframe?.contentWindow;
    if (!$iframeWin) return;

    const top = component.getBoundingClientRect().top + $iframeWin.scrollY - 70;
    $iframeWin.scrollTo({ top, behavior: 'smooth' });
  };

  const getIframeDoc = () => {
    const $iframe = document.body.querySelector<HTMLIFrameElement>('.iframe');
    return $iframe?.contentDocument || ($iframe?.contentWindow && ($iframe?.contentWindow as any).document);
  };

  const isTargetVisibleInIframeWithGivenExpectedPercent = (target: Element, expectedPercent: number): boolean => {
    const iframeDoc = getIframeDoc();
    const rect = target.getBoundingClientRect();
    const iframeHeight = iframeDoc?.innerHeight || iframeDoc?.documentElement?.clientHeight;

    return !(
      Math.floor(100 - ((rect.top >= 0 ? 0 : rect.top) / -rect.height) * 100) < expectedPercent ||
      Math.floor(100 - ((rect.bottom - iframeHeight) / rect.height) * 100) < expectedPercent
    );
  };

  const openSetting = (uid: string, productId?: string, articleId?: string) => {
    checkDiscardChangeGlobalStyle();
    if (uid) {
      editorStore.setEditingComponentUid(uid);
      editorStore.setEditingProductId(productId ?? '');
      editorStore.setEditingArticleId(articleId ?? '');
    }
  };

  const deactiveSetting = () => {
    editorStore.setEditingComponentUid(null);
  };

  const handleComponentConfiguration = async (componentUid: string, actionType: 'copy' | 'paste') => {
    if (!componentUid) return;

    const sectionCid = cacheGetSectionCidByComponentUid(componentUid);
    if (!sectionCid) return;

    const section = sectionStore.getItemByCid(sectionCid);
    const cloneSection = cloneDeepObject(section) as PageSection;
    if (!cloneSection?.component) return;

    const jsonComponent = convertComponentToJSON(cloneSection.component);
    if (!jsonComponent) return;

    const currentComponent = getComponentByUid(jsonComponent, componentUid);
    if (!currentComponent) return;

    switch (actionType) {
      case 'copy':
        copyComponentConfigurationToStore(currentComponent);
        break;
      case 'paste':
        pasteComponentConfigurationFromStore({ sectionCid, componentUid, currentComponent, jsonComponent });
        break;
    }
  };

  const copyComponentConfigurationToStore = (currentComponent: Component) => {
    const data = {
      tag: currentComponent.tag,
      advanced: currentComponent.advanced,
      styles: currentComponent.styles,
    };

    editorStore.setDataStyleCopied(data);
    return;
  };

  const pasteComponentConfigurationFromStore = ({
    sectionCid,
    componentUid,
    currentComponent,
    jsonComponent,
  }: {
    sectionCid: string;
    componentUid: string;
    currentComponent: Pick<Component, 'tag' | 'styles' | 'advanced'>;
    jsonComponent: Component;
  }) => {
    try {
      const dataStyle = getDataStyleCopiedByTag(currentComponent.tag);
      if (!dataStyle) return;

      currentComponent.styles = dataStyle.styles;
      currentComponent.advanced = dataStyle.advanced;
      const newComponent = convertComponentToString(jsonComponent);
      componentForceUpdateConfigurations({
        cid: sectionCid,
        component: newComponent,
        settings: {
          componentUid: componentUid,
        },
      });
    } catch {
      console.warn('Pasted data error!');
    }
  };

  const getDataStyleCopiedByTag = (componentTag: string) => {
    const dataStyleCopied = editorStore.getDataStyleCopied;
    if (
      !dataStyleCopied ||
      !dataStyleCopied.advanced ||
      !dataStyleCopied.styles ||
      dataStyleCopied.tag !== componentTag
    )
      return;

    return dataStyleCopied;
  };

  const getConfigurationFromClipboardByTag = async (
    componentTag: string,
    type: string = 'text/html',
  ): Promise<CopiedData | undefined> => {
    if (!componentTag || !navigator.clipboard) return;

    try {
      const clipboardContents = await navigator.clipboard.read();
      const clipboard = clipboardContents[0];
      if (!clipboard) return;

      const content: string = (await (await clipboard.getType(type)).text()) || '';
      const regex = /<div data-copy-style="gempages"><!--(.*?)--><\/div>/;
      const match = content.match(regex);
      if (!(match && match[1])) return;

      const dataStyleContent = match[1];
      const data = JSON.parse(dataStyleContent);
      if (!data || !data.advanced || !data.styles || data.tag !== componentTag) return;

      return data;
    } catch (error) {
      return;
    }
  };

  const getProductComponentCount = () => {
    const sectionItems = sectionStore.getItems;
    const sectionItemsCount = sectionItems.length;

    if (!sectionItemsCount) return 0;

    let count = 0;

    for (let i = 0; i < sectionItemsCount; i++) {
      const item = sectionItems[i];
      const component: any = parseJson(item?.component ?? '');
      const productComponents = getComponentByTag(component, 'Product');

      if (productComponents && productComponents.length > 0) {
        count += productComponents.length;
      }
    }

    return count;
  };

  const checkPageHasDynamicProductComponent = () => {
    const sectionItems = sectionStore.getItems;
    const sectionItemsCount = sectionItems.length;

    if (!sectionItemsCount) return undefined;

    for (let i = 0; i < sectionItemsCount; i++) {
      const item = sectionItems[i];
      const component: any = parseJson(item?.component ?? '');
      const dynamicProductComponents = getComponentByProductStatus(component, 'dynamic');

      if (dynamicProductComponents && dynamicProductComponents.length > 0) {
        return true;
      }
    }

    return false;
  };

  const getStaticProductComponentUid = () => {
    const sectionItems = sectionStore.getItems;
    const sectionItemsCount = sectionItems.length;

    if (!sectionItemsCount) return undefined;

    let staticUid;

    for (let i = 0; i < sectionItemsCount; i++) {
      const item = sectionItems[i];
      const component: any = parseJson(item?.component ?? '');
      const staticProductComponents = getComponentByProductStatus(component, 'static');

      if (staticProductComponents && staticProductComponents.length > 0) {
        staticUid = staticProductComponents[0]?.uid;
        break;
      }
    }

    return staticUid;
  };

  const goToFirstStaticProductComponent = () => {
    const uid = getStaticProductComponentUid();

    nextTick(() => {
      if (!uid) return;

      openSetting(uid);

      emitActiveComponent({
        componentUid: uid,
      });

      const iframeDoc = getIframeDoc();
      if (!iframeDoc) return;

      const dom = iframeDoc.querySelector(`[data-uid='${uid}']`);
      if (!dom) return;

      if (!isTargetVisibleInIframeWithGivenExpectedPercent(dom, 70)) {
        nextTick(() => {
          scrollToActiveUid(uid);
        });
      }
    });
  };

  const goToActiveComponent = (uid: string) => {
    if (!uid) return;

    const iframeDoc = getIframeDoc();
    if (!iframeDoc) return;

    const dom = iframeDoc.querySelector(`[data-uid='${uid}']`);
    if (!dom) return;

    if (!isTargetVisibleInIframeWithGivenExpectedPercent(dom, 70)) {
      nextTick(() => {
        scrollToActiveUid(uid);
      });
    }
  };

  function componentEditorConfigs(uid: string) {
    const builderConfigStore = useBuilderConfigStore();
    const builderSettings = computed(() => builderConfigStore.getBuilderSettings);
    const tag = cacheGetComponentTagByComponentUid(uid);
    if (tag) {
      const defaultSetting = builderSettings.value[tag] as ComponentConfig;
      if (defaultSetting) {
        return defaultSetting.editorConfigs;
      }
    }
    return null;
  }

  function getIsComponentNoSettings(uid: string) {
    return componentEditorConfigs(uid)?.component?.noSetting || false;
  }

  return {
    remove,
    duplicate,
    visibilityShopifySection,
    openSetting,
    deactiveSetting,
    handleComponentConfiguration,
    checkPageHasDynamicProductComponent,
    getDataStyleCopiedByTag,
    getConfigurationFromClipboardByTag,
    successDuplicateHandler,
    getStaticProductComponentUid,
    getProductComponentCount,
    goToFirstStaticProductComponent,
    goToActiveComponent,
    getIsComponentNoSettings,
    scrollToActiveUid,
  };
}

const getComponentByProductStatus = (jsonComponent: any, productStatus: string): any => {
  if (
    (productStatus === 'static' &&
      jsonComponent?.tag === 'Product' &&
      !jsonComponent?.settings?.productSetting?.productStatus) ||
    jsonComponent?.settings?.productSetting?.productStatus == productStatus
  ) {
    return [jsonComponent];
  }
  if (jsonComponent.childrens?.length) {
    let foundedComponents: any = [];

    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      const c = getComponentByProductStatus(children, productStatus);

      if (c) {
        foundedComponents = [...foundedComponents, ...c];
      }
    }

    return foundedComponents;
  }
  return null;
};

const getComponentByTag = (jsonComponent: any, componentTag: string): any => {
  if (jsonComponent?.tag === componentTag) {
    return [jsonComponent];
  }
  if (jsonComponent.childrens?.length) {
    let foundedComponents: any = [];

    for (let i = 0; i < jsonComponent.childrens.length; i++) {
      const children = jsonComponent.childrens[i];
      const c = getComponentByTag(children, componentTag);

      if (c) {
        foundedComponents = [...foundedComponents, ...c];
      }
    }

    return foundedComponents;
  }
  return null;
};
