import type { ThemePageType } from '../../common/utils/types';

const UPLOAD_CARE_PREFIX_URL = 'ucarecdn.com';

export const getImageResize = (src: string, width: number) => {
  if (!src.includes(UPLOAD_CARE_PREFIX_URL)) return undefined;
  return `${src}-/resize/${width}x/`;
};

export function usePageName(pageType: ThemePageType) {
  switch (pageType) {
    case 'GP_INDEX': {
      return 'Home Page';
    }
    case 'GP_ARTICLE': {
      return 'Blog Post';
    }
    case 'GP_COLLECTION': {
      return 'Collection Page';
    }
    case 'GP_PRODUCT': {
      return 'Product Page';
    }
    case 'GP_STATIC': {
      return 'Landing Page';
    }
    case 'GP_FUNNEL_PAGE': {
      return 'Funnel Page';
    }
    default: {
      return 'Instant Landing Page';
    }
  }
}
