import useNotification from '@/composables/useNotification';
import { getConfig } from '@/config';
import useShopStore from '@/stores/shop';
import { getEmbedAppUrl } from '@/utils/getEmbedAppUrl';

let isDelayRedirect = false;
export const backToDashboard = ({ delayRedirect }: { delayRedirect?: number }) => {
  if (isDelayRedirect) {
    return;
  }
  if (delayRedirect) {
    isDelayRedirect = true;
    setTimeout(() => {
      // Đợi component noti load xong
      const { handleError } = useNotification();
      handleError(`Login session expired, redirecting dashboard after ${delayRedirect / 1000}s.`);
    }, 500);
    setTimeout(() => {
      redirectDashboard();
    }, delayRedirect);
    return;
  }
  redirectDashboard();
};

/**
 * This is private function work in function backToDashboard
 */
const redirectDashboard = () => {
  const shopStore = useShopStore();
  const shopDomain = shopStore.getShopDomain;
  if (shopDomain) {
    const url = `${getEmbedAppUrl(shopDomain)}`;
    if (window.top) {
      window.top.location.href = url;
    } else {
      window.location.href = url;
    }
    return;
  }

  const appId = getConfig('featureConfig')?.embedAppId || 'gempages-cro';
  if (window.top) {
    window.top.location.href = 'https://admin.shopify.com/?redirect=/apps/' + appId;
  } else {
    window.location.href = 'https://admin.shopify.com/?redirect=/apps/' + appId;
  }
};
