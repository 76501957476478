import { notify } from 'notiwind';
import useShopStore from '@/stores/shop';
import { sentryCaptureException } from '@/modules/editor/modules/common/use-cases/sentry';
import { clearLoginAs } from '@/api/helpers';
import { EDITOR_NOTICE_EVENT } from '@/modules/editor/modules/common/constants/trackEventIds';
import { useGtm } from '@gtm-support/vue-gtm';
import { useIntercomAction } from './useIntercom';
import useLocationHandlers from './useLocationHandlers';
import { backToDashboard } from '@/utils/backToDashboard';
import useEditorStore from '@/modules/editor/modules/common/stores/editor';
import usePageBackupStore from '@/stores/pageBackup';
export const CLOSE_ERROR_TIME = 1000 * 60 * 60 * 60;
export const CLOSE_SUCCESS_TIME = 1000 * 5;
const DEFAULT_CLOSE_TIME = 3000;
export type TActionNotice = { content: string; onAction: () => void };

type MessageItem = {
  content: string;
  action?: TActionNotice;
  isHide?: boolean;
  isError?: boolean;
};
export default function useNotification() {
  const intercomAction = useIntercomAction();
  const { handleOpenPageShopifyInNewTab, handleOpenPageGPInNewTab } = useLocationHandlers();
  const gtm = useGtm();

  const handleByStatus = ({
    statusCode,
    code,
    message,
    forceMessage,
    action,
  }: {
    statusCode: number;
    code: number;
    message: any;
    forceMessage?: string;
    action?: TActionNotice;
  }) => {
    if (statusCode == 500) {
      alertError({ code, message, action });
      return;
    }
    if (statusCode == 401 || [401101, 400120].includes(code)) {
      // status 401 or instant no match pricing, cookie
      const dataError = errorAuth({ code, message });
      alertError({
        code: dataError.code,
        message: dataError.message,
        action,
      });
      return;
    }
    if (statusCode == 413) {
      const action = {
        content: 'Contact Support',
        onAction: () => onContactSupport(message),
      };

      alertError({ code, message: forceMessage ?? message, action });
      return;
    }
    if (statusCode == 408) {
      alertError({ code, message: forceMessage ?? message, action });
      return;
    }
    alertError({ code, message, action });
  };

  const alertError = ({ code, message, action }: { code?: number; message: string; action?: TActionNotice }) => {
    const msg = mapMessage({ code, message });
    const dataMsg = handleMappingMessage(msg);
    if (dataMsg.isHide) return;

    const newMessage = dataMsg.content ? dataMsg.content : msg;
    notify(
      {
        type: 'error',
        text: newMessage,
        action: dataMsg?.action ? dataMsg?.action : action,
        group: 'bottom',
      },
      CLOSE_ERROR_TIME,
    );
    trackerMessage(newMessage, 'error');
  };

  const mapMessage = ({ code, message }: { code?: number; message: string }): string => {
    switch (code) {
      case 403003:
        return `${code}: Your plan is not advanced yet, please upgrade to use this feature!`;
      case 500100:
        return `${code}: Login GemPages to continue using this features.!`;
      case 400103:
        return `${code}: Shop has connected`;
    }
    return message;
  };

  const errorParser = (
    error: any,
  ): {
    statusCode: number;
    code: number;
    message: string;
  } => {
    if (error.response) {
      // Graphql type 1
      const errors = error?.response?.errors;
      if (errors?.length) {
        for (let index = 0; index < errors.length; index++) {
          const error = errors[index];
          const statusCode = error?.extensions?.response?.status;
          const graphqlCode = error?.extensions?.code;
          if (statusCode) {
            const statusText = error?.extensions?.response?.statusText;
            return {
              statusCode: statusCode || 200,
              code: 400000,
              message: statusText, // works, `e` narrowed to string
            };
          } else if (graphqlCode) {
            const message = error?.extensions?.exception?.message;
            return {
              statusCode: statusCode || 200,
              code: 400000,
              message: message, // works, `e` narrowed to string
            };
          } else if (!statusCode) {
            return {
              statusCode: statusCode || 200,
              code: 400000,
              message: error, // works, `e` narrowed to string
            };
          }
        }
      } else if (error?.response?.status) {
        const statusCode = error?.response?.status;
        if (statusCode) {
          const err = error?.response?.errors?.[0] || error?.response?.error;
          if (err) {
            return {
              statusCode: statusCode || 200,
              code: 400000,
              message: err, // works, `e` narrowed to string
            };
          } else {
            return {
              statusCode: statusCode || 200,
              code: 400000,
              message: err, // works, `e` narrowed to string
            };
          }
        } else if (!statusCode) {
          return {
            statusCode: 200,
            code: 400000,
            message: error, // works, `e` narrowed to string
          };
        }
      }
    } else if (typeof error === 'string') {
      return {
        statusCode: 200,
        code: 400000,
        message: error, // works, `e` narrowed to string
      };
    } else if (error instanceof Error) {
      return {
        statusCode: 400,
        code: 400000,
        message: error.message, // works, `e` narrowed to string
      };
    } else if (error.code) {
      return {
        statusCode: 200,
        code: error.code,
        message: error.message || '',
      };
    }

    sentryCaptureException(
      'handleError',
      'Unidentified error',
      {
        error,
      },
      {
        level: 'warning',
      },
    );
    return {
      statusCode: 200,
      code: 400000,
      message: JSON.stringify(error),
    };
  };

  const errorAuth = ({
    code,
    message,
  }: {
    code: number;
    message: string;
  }): {
    code: number;
    message: string;
  } => {
    if (!code) {
      code = 401998;
    }
    const shopStore = useShopStore();
    if (shopStore.getConnectedType == 'storefront') {
      const editorStore = useEditorStore();
      const pageBackupStore = usePageBackupStore();
      if (code == 401998) {
        if (!editorStore.getInitLoaded) {
          backToDashboard({
            delayRedirect: 8000,
          });
        } else {
          pageBackupStore.setReason('SESSION_EXPIRED');
        }
      } else if (code == 401999) {
        if (!editorStore.getInitLoaded) {
          backToDashboard({
            delayRedirect: 8000,
          });
        } else {
          pageBackupStore.setReason('SESSION_EXPIRED');
        }
      }
    } else if (shopStore.getConnectedType == 'instant') {
      return {
        code,
        message,
      };
    }
    return {
      code,
      message,
    };
  };

  const trackerMessage = (msg: string, type: 'success' | 'error') => {
    gtm?.trackEvent({
      event: 'EDITOR_NOTICE_MESSAGE',
      data: {
        event_id:
          type === 'error' ? EDITOR_NOTICE_EVENT.EDITOR_NOTICE_ERROR : EDITOR_NOTICE_EVENT.EDITOR_NOTICE_SUCCESS,
        message: msg,
        toast_type: type,
      },
    });
  };

  const MAPPING_MESSAGE_HIDE: Record<string, MessageItem> = {
    'internal server error': { content: 'Error Occurred', isHide: true },
    '500011': { content: 'Error Occurred', isHide: true },
    'DOCTYPE html': { content: 'Error Occurred', isHide: true },
    '[object Object]': { content: 'Error Occurred', isHide: true },
    'error reading from server': { content: 'Server error', isHide: true },
    'already connected to domain': { content: 'Server error', isHide: true },
    NetworkError: { content: 'Network failed', isHide: true },
    'Network request failed': { content: 'Network failed', isHide: true },
    'Cannot set properties of undefined': { content: 'Network failed', isHide: true },
    'strconv.ParseUint: parsing "": invalid syntax': { content: 'Invalid syntax', isHide: true },
  };
  const MAPPING_MESSAGE_ACTIONS: Record<string, MessageItem> = {
    'Can not find theme': {
      content: 'Theme pages missing',
      action: {
        content: 'Check theme',
        onAction: () => handleOpenPageShopifyInNewTab('themes'),
      },
      isError: true,
    },
    'theme page does not exist': {
      content: 'Theme pages missing',
      action: {
        content: 'Check theme',
        onAction: () => handleOpenPageShopifyInNewTab('themes'),
      },
      isError: true,
    },
    'not exist themePage': {
      content: 'Theme pages missing',
      action: {
        content: 'Check theme',
        onAction: () => handleOpenPageShopifyInNewTab('themes'),
      },
      isError: true,
    },
    'Save unsuccessful due to a section exceeding code size limit': {
      content: 'Save unsuccessful',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: Save unsuccessful due to a section exceeding code size limit.'),
      },
      isError: true,
    },
    'Auto-save unsuccessful due to a section exceeding code size limit.': {
      content: 'Auto-save unsuccessful',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: Auto-save unsuccessful due to a section exceeding code size limit..'),
      },
      isError: true,
    },
    'duplicated cid': {
      content: 'Duplicated cid',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: Save unsuccessful due to a section exceeding code size limit.'),
      },
      isError: true,
    },
    'find section by cid': {
      content: 'Section not found',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport("Can't find section by cid"),
      },
      isError: true,
    },
    'Unexpected end of JSON input': {
      content: 'Error Occurred',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('Unexpected end of JSON input'),
      },
      isError: true,
    },
    'is not valid JSON': {
      content: 'Invalid JSON',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('"undefined" is not valid JSON'),
      },
      isError: true,
    },
    'Failed to fetch': {
      content: 'Failed to fetch',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('Failed to fetch'),
      },
      isError: true,
    },
    PayloadTooLargeError: {
      content: 'Page too large',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: PayloadTooLargeError: request entity too large'),
      },
      isError: true,
    },
    'Request Timeout': {
      content: 'Request timeout',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: Request Timeout'),
      },
      isError: true,
    },
    'permission denied': {
      content: 'Permission denied',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('400000: permission denied'),
      },
      isError: true,
    },
    'shop has no connected platform': {
      content: 'Disconnected shop',
      action: {
        content: 'Contact Support',
        onAction: () => onContactSupport('shop has no connected platform for GemPages v7'),
      },
      isError: true,
    },
    'plan trial2022 only allows publishing': {
      content: 'Trial limits exceeded',
      action: {
        content: 'Upgrade plan',
        onAction: () => handleOpenPageGPInNewTab('app/pricing'),
      },
      isError: true,
    },
    'no product in your store': {
      content: 'No products available',
      action: {
        content: 'Add product',
        onAction: () => handleOpenPageShopifyInNewTab('products/new'),
      },
      isError: true,
    },
    'only allow 1 sync platform original template process running at any given time': {
      content:
        'A Shopify Original sync is currently underway. Please wait 1-2 minutes before reloading this page to view the latest updates.',
      action: {
        content: 'Reload page',
        onAction: () => window.location.reload(),
      },
      isError: true,
    },
  };
  const MAPPING_MESSAGE: Record<string, MessageItem> = {
    'Need at least 1 section to': { content: 'Page is empty', isError: true },

    'strconv.ParseUint: parsing "": invalid syntax': { content: 'Invalid token', isError: true },
    'invalid or expired authorization token': { content: 'Invalid token', isError: true },
    'Authentication failed, please try again later': { content: 'Invalid token', isError: true },

    'shopifyAPI.Upload: (image/avif)': { content: 'Unsupported format .avif' },
    'shopifyAPI.Upload: Could not download image': { content: 'File upload error', isError: true },
    'shopifyAPI.Upload: Could not process image': { content: 'File upload error', isError: true },
    'shopifyAPI.Upload: graphqlClient.File.Upload': { content: 'File upload error', isError: true },

    'ent: validator failed for field': { content: 'Field validation failed', isError: true },
    'Name must not be empty': { content: 'Empty name error', isError: true },
    'Handle must not be empty': { content: 'Empty handle error', isError: true },

    ...MAPPING_MESSAGE_ACTIONS,
    ...MAPPING_MESSAGE_HIDE,
  };

  const onContactSupport = (message: string) => {
    if (intercomAction) {
      const defaultMessage = `I am encountering an error and require assistance. Error message: ${message}`;
      intercomAction.showWithMessage(defaultMessage);
    }
  };

  const handleMappingMessage = (message: string): MessageItem => {
    const mappingMessage = MAPPING_MESSAGE[message];
    if (mappingMessage) return mappingMessage;

    let dataMsg = {
      content: message,
    };
    Object.keys(MAPPING_MESSAGE).forEach((key) => {
      if (message.includes(key)) {
        dataMsg = MAPPING_MESSAGE[key];
      }
    });

    return dataMsg;
  };

  return {
    handleByStatus,
    handleError: (error: any, options?: { forceMessage?: string; action?: TActionNotice }) => {
      const errors = error?.response?.errors;
      const isUnAuthorized = errors?.some((error: any) => error?.extensions?.response?.status === 401);
      if (isUnAuthorized) {
        clearLoginAs();
      }
      const { code, statusCode, message } = errorParser(error);
      handleByStatus({ statusCode, code, message, forceMessage: options?.forceMessage, action: options?.action });
    },
    handleSuccess: (
      msg: string,
      options?: {
        action?: TActionNotice;
      },
      group?: string,
    ) => {
      const dataMsg = handleMappingMessage(msg);
      if (dataMsg.isHide) return;

      const newMessage = dataMsg.content ? dataMsg.content : msg;
      trackerMessage(newMessage, 'success');

      if (typeof dataMsg.isError === 'boolean' && dataMsg.isError) {
        notify(
          {
            type: 'error',
            text: dataMsg.content ? dataMsg.content : msg,
            action: dataMsg.action ? dataMsg.action : options?.action,
            group: group ?? 'bottom',
          },
          CLOSE_ERROR_TIME,
        );

        return;
      }

      notify(
        {
          type: 'success',
          text: dataMsg.content ? dataMsg.content : msg,
          action: dataMsg.action ? dataMsg.action : options?.action,
          group: group ?? 'bottom',
        },
        CLOSE_SUCCESS_TIME,
      );
    },
    handleAlert(type: 'error' | 'success' = 'error', msg: string, time?: number, group?: string) {
      notify(
        {
          type,
          text: msg,
          customTypeError: true,
          group: group ?? 'bottom',
        },
        time ? time : type === 'error' ? CLOSE_ERROR_TIME : DEFAULT_CLOSE_TIME,
      );
    },
    errorParser,
  };
}
