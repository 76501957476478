import { useShopMetaQuery } from '@/api/app/queries/shopMeta.generated';
import type { ShopMetaQueryVariables } from '@/api/app/queries/shopMeta.generated';
import { useShopMetaCreateOrUpdateMutation } from '@/api/app/mutations/shop-meta-create-or-update.generated';
import {
  META_KEY_SURVEY_SHOWN,
  SHOP_META_DROP_ELEMENT_COUNT,
  SHOP_META_DROP_ELEMENT_COUNT_MAX,
  SHOP_META_IS_PREVIEWED,
} from '../constants';
import type { TSurveysShown } from '../types';
import useShopStore from '@/stores/shop';

export const useSurveyShopMeta = () => {
  const shopMetaVariables: ShopMetaQueryVariables = {
    where: { keyIn: [META_KEY_SURVEY_SHOWN, SHOP_META_DROP_ELEMENT_COUNT, SHOP_META_IS_PREVIEWED] },
  };
  const shopStore = useShopStore();

  const dataSurveyShown = computed(() => {
    return shopStore.getDataSurveyShown;
  });
  const isFetchingDataSurvey = computed(() => {
    return shopStore.getIsFetchingDataSurvey;
  });
  const dropElementCount = computed(() => {
    return shopStore.getDropElementCount;
  });

  useShopMetaQuery(shopMetaVariables, {
    onSuccess: (result) => {
      const surveyShown = result.shopMeta?.edges?.filter((v) => v?.node?.key === META_KEY_SURVEY_SHOWN);
      if (surveyShown?.[0]?.node?.value) {
        const dataSurveyShown = JSON.parse(surveyShown?.[0]?.node?.value ?? []);
        shopStore.setDataSurveyShown(dataSurveyShown);
      }
      const dropElementCount = result.shopMeta?.edges?.filter((v) => v?.node?.key === SHOP_META_DROP_ELEMENT_COUNT);
      const dropElementCountValue = dropElementCount?.[0]?.node?.value;
      if (dropElementCountValue) {
        shopStore.setDropElementCount(parseInt(dropElementCountValue));
      }
      const isPreviewed = result.shopMeta?.edges?.filter((v) => v?.node?.key === SHOP_META_IS_PREVIEWED);
      const isPreviewedValue = isPreviewed?.[0]?.node?.value;
      if (isPreviewedValue == 'true') {
        shopStore.setIsPreviewed();
      }
      shopStore.setIsFetchingDataSurvey(true);
    },
  });

  const shopMetaMutation = useShopMetaCreateOrUpdateMutation();

  const createOrUpdateSurvey = (payload: TSurveysShown[]) => {
    shopStore.setDataSurveyShown(payload);
    upSertShopMeta(META_KEY_SURVEY_SHOWN, JSON.stringify(payload));
  };

  const updateDropElementCount = () => {
    if (dropElementCount.value < SHOP_META_DROP_ELEMENT_COUNT_MAX) {
      const newVal = dropElementCount.value + 1;
      shopStore.setDropElementCount(newVal);
      upSertShopMeta(SHOP_META_DROP_ELEMENT_COUNT, newVal.toString());
    }
  };

  const updateIsPreviewed = () => {
    shopStore.setIsPreviewed();
    upSertShopMeta(SHOP_META_IS_PREVIEWED, 'true');
  };

  const upSertShopMeta = (key: string, value: string) => {
    shopMetaMutation.mutate({
      input: {
        key,
        value,
      },
    });
  };

  return {
    dataSurveyShown,
    isFetchingDataSurvey,
    createOrUpdateSurvey,
    updateDropElementCount,
    updateIsPreviewed,
  };
};
