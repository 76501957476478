import { useRoute } from 'vue-router';
import { useInstantAuthentication } from './useInstantAuthentication';
import { useMode } from './useMode';
import { useStoreFrontAuthentication } from './useStoreFrontAuthentication';
import { useGemPagesAuthentication } from './useGemPagesAuthentication';

export const useShopAuth = () => {
  const route = useRoute();
  const { connectedType, editorConnectedType, updateConnectedType } = useMode();
  useGemPagesAuthentication();
  useInstantAuthentication(connectedType, editorConnectedType);
  useStoreFrontAuthentication(connectedType, editorConnectedType);

  watch(
    () => route.name,
    () => {
      updateConnectedType();
    },
  );
};
