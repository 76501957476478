// eslint-disable-next-line check-file/filename-naming-convention
import type { Section } from '../../common/utils/types';

import { defineStore } from 'pinia';
import hash from 'object-hash';

import { hashSection } from '../utils';
import { IGNORE_CID } from '../constants';

// const trackForChanges = (oldHash: Map<string, string>, newHash: Map<string, string>) => {
//   if (oldHash.size !== newHash.size) {
//     console.log(`Size change from: ${oldHash.size} to: ${newHash.size}`);
//   }

//   oldHash.forEach((value, key) => {
//     if (newHash.has(key)) {
//       if (value !== newHash.get(key)) {
//         console.log(`Hash change for key: ${key}`);
//       }
//     } else {
//       console.log(`Key removed: ${key}`);
//     }
//   });

//   const oldHashKey = [...oldHash.keys()];
//   newHash.forEach((value, key) => {
//     if (!oldHashKey.includes(key)) {
//       console.log(`Key added: ${key}`);
//     }
//   });
// };

export const useContentHashStore = defineStore('content-hash', {
  state: () => ({
    originalPageSectionHash: new Map<string, string>(),
    originalSectionPositionHash: '',
  }),
  actions: {
    setOriginalPageSectionHash(pageSections?: Section[], isThemeSection = false) {
      if (!pageSections) return;

      // const oldHash = new Map(originalPageSectionHash.value);
      this.originalPageSectionHash = new Map();
      pageSections.forEach((section) => {
        if (section.isThemeSection || section.isShopifySection || isThemeSection) {
          this.originalPageSectionHash.set(section?.id, hashSection(section, IGNORE_CID));
          return;
        }

        if (section?.cid) {
          this.originalPageSectionHash.set(section?.cid, hashSection(section));
        }
      });
      // trackForChanges(oldHash, originalPageSectionHash.value);
    },
    setOriginalSectionPositionHash(sectionPosition: string[]) {
      this.originalSectionPositionHash = hash.MD5(sectionPosition);
    },
  },
});
