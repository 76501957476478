import type * as Types from '@/types/graphql';

import type { ComputedRef, Ref } from 'vue';
import { ShopSelectFragmentDoc } from '../fragments/shop.generated';
import { useQuery, type UseQueryOptions } from 'vue-query';
import { appFetcher } from '@/api/gatewayClient';
import { PublicTokenSelectFragmentDoc } from '../fragments/public-token.generated';
export type CurrentShopQueryVariables = Types.Exact<{ [key: string]: never }>;

export type CurrentShopQueryResponse = { currentShop?: Types.Maybe<Types.Shop> };

export const CurrentShopDocument = `
  query currentShop {
    currentShop {
      ...ShopSelect
    }
  }
  ${ShopSelectFragmentDoc}
  ${PublicTokenSelectFragmentDoc}
`;

export const useCurrentShopQuery = <TData = CurrentShopQueryResponse, TError = unknown>(
  variables?: CurrentShopQueryVariables | ComputedRef<CurrentShopQueryVariables> | Ref<CurrentShopQueryVariables>,
  options?: UseQueryOptions<CurrentShopQueryResponse, TError, TData>,
) =>
  useQuery<CurrentShopQueryResponse, TError, TData>(
    variables === undefined ? ['currentShop'] : ['currentShop', variables],
    appFetcher<CurrentShopQueryResponse, CurrentShopQueryVariables>(CurrentShopDocument, variables),
    options,
  );

useCurrentShopQuery.getKey = (
  variables?: CurrentShopQueryVariables | ComputedRef<CurrentShopQueryVariables> | Ref<CurrentShopQueryVariables>,
) => (variables === undefined ? ['currentShop'] : ['currentShop', variables]);
