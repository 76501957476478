export const isTokenValidForNextHours = (token: string, validForHours: number): boolean => {
  try {
    // Lấy phần payload của token
    const base64Url = token.split('.')[1];

    // Chuyển đổi từ Base64URL sang Base64
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    // Giải mã Base64 thành JSON
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    // Parse JSON để lấy exp và expired (thời gian hết hạn)
    const { exp, expired } = JSON.parse(jsonPayload);

    // Xác định thời gian hết hạn, ưu tiên trường expired nếu có
    const expirationTime = expired ?? exp;

    if (!expirationTime) {
      throw new Error('Expiration time not found in token');
    }

    // Lấy thời gian hiện tại tính bằng giây và thời gian trong tương lai tính bằng giây
    const currentTimeInSeconds = Math.floor(Date.now() / 1000);
    const validForSeconds = validForHours * 60 * 60;
    const timeFromNowInSeconds = currentTimeInSeconds + validForSeconds;

    // So sánh trực tiếp thời gian hết hạn và thời gian tính từ hiện tại
    return expirationTime >= timeFromNowInSeconds;
  } catch (error) {
    console.error('Invalid JWT token:', error);
    return false; // Trả về false nếu có lỗi xảy ra (token không hợp lệ)
  }
};
