import { defineStore } from 'pinia';

export type State = {
  hasUndo: boolean;
  hasRedo: boolean;
  cids: string[];
  whileSavingCids: string[];
  savedId: string;
};

export const useHistoryStore = defineStore('history', {
  state: (): State => ({
    hasUndo: false,
    hasRedo: false,
    cids: [],
    whileSavingCids: [],
    savedId: '',
  }),
  getters: {
    getCids(state) {
      return state.cids;
    },
    getWhileSavingCids(state) {
      return state.whileSavingCids;
    },
    getSavedId(state) {
      return state.savedId;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    setSavedIds(value: string) {
      this.savedId = value;
    },
    setCids(cids: string[]) {
      this.cids = cids;
    },
    setCid(cids: string[]) {
      this.cids = this.cids.concat(cids);
    },
    clearCids() {
      this.cids = [];
    },
    syncCidsFromWhileSaving() {
      const whileSavingCids = this.whileSavingCids.map((cid: string) => cid);
      this.setCids(whileSavingCids);
    },
    setWhileSavingCids(cids: string[]) {
      this.whileSavingCids = cids;
    },
    clearWhileSavingCids() {
      this.whileSavingCids = [];
    },
    setHistoryHasUndo(value: boolean) {
      this.hasUndo = value;
    },
    setHistoryHasRedo(value: boolean) {
      this.hasRedo = value;
    },
  },
});

export default useHistoryStore;
