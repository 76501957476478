import { cloneDeepObject } from '@/utils/common';
import { RenderSectionCID } from '@/utils/id';
import type { ComponentTag } from '@gem/control';
import { SECTION_TAG } from '../const';
import type { Component, Section } from '../types';
import useHistoryStore from '../../../history/stores/history';
import useSectionStore from '../../stores/section';
import { getComponentsBySettingAndValue } from './component';

export const getSectionCID = () => {
  const historyCIDs = useHistoryStore().getCids;
  const sectionStoreCIDs = useSectionStore().getItems.map((item) => item.cid as string);
  const expelStoreCIDs = [...historyCIDs, ...sectionStoreCIDs];
  return RenderSectionCID(expelStoreCIDs);
};

export const initNewSection = ({
  components,
  isMobile,
  options,
  isGeneratedFromImg2Layout,
  isGeneratedContent,
}: {
  components: Component[];
  isMobile?: boolean;
  options?: {
    tag?: ComponentTag;
    isBuildWithSectionLoading?: boolean;
    isPastingSectionLoading?: boolean;
  };
  isGeneratedFromImg2Layout?: boolean;
  isGeneratedContent?: boolean;
}): Section => {
  if (components.length > 1) {
    console.error("Can't add component array:", components);
  }
  const cloneComponent = cloneDeepObject(components[0]);
  cloneComponent.tag = options?.tag || SECTION_TAG; // Replace tag Row -> Section
  const cid = getSectionCID();
  const isBuildWithSectionLoading = !!options?.isBuildWithSectionLoading;
  const isPastingSectionLoading = !!options?.isPastingSectionLoading;
  const isLoading = isBuildWithSectionLoading || isPastingSectionLoading;

  return {
    id: '', // create new id == ''
    cid: cid,
    name: isLoading ? 'SectionLoading' : `Section ${cloneComponent.uid}`,
    display: true,
    isMobile,
    component: JSON.stringify(cloneComponent),
    isGeneratedFromImg2Layout: isGeneratedFromImg2Layout,
    isGeneratedContent: isGeneratedContent,
  };
};

export const countSelectedOptionOnTemplate = (tag: string, settingId: string, value: any) => {
  const components = useSectionStore()
    .getItems.map((item) => JSON.parse(item.component ?? '{}'))
    .flat();
  let settingComponents: Component[] = [];
  components.forEach((component) => {
    settingComponents = getComponentsBySettingAndValue(component, tag, settingId, value, settingComponents);
  });
  return settingComponents.length;
};
