<template>
  <Teleport to="body">
    <NotificationGroup :position="position" :group="group">
      <div
        class="fixed inset-x-0 z-[1000] mx-auto my-8 flex w-fit max-w-[600px] items-start justify-center p-8 px-4"
        :class="{
          'top-0': position === 'top',
          'bottom-0': position !== 'top',
          '!w-fit': group === 'fit-content',
        }">
        <div class="w-full">
          <Notification
            v-slot="{ notifications, close }"
            :max-notifications="group == 'save' ? 1 : 3"
            enter="transform ease-in-out duration-200 transition"
            :enter-from="
              isBottomNotification
                ? 'translate-y-[120px] opacity-70'
                : 'translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-4'
            "
            :enter-to="
              isBottomNotification ? 'translate-y-0 opacity-100' : 'translate-y-0 opacity-100 sm:translate-x-0'
            "
            :leave="isBottomNotification ? 'transition ease-in duration-200' : 'transition ease-in duration-500'"
            :leave-from="isBottomNotification ? 'opacity-100 translate-y-0' : 'opacity-100'"
            :leave-to="isBottomNotification ? 'opacity-70 translate-y-32' : 'opacity-0'"
            move="transition">
            <div
              v-for="(notification, index) in notifications"
              :key="notification.id"
              data-test="notification"
              class="shadow-success-message text-text-dark-500 mx-auto flex w-full items-center justify-between gap-8 overflow-hidden rounded-xl px-12 py-8 text-[13px] font-[550] leading-5"
              :class="{
                'bg-red-300': notification.type == 'error',
                'bg-dark-500': notification.type == 'success',
                'mt-[64px]': index === 0 && position === 'top',
                'mt-8': index > 0 && position === 'top',
                'mb-8': isBottomNotification,
              }">
              <div class="flex items-start gap-4">
                <g-base-icon
                  v-if="notification.type == 'error'"
                  class="min-w-[20px]"
                  name="alert-minor"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"></g-base-icon>
                <p class="" v-html="notification.text"></p>
              </div>
              <div class="flex items-center gap-8">
                <div v-if="notification.action">
                  <div
                    class="flex h-28 cursor-pointer items-center justify-center whitespace-nowrap px-12"
                    @click="notification?.action?.onAction()">
                    {{ notification?.action?.content }}
                  </div>
                </div>
                <div class="cursor-pointer">
                  <g-base-icon
                    name="cancel-small-minor"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    @click="close(notification.id)"></g-base-icon>
                </div>
              </div>
            </div>
          </Notification>
        </div>
      </div>
    </NotificationGroup>
  </Teleport>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    position?: 'top' | 'bottom';
    group?: string;
  }>(),
  {
    position: 'top',
  },
);
const isBottomNotification = props.position === 'bottom';
</script>
