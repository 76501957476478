export const isTokenExpired = (token: string): boolean => {
  try {
    // Lấy phần payload của token
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    // Parse JSON để lấy exp hoặc expired (thời gian hết hạn)
    const payload = JSON.parse(jsonPayload);
    const expirationTime = payload.exp || payload.expired;

    if (!expirationTime) {
      throw new Error('Expiration time not found in token');
    }

    // Kiểm tra token có hết hạn không
    const expired = Date.now() >= expirationTime * 1000;
    return expired;
  } catch (error) {
    console.error('Invalid JWT token:', error);
    return true; // Trả về true nếu có lỗi xảy ra (token không hợp lệ)
  }
};
